import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { plainToClass } from 'class-transformer';
import { Client } from 'src/app/components/classes/Client';
import { ProgressAlertComponent } from 'src/app/components/dialogs/progress-alert/progress-alert.component';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-client-setup-provider',
  templateUrl: './client-setup-provider.component.html',
  styleUrls: ['./client-setup-provider.component.scss']
})
export class ClientSetupProviderComponent implements OnInit {

  clientsList: Array<Client> = [];
  filteredClients: Array<Client> = [];
  currentItemsToShow = [];
  @Output() sendClientListLength: EventEmitter<any> = new EventEmitter;
  clientListLength: number;
  defaultRecords: any = 10;
  searchText: string;
  showNewClient: boolean;


  constructor(private clientService:ClientService, private matSnackBar: MatSnackBar, private dialog:MatDialog) { }

  ngOnInit() {
    this.getClientsList()
  }


  setDefaultPaginatorItems(){
    this.currentItemsToShow = [];
    if(this.searchText){
      this.currentItemsToShow = this.filteredClients.slice(0, this.defaultRecords)
    }
    else{
      this.currentItemsToShow = this.clientsList.slice(0, this.defaultRecords)
    }
  }

  onPageChange($event) {
    this.currentItemsToShow = this.clientsList.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
  }

  toggleNewClientVisibility(){
    this.showNewClient = !this.showNewClient;
  }

  filterClient(){
    this.filteredClients = [];
    if(this.searchText){
      this.clientsList.forEach( client => {
        const lowerProduct = client.companyName.toLowerCase()
        if(lowerProduct.includes(this.searchText.toLowerCase())){
          this.filteredClients.push(client);
        }
      });
    }
    this.setDefaultPaginatorItems();
  }

  addedNewClient(data){
    this.toggleNewClientVisibility()
    this.getClientsList()
  }

  closeNewClient(data){
    this.toggleNewClientVisibility()
  }

  async getClientsList(){
    const progressDialog = this.dialog.open(ProgressAlertComponent, {
      data: {
        title: 'Loading...'
      }
    });
    try{
      this.clientsList = [];
      const data = await this.clientService.getClientUnderProvider();
      this.clientsList = this.clientsList.concat(plainToClass(Client, data));
      this.clientListLength = this.clientsList.length;
      this.setDefaultPaginatorItems();
      this.sendClientListLength.emit(this.clientListLength);
      progressDialog.close();
    }
    catch{
      progressDialog.close();
      this.matSnackBar.open('Something went wrong');
    }
  }

}
