<div class="contentContainer">
  <div *ngIf="isRootUser">
    <app-provider-setup-manager></app-provider-setup-manager>
  </div>
  <div *ngIf="!isRootUser">
    <h2 class="p-t-10">Provider Setup</h2>
    <mat-divider class="m-t-10 m-b-10"></mat-divider>
    <app-provider-setup-main></app-provider-setup-main>
  </div>
</div>
