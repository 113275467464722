<div class="headerBar pl bg-primary">
  <div class="row no-gutters">
    <div class="col"></div>
    <div class="col-6">
        <div class="topNavContent">
          <div class="topNavName">{{username}} (<strong>{{userType | userTypePipe}}</strong>)</div>
          <button class="pl bg-primary topNavButton m-r-5"> <i class="fa-solid fa-gear" routerLink="/app/profile" matTooltip="User Profile and Change Password" ></i></button>
          <button class="pl bg-primary topNavButton" ><i class="fa-solid fa-right-from-bracket" (click)="logOut()" matTooltip="Logoff" ></i></button>
        </div>
    </div>
  </div>
</div>
