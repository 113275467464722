import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import { TokenService } from './token.service';
import { Router } from '@angular/router';
import { globalUser } from '../global/globaluser';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor( private httpClient:HttpClient, private authService:TokenService,  private router:Router) { }

  private userLoggedIn = new BehaviorSubject<boolean>(this.authService.isAuthenticated());


  public async loginUser(username:string, password:string, sessionId:string) {
    const result = {message:"", success:false, error: false};
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/x-www-form-urlencoded');

    const params = new HttpParams()
      .set('username', username)
      .set('password', password)
      .set('grant_type', 'password');

    await this.httpClient.post("token", params, {headers:headers}).toPromise().then(
        (res) => {
          result.success = true;
          const token = JSON.parse(JSON.stringify(res));
          localStorage.setItem('token', token.access_token);
          localStorage.setItem('token_exp', token[".expires"]);
          localStorage.setItem('username', username);
          globalUser.setglobalUserID(token.Id);
          localStorage.setItem('parentId', token.ParentId)
          localStorage.setItem('userKind', token.Kind);
          localStorage.setItem('RqUserId', token.Id);
          localStorage.setItem('RqLogo', token.Logo);
          //this.setUserLoggedIn(true);

        },
        (err) => {
          if(err.error){
            result.error = true;
            result.message = err.error.error_description;
          }
          else
            result.message = "An error occured";
        },
      );
    return result;
  }//End loginUser

  setUserLoggedIn(userLoggedIn){
    this.userLoggedIn.next(userLoggedIn);
  }

  getUserLoggedIn(): Observable<boolean>{
    return this.userLoggedIn.asObservable();
  }

  public async forgotPassword(userId: string){

    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json');

    await lastValueFrom(this.httpClient.post("api/Account/ForgotPassword", {UserName: userId}, {headers:headers}));
  }

  public logoutUser(navigate: Boolean){
    const params = new HttpParams();
    this.httpClient.post("api/Account/Logout", params);

    if(!localStorage.getItem('RQRemember')){
      localStorage.removeItem('readyQualUsername')
    }
    localStorage.removeItem('token');
    localStorage.removeItem('token_exp');
    localStorage.removeItem('parentId')
    localStorage.removeItem('userKind');
    localStorage.removeItem('RqUserId');
    this.setUserLoggedIn(false);
    if(navigate){
      this.router.navigate(['app/access/signin']);
    }
  }

  public async resetPassword(data){
    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json');

    await lastValueFrom(this.httpClient.post("api/Account/ResetPassword", data, {headers:headers}));
  }

}
