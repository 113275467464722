import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { plainToClass } from 'class-transformer';
import { Client } from 'src/app/components/classes/Client';
import { ProgressAlertComponent } from 'src/app/components/dialogs/progress-alert/progress-alert.component';
import { ClientService } from 'src/app/services/client.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-provider-setup-manager',
  templateUrl: './provider-setup-manager.component.html',
  styleUrls: ['./provider-setup-manager.component.scss']
})
export class ProviderSetupManagerComponent implements OnInit {

  providers: Array<Client> = [];
  filteredProviders: Array<Client> = [];
  searchText: string;
  showNewProvider: boolean;
  currentItemsToShow = [];
  defaultRecords: any = 10;

  constructor(private clientService:ClientService, private matSnackBar: MatSnackBar, private dialog:MatDialog) { }

  ngOnInit() {
    this.getProvidersData()
  }

  filterProvider(){
    if(this.searchText == null || this.searchText == ""){
      this.filteredProviders = this.providers;
      this.setDefaultPaginatorItems();
    }
    else{
      this.filteredProviders = this.providers.filter((provider) => provider.companyName == this.searchText)
      this.setDefaultPaginatorItems()
    }
  }

  closeNewUser(event){
    this.toggleNewProviderVisibility()
  }

  toggleNewProviderVisibility(){
    this.showNewProvider = !this.showNewProvider;
  }

  addedNewProvider(event){
    this.getProvidersData();
    this.toggleNewProviderVisibility()
  }

  setDefaultPaginatorItems(){
    if(this.searchText == null || this.searchText == ""){
      this.currentItemsToShow = this.providers.slice(0, this.defaultRecords)
    }
    else{
      this.currentItemsToShow = this.filteredProviders.slice(0, this.defaultRecords)
    }
  }

  onPageChange($event) {
    if(this.searchText == null || this.searchText == ""){
      this.currentItemsToShow = this.providers.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
    }
    else{
      this.currentItemsToShow = this.filteredProviders.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
    }
  }

  async getProvidersData(){
    const progressDialog = this.dialog.open(ProgressAlertComponent, {
      data: {
        title: 'Loading...'
      }
    });
    try{
      const data = await this.clientService.getRootProviders();
      this.providers = this.providers.concat(plainToClass(Client, data));
      this.setDefaultPaginatorItems();
      progressDialog.close();
    }
    catch{
      progressDialog.close();
      this.matSnackBar.open('Something went wrong');
    }
  }

}
