import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { LoanType } from '../components/classes/LoanType';
import { Client } from '../components/classes/Client';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

constructor(private httpClient:HttpClient) { }

  async getProvider(subscriberId: string){
    const data = await lastValueFrom(this.httpClient.get(`api/Provider/Provider/${subscriberId}`));
    return data;
  }

  async postProvider(provider: Client){
    const data = await lastValueFrom(this.httpClient.put(`api/Provider/Provider`, provider ));
    return data;
  }

  async getRootProviders(){
    const data = await lastValueFrom(this.httpClient.get(`api/Root/Providers`));
    return data;
  }

  async postRootProvider(provider: Client){
    const data = await lastValueFrom(this.httpClient.put(`api/Root/Provider`, provider ));
    return data;
  }

  async addProvider(provider: Client){
    const data = await lastValueFrom(this.httpClient.post(`api/Root/Provider`, provider ));
    return data;
  }
  async getClient(subscriberId){
    const data = await lastValueFrom(this.httpClient.get(`api/Subscriber/Subscribers/${subscriberId}`));
    return data;
  }

  async getClientUnderProvider(){
    const data = await lastValueFrom(this.httpClient.get(`api/Provider/Subscribers`));
    return data;
  }

  async updateClient(subscriber: Client){
    const data = await lastValueFrom(this.httpClient.put(`api/Subscriber/Subscriber`, subscriber));
    return data;
  }

  async addClient(subscriber: Client){
    const data = await lastValueFrom(this.httpClient.post(`api/Provider/Subscriber`, subscriber));
    return data;
  }

  async getLoanTypes(subscriberId){
    const data = await lastValueFrom(this.httpClient.get(`api/Subscriber/Categories/${subscriberId}/LoanTypes`));
    return data;
  }

  async getCategories(subscriberId){
    const data = await lastValueFrom(this.httpClient.get(`api/Subscriber/Categories/${subscriberId}`));
    return data;
  }

  async addUserToProduct(productId, userId){
    const data = await lastValueFrom(this.httpClient.post(`api/Subscriber/AddUserProduct/${productId}/User/${userId}`, {}));
    return data;
  }

  async removeUserFromProduct(productId, userId){
    const data = await lastValueFrom(this.httpClient.put(`api/Subscriber/RemoveUserProduct/${productId}/User/${userId}`, {}));
    return data;
  }

  async addNewProduct(product: LoanType){
    const data = await lastValueFrom(this.httpClient.post(`api/Subscriber/LoanTypes`, product));
    return data;
  }

  async updateProduct(product: LoanType){
    const data = await lastValueFrom(this.httpClient.put(`api/Subscriber/LoanTypes`, product));
    return data;
  }

}
