<div>
  <div class="row justify-content-end" >
    <div class="col-md-2">
      <input type="text" class="form-control pull-right w m-r-lg" placeholder="SEARCH" [(ngModel)]="searchText" (ngModelChange)="filterUsers()"/>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <h2 style="position: relative;">
        User Setup
        <span class="badge pl bg-primary alert_indicator" style="font-size: 12px;"> {{users?.length}} </span>
      </h2>
    </div>
    <div class="col-md-4 offset-md-4">
      <span class="fa-stack fa-pull-right text-lg m-t-10 clickable" (click)="toggleNewUserVisibility()">
        <i class="fa fa-circle fa-stack-2x pl" [ngClass]="{'text-danger':showNewUser, 'text-primary':!showNewUser}"></i>
        <i class="fa fa-stack-1x fa-inverse pl" [ngClass]="{'fa-plus':!showNewUser, 'fa-minus':showNewUser}"></i>
      </span>
    </div>
  </div>
  <mat-divider class="m-t-10 m-b-10"></mat-divider>
  <div *ngIf="showNewUser">
    <app-user-setup-new (cancelNewUser)="showNewUser = false" [parentId]="subscriberID" (addedNewUser)="newUserAdded()" [loanType]="loanTypes"></app-user-setup-new>
  </div>
  <div *ngFor="let item of currentItemsToShow">
    <app-user-setup-item [user]="item" [loanType]="loanTypes"></app-user-setup-item>
  </div>
  <mat-paginator class="m-t-10" [length]="users?.length" (page)="onPageChange($event)" [pageSize]="10" [showFirstLastButtons]="true" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

</div>
