import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AutoResume, DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { AlertsComponent } from './components/dialogs/alerts/alerts.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoginService } from './services/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent {
  title = 'ReadyQual';
  timeUntilIdle = 1200; //time in seconds
  timeUntilTimeout = 60; //time in seconds
  timedout = false;

  constructor(
    private router: Router,
    private idle: Idle,
    private loginService: LoginService,
    private dialog: MatDialog,
  ){
    let timeoutDialog:MatDialogRef<AlertsComponent, AlertsComponent>;
    idle.setIdle(this.timeUntilIdle);
    idle.setTimeout(this.timeUntilTimeout); //starts ticking after idle period triggers
    idle.setAutoResume(AutoResume.notIdle);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);


    idle.onIdleEnd.subscribe(() => {
      timeoutDialog.close();
    });

    idle.onTimeout.subscribe(() => {
      this.timedout = true;
      this.stop();
      this.dialog.closeAll();
      this.loginService.logoutUser(true);
    });

    window.addEventListener('storage', (event) => {///Sends user to login page if it detects another tab or window logged out.
      if(event.key == null && !this.hasRoute('consumer')){///should happen only when the LocalStorage is cleared.
        this.stop();
        this.router.navigate(['app/access/signin']);
      }
    });

    idle.onIdleStart.subscribe(() => {
      if(!this.hasRoute('consumer')){
        timeoutDialog = this.dialog.open(AlertsComponent, {
          width: '250px',
          data: {
            title: 'Idle Warning',
            message: "You will time out in " + this.timeUntilTimeout + " seconds!",
            isConfirmation: false,
          },
          disableClose: true,
        });
        timeoutDialog.afterClosed().subscribe(() => {
          idle.interrupt(true);
        });
      }

    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      timeoutDialog.componentInstance.data.message = "You will time out in " + countdown + " seconds!";
    });


    this.loginService.getUserLoggedIn().subscribe((userLoggedIn) => { ///Resets or stops the idle watch depending on whether or not the user is logged in.
      if(userLoggedIn){
        this.reset();
      }
      else{
        this.stop();
      }
    });
  }//end of constructor

  reset(){
    this.idle.watch();
    this.timedout = false;
  }//end reset

  private stop(){
    this.idle.stop();
  }//End stop

  hasRoute(route: string) {
    return this.router.url.includes(route);
  }



}
