import { Injectable } from '@angular/core';
import {Location, LocationStrategy} from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class WebAddressService {

constructor(private location: Location, private locationStrategy: LocationStrategy) { }

  generateWebAddress(){
   const basePath = `${this.getBaseLocation()}/#/consumer/`;
   return basePath;
  }

  getBaseLocation(){
    var path = window.location.protocol + "//" + window.location.host;
    if(path == ""){
      path = "https://dev.readyqual.com";
    }
    return path;
  }

}
