import { LoanType, Product } from "./LoanType";

export class User {
  Id: string;
  address: string;
  branding: string;
  cell: string;
  city: string;
  code: string;
  companyName: string;
  completionMessage: string;
  contact: string;
  dateCreated: string;
  dateLastLogon: string;
  dateModified: string;
  email: string;
  hierarchicalCompletionMessage: string;
  hierarchicalRejectMessage: string;
  hierarchicalWelcomeMessage: string;
  kind: string;
  matchPassword:string
  notificationEmail: string;
  parentId: string;
  password: string;
  phone: string;
  providerId: string;
  products: Array<Product>;
  rejectMessage: string;
  securityCookie: string;
  state:string;
  status:number;
  url:string;
  userName: string;
  welcomeMessage: string;
  zip: string;
}
