import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'clientStatus'})
export class ClientStatusPipe implements PipeTransform {
  transform(value: number): string {
    switch(value)
    {
      case 1: return "Active";
      case 2: return "Terminated";
      case 3: return "Inactive";
      default: return "Active";
    }
  }
}
