import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Client } from 'src/app/components/classes/Client';
import { User } from 'src/app/components/classes/User';
import { confirmPasswordValidator, matchPasswordValidator } from 'src/app/components/constants/confirm-password.validator';
import { ProgressAlertComponent } from 'src/app/components/dialogs/progress-alert/progress-alert.component';
import { ClientService } from 'src/app/services/client.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-provider-setup-new-user',
  templateUrl: './provider-setup-new-user.component.html',
  styleUrls: ['./provider-setup-new-user.component.scss']
})
export class ProviderSetupNewUserComponent implements OnInit {

  newUser: User;
  newProviderUserForm: FormGroup;
  confirmPassword: string;
  @Input() provider: Client;
  @Output() cancelNewUser: EventEmitter<any> = new EventEmitter;
  @Output() addedNewUser: EventEmitter<any> = new EventEmitter;
  userKind: string = localStorage.getItem("userKind") || "";
  subscriberID = localStorage.getItem("parentId") || null;

  constructor(private clientService: ClientService, private usersService: UsersService, private matSnackBar: MatSnackBar, private dialog: MatDialog) { }

  ngOnInit() {
    this.newUser = new User()
    this.newProviderUserForm = new FormGroup({
      email: new FormControl(this.newUser.email, {nonNullable: true, validators: [Validators.required, Validators.email]}),
      contact: new FormControl(this.newUser.contact, {nonNullable: true, validators: [Validators.required]}, ),
      phone: new FormControl(this.newUser.phone, {nonNullable: true, validators: [Validators.required, Validators.pattern("^[0-9]*$")]}),
      cell: new FormControl(this.newUser.cell, {nonNullable: true, validators: [Validators.pattern("^[0-9]*$")]} ),
      userName: new FormControl(this.newUser.userName, Validators.required),
      password: new FormControl(this.newUser.password, [
        Validators.required,
        Validators.pattern("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&]).{8,50}")
      ]),
      matchPassword: new FormControl(this.newUser.matchPassword, [Validators.required]),
    },
    {validators: matchPasswordValidator})
  }

  async addNewUser(){
    const progressDialog = this.dialog.open(ProgressAlertComponent, {
      data: {
        title: 'Adding New User...'
      }
    });
    try{
      this.newUser = this.newProviderUserForm.value;
      this.newUser.parentId = this.provider.Id;
      this.newUser.providerId = this.provider.Id;
      this.newUser.kind = "ProviderUser";
      if(this.userKind == "RootUser"){
        await this.usersService.addNewUserRoot(this.newUser);
      }
      else{
        await this.usersService.addNewUser(this.newUser);
      }
      this.addedNewUser.emit();
      progressDialog.close();
    }
    catch (e){
      progressDialog.close();
      if(e.error.includes("already exists")){
        this.matSnackBar.open( e.error + '. Please choose a different username.');
     }
     else{
       this.matSnackBar.open('Something went wrong.');
     }
    }
  }

  closeNewUser(){
    this.cancelNewUser.emit()
  }

}
