import { Component, OnInit } from '@angular/core';
import { Client } from '../components/classes/Client';
import { User } from '../components/classes/User';
import { ClientService } from '../services/client.service';
import { UsersService } from '../services/users.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ProgressAlertComponent } from '../components/dialogs/progress-alert/progress-alert.component';
import { plainToClass } from 'class-transformer';

@Component({
  selector: 'app-provider-setup',
  templateUrl: './provider-setup.component.html',
  styleUrls: ['./provider-setup.component.scss']
})
export class ProviderSetupComponent implements OnInit {

  provider: Client;
  providerUsers: Array<User> = [];
  editingProvider: boolean;
  subscriberID = localStorage.getItem("parentId") || null;
  userType =  localStorage.getItem("userKind") || "";
  isRootUser: boolean;
  addNewUser: boolean;

  constructor(private clientService:ClientService, private usersService:UsersService, private matSnackBar: MatSnackBar, private dialog:MatDialog) { }

  ngOnInit() {
    if(this.userType == "RootUser"){
      this.isRootUser = true;
    }
  }

}
