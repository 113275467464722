import { tap } from 'rxjs/operators';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {LoginService} from "./login.service";
import { TokenService } from './token.service';
import { ToolsService } from './tools-service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(public auth: TokenService, private loginService: LoginService, private toolsService: ToolsService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap({
        next: any =>{
          if(!this.auth.getToken() && !(this.toolsService.hasRoute('consumer') || this.toolsService.hasRoute('resetpw'))){
            this.loginService.logoutUser(true);
          }
        },
        error: error => {
          if (error instanceof HttpErrorResponse) {
            if(error.status === 400){
              //console.log("Here!");
            }
            if (error.status === 401) { //Not authorized. Token expired? Or navigating to a details page without a token. Logout and redirect to login page
              this.loginService.logoutUser(true);
            }
          }
        }
      })
    );
  }//End intercept

  
}//End class
