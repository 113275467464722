import { Component, OnInit } from '@angular/core';
import { ReportOptions, Reports } from '../components/classes/Reports';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ProgressAlertComponent } from '../components/dialogs/progress-alert/progress-alert.component';
import { ReportService } from '../services/report.service';
import { AlertsComponent } from '../components/dialogs/alerts/alerts.component';
import { ToolsService } from '../services/tools-service';
import { Client } from '../components/classes/Client';
import { ClientService } from '../services/client.service';
import { plainToClass } from 'class-transformer';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  reports: Array<Reports> = [];
  filteredReports: Array<Reports> = [];
  reportOptions: Array<ReportOptions> = [];
  isProcessingDelete: boolean = false;
  element: any;
  showEdit: any;
  showView: any;
  showNewReport: boolean;
  searchText: string;
  currentItemsToShow = [];
  defaultRecords: any = 10;
  clients: Array<Client> = [];
  userKind = localStorage.getItem("userKind") || null;

  constructor(private clientService:ClientService, private reportService: ReportService, private matSnackBar: MatSnackBar, private dialog:MatDialog, private toolService: ToolsService) { }

  async ngOnInit() {
    await this.getReports();
    this.getReportOptions()
  }

  setDefaultPaginatorItems(){
    this.currentItemsToShow = [];
    if(this.searchText){
      this.currentItemsToShow = this.filteredReports.slice(0, this.defaultRecords)
    }
    else{
      this.currentItemsToShow = this.reports.slice(0, this.defaultRecords)
    }  }

  onPageChange($event) {
    if(this.searchText){
      this.currentItemsToShow = this.filteredReports.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
    }
    else{
      this.currentItemsToShow = this.reports.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
    }
  }

  async getReports(){
    const progressDialog = this.dialog.open(ProgressAlertComponent, {
      data: {
        title: 'Loading Reports...'
      }
    });
    try{
      this.reports = [];
      if(this.userKind == 'ProviderUser'){
        this.getClients();
      }
      this.reports = this.reports.concat(await this.reportService.getReports());
      this.filteredReports = this.reports;
      this.setDefaultPaginatorItems();
      progressDialog.close();
    }
    catch(e){
      progressDialog.close();
      this.matSnackBar.open('Something went wrong. Please refresh.');
    }

  }

  filterReports(){
    this.filteredReports = [];
    if(this.searchText){
      this.reports.forEach( report => {
        const lowerReport = report.name.toLowerCase()
        if(lowerReport.includes(this.searchText.toLowerCase())){
          this.filteredReports.push(report)
        }
      });
    }
    this.setDefaultPaginatorItems();
  }

  saveReport(editedReport){
    this.closeView();
  }

  async addReport(newReport){
    const progressDialog = this.dialog.open(ProgressAlertComponent, {
      data: {
        title: 'Adding Report...'
      }
    });
    try{
      await this.reportService.postReport(newReport);
      this.showNewReport = false;
      this.reports = [];
      this.getReports();
      progressDialog.close();
    }
    catch(e){
      progressDialog.close();
      this.matSnackBar.open('Something went wrong when trying to add report.');
    }
  }

  async runReport(report){
    const progressDialog = this.dialog.open(ProgressAlertComponent, {
      data: {
        title: 'Running Report...'
      }
    });
    try{
      const data = await this.reportService.runReport(report.id , report.params);
      this.toolService.downloadCSV(data, report.name);
      progressDialog.close();
    }
    catch(e){
      progressDialog.close();
      this.matSnackBar.open('Something went wrong when trying to add report.');
    }
  }

  async getClients(){
    this.clients = [];
    const data = await this.clientService.getClientUnderProvider();
    this.clients = this.clients.concat(plainToClass(Client, data));
  }

  async getReportOptions(){
    this.reportOptions = this.reportOptions.concat(await this.reportService.getReportsOptions());
  }

  toggleNewReportVisibility(){
    this.showNewReport = !this.showNewReport;
  }

  showRunReport(){
    this.showView = this.showView;
  }

  editReport(){
    this.showEdit = !this.showEdit
  }

  closeNew(){
    this.showNewReport = false;
  }

  closeView(){
    this.showView = null;
    this.showEdit = null;
  }

  deleteReport(report: Reports){
    const dialogRef = this.dialog.open(AlertsComponent, {
      data: {
        message: `Are you sure you want to delete ${report.name}?`,
        title: "Confirm Removal",
        confirmButtonName: "REMOVE",
        isConfirmation: true,
        destructive: true
      }
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if(result) {
        await this.reportService.deleteReport(report.Id);
        await this.getReports();
      }});
  }

  getReportFieldDescription(reportId){
    return this.reportOptions.find(option => option.Id == reportId)?.displayName;
  }

}
