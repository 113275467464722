import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { lastValueFrom } from 'rxjs';
import { Profile } from '../components/classes/Profile';
import { UserPassword } from '../components/classes/UserPassword';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

constructor(private httpClient:HttpClient) { }

  async getProfile(userId){
    const data = await lastValueFrom(this.httpClient.get(`api/User?profileId=${userId}`))
    return plainToClass(Profile, data)
  }

  async putProfile(profile){
    const data = await lastValueFrom(this.httpClient.put(`api/User`, profile ))
    return plainToClass(Profile, data)
  }

  async changePassword(password: UserPassword){
    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json');

    const data = await lastValueFrom(this.httpClient.post('api/Account/ChangePassword',
      {OldPassword:password.oldPassword, NewPassword: password.newPassword, ConfirmPassword: password.confirmPassword},
      {headers: headers})
    )
  }

}
