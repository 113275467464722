import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Consumer, ConsumerColors } from 'src/app/components/classes/Consumer';
import { ConsumerTermsConditionsComponent } from '../consumer-terms-conditions/consumer-terms-conditions.component';

@Component({
  selector: 'app-consumer-agreement',
  templateUrl: './consumer-agreement.component.html',
  styleUrls: ['./consumer-agreement.component.scss']
})
export class ConsumerAgreementComponent implements OnInit {

  @Input() consumerInfo: Consumer;
  @Input() branding: ConsumerColors;
  @Input() preview: boolean;
  tuEnabled: boolean;
  xpnEnabled: boolean;
  efxEnabled: boolean;

  constructor(private matSnackBar: MatSnackBar, private dialog:MatDialog) { }

  ngOnInit() {
    if(!this.preview)
      this.checkBureausEnabled();
  }

  checkBureausEnabled(){
    this.tuEnabled = this.consumerInfo?.productSetup?.TU.enabled;
    this.xpnEnabled = this.consumerInfo?.productSetup?.XPN.enabled;
    this.efxEnabled = this.consumerInfo?.productSetup?.EFX.enabled;
  }

  goToTermsAndConditions(){
    this.dialog.open(ConsumerTermsConditionsComponent,{
      data:{
        branding: this.branding,
        consumerInfo: this.consumerInfo
      },
    })
  }

}


