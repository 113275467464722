import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export const matchPasswordValidator: ValidatorFn = ( control: AbstractControl): ValidationErrors | null => {
  return control.value.password === control.value.matchPassword ? null : { PasswordNoMatch: true };
};

export const confirmPasswordValidator: ValidatorFn = ( control: AbstractControl): ValidationErrors | null => {
  return control.value.newPassword === control.value.confirmPassword ? null : { PasswordNoMatch: true };
};
