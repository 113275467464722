<div class="fillBG">

    <div class="loginFieldsSection rqModal">

        <div class="pl gradientPrimary text-white loginTitle title">
            <span class="loginText">LOGIN</span>
        </div>

        <div style="padding:20px">
          <div class="loginFieldSection">
            <div class="w100">
              <input type="text" maxlength="50" autocomplete="new-username" placeholder="User Name" [(ngModel)]="loginValue.userId" />
            </div>
          </div>
            <div class="loginFieldSection" style="margin-bottom: 10px;">
                <div class="w100">
                  <input type="password" maxlength="50" autocomplete="new-password" placeholder="Password" [(ngModel)]="loginValue.password" (keyup.enter)="signIn()" />
              </div>
          </div>
          <div class="loginButtonPosition">
            <button type="button" class="pl btn-primary loginButton" (click)="signIn()" [disabled]="signInEnabled() == false" *ngIf="!isLoggingIn">LOGIN</button>
            <div class="loggingInLabel pl text-primary" *ngIf="isLoggingIn" >
              <i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i> Logging in...
            </div>
            <div class="failedLabel" *ngIf="errorLogin">
             {{errorMessage}}
          </div>
          </div>
          <mat-divider></mat-divider>
          <div class="m-t-10 rememberMe">
            <label class="i-checks i-checks-sm">
              <input type="checkbox" id="cbRememberMe" [(ngModel)]="rememberMe">
              <i></i>
              Remember Me
            </label>
          </div>
          <div>
              <a class="loginscreenButton forgotPW"   (click)="forgotPW()">FORGOT PASSWORD?</a>
          </div>
        </div>
    </div>

</div>
