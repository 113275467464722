<div class="row justify-content-end">
  <div class="col-md-2">
    <input type="text" class="form-control pull-right w m-r-lg"  placeholder="SEARCH" [(ngModel)]="searchText" (ngModelChange)="filterClient()"/>
  </div>
</div>
<div class="row">
  <div class="col-md-4">
    <h2>
      Client Setup
      <span class="badge pl bg-primary" style="font-size: 12pt;">{{clientsList?.length}}</span>
    </h2>
  </div>
  <div class="col-md-4 offset-md-4">
    <span class="fa-stack fa-pull-right text-lg m-t-10 clickable" (click)="toggleNewClientVisibility()">
      <i class="fa fa-circle fa-stack-2x pl" [ngClass]="{'text-danger':showNewClient, 'text-primary':!showNewClient}"></i>
      <i class="fa fa-stack-1x fa-inverse" [ngClass]="{'fa-plus':!showNewClient, 'fa-minus':showNewClient}"></i>
    </span>
  </div>
</div>
<div *ngIf="showNewClient">
  <app-client-setup-new (cancelNewClient)="closeNewClient($event)" (addedNewClient)="addedNewClient($event)"></app-client-setup-new>
</div>
<mat-divider class="m-t-10 m-b-10"></mat-divider>
<div *ngIf="clientsList">
  <div *ngFor="let client of currentItemsToShow">
    <app-client-setup-item [client]="client"></app-client-setup-item>
  </div>
  <mat-paginator class="m-t-10" [length]="clientsList?.length" (page)="onPageChange($event)" [pageSize]="10" [showFirstLastButtons]="true" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>


