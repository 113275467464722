import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Consumer, ConsumerColors } from 'src/app/components/classes/Consumer';

@Component({
  selector: 'app-consumer-terms-conditions',
  templateUrl: './consumer-terms-conditions.component.html',
  styleUrls: ['./consumer-terms-conditions.component.scss']
})
export class ConsumerTermsConditionsComponent implements OnInit {

  details: Consumer;
  branding: ConsumerColors;

  constructor(@Inject(MAT_DIALOG_DATA) public data: TermsConditions | any, private matSnackBar: MatSnackBar, private dialog:MatDialog) { }

  ngOnInit() {
    this.details = this.data?.consumerInfo;
    this.branding = this.data?.branding;
  }

  closeDialog(){
    this.dialog.closeAll()
  }

}

export interface TermsConditions{
  branding: ConsumerColors
  consumerInfo: Consumer
}


