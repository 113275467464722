import { ProductSetup } from "./Client";
import { LoanCategory } from "./LoanType";
import { Logo } from "./Logo";

export class Consumer {
  URL: string;
  address: string;
  branding: string;
  categories: Array<ConsmerLoanCategory>;
  city: string;
  companyName: string;
  loContactName: string;
  loEmail: string;
  loPhone: string;
  logo: Logo;
  originatorId: string;
  phone: string;
  productSetup: ProductSetup;
  state: string;
  subscriberId: string;
  welcomeMessage: string;
  zip: string;
}

export class ConsumerProduct{
  Id: string;
  description: string;
}

export class ConsmerLoanCategory{
  Id: string;
  dateCreated: string;
  description: string;
  icon: string;
  products: Array<ConsumerProduct>;
  status: string;
  subscriberId: string;
}

export class ConsumerColors{
  ButtonBackgroundColor: string;
  CategoryHeadingTextColor: string;
  FinishHeadingTextColor: string;
  FooterBackgroundColor: string;
  HeaderBackgroundColor: string;
  HeaderTextColor: string;
  SelectedTabBackgroundColor: string;
  UnselectedTabBackgroundColor: string;
}

export class ZipLookUpInfo{
  state:string;
  city: string;
}
