import { Component } from '@angular/core';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent {

    username: string = localStorage.getItem("readyQualUsername") || "";
    userType: string = localStorage.getItem("userKind") || "";

    constructor(private loginService: LoginService){}

    logOut(){
      this.loginService.logoutUser(true);

    }
}
