import { Component, OnInit } from '@angular/core';
import { LoginService } from '../services/login.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AlertsComponent } from '../components/dialogs/alerts/alerts.component';
import { MatDialog } from '@angular/material/dialog';
import { ProgressAlertComponent } from '../components/dialogs/progress-alert/progress-alert.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserTypePipe } from '../components/pipes/userTypePipe';
import { TokenService } from '../services/token.service';
// import packageJson from "package.json";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit{

  loginValue = {userId:localStorage.getItem("readyQualUsername") || "", password:""};
  loginLabelText: string | undefined;
  environment = environment;
  isLoggingIn = false;
  showTerms:boolean = false;
  show:boolean = true;
  rememberMe: boolean = false;
  passedInAction = "";
  passedInDisplayID = "";
  errorLogin : boolean;
  errorMessage: string = "";

  constructor(private loginService:LoginService, private tokenService: TokenService, private router:Router, private dialog:MatDialog, private matSnackBar: MatSnackBar, private userPipe: UserTypePipe) { }

  ngOnInit(): void {
    if(localStorage.getItem("token") && this.tokenService.isAuthenticated() && (localStorage.getItem("RqUserId"))){
      this.goToHomeScreen();
    }
    if(localStorage.getItem("RQRemember")){
      if(localStorage.getItem("readyQualUsername")){
        this.rememberMe = true;
      }
    }

  }

  signIn(){
    if(!this.signInEnabled()){
      return;
    }
    this.resetError()
    this.loginLabelText = '';
    this.isLoggingIn = true;
    this.errorLogin = false;
    this.loginService.loginUser(this.loginValue.userId, this.loginValue.password, '').then(
      res => {
        if(res.success){
          if(this.rememberMe){
            localStorage.setItem("RQRemember", 'true');
          }
          localStorage.setItem("readyQualUsername", this.loginValue.userId);
          const userType = localStorage.getItem('userKind');
          const pipedUser = this.userPipe.transform(userType);
          this.goToHomeScreen();
        }
        if(res.error){
          this.errorLogin = true;
          this.errorMessage = res.message;
        }
        this.isLoggingIn = false;
      });
  }

  goToHomeScreen(){
    const userType = localStorage.getItem('userKind');
    const pipedUser = this.userPipe.transform(userType);
    switch(userType){
      case 'RootUser':
        this.router.navigate([`app/${pipedUser}/managers/list`]);
        break;
      case 'ProviderUser':
        this.router.navigate([`/app/${pipedUser}/clients/list`]);
        break;
      default:
        this.router.navigate([`/app/${pipedUser}/orders/list`]);
        break;
    }
  }

  signInEnabled() {
    return this.loginValue.userId != "" && this.loginValue.password != "";
  }

  async forgotPW(){
    this.resetError()
    if(this.loginValue.userId){
      const progressDialog = this.dialog.open(ProgressAlertComponent, {
        data: {
          title: 'Loading...'
        }
      });
      try{
        await this.loginService.forgotPassword(this.loginValue.userId);
        progressDialog.close();
        this.dialog.open(AlertsComponent, {
          data: {
            message: 'An email has been sent with a link to reset your password.',
            title: "Email Sent",
            isConfirmation: false,
            destructive: true
          }
        });
      }
      catch{
        progressDialog.close();
        this.matSnackBar.open('Something went wrong');
      }
    }
    else{
      this.errorLogin = true;
      this.errorMessage = "User name is required in order to request a password reset."
    }
  }

  resetError(){
    this.errorLogin = false;
    this.errorMessage = ""
  }

}
