export class Reports {
  Id: string
  type: string;
  name: string;
  status: string;
  defaultFilters: string;
  customQuery: string;
  customParams: string;
  fields: Array<ReportFields> = [];
  profileId: string;
  userOnly: boolean;
  options: string;
}

export class ReportFields{
  Id: string;
  sort: string;
}

export class ReportOptions{
  Id: string;
  SelectClauseAdditions: string;
  displayName: string;
  fieldName: string;
  reportSort: string;
  select: boolean;
  sortOrder: string;
  tableName: string;
  type: string;
}

export class ReportVariables{
  clientCode: string = null;
  clientId: string = null;
  dateFrom: string;
  dateTo: string;
  emailReport?: boolean = false;
  filterDateCompleted?: boolean = false;
  runFields: Array<ReportFields> = [];
  status: string = null;
}
