import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Logo } from '../components/classes/Logo';
import { environment } from 'src/environments/environment';
import { UserTypePipe } from '../components/pipes/userTypePipe';
import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  UserType =  localStorage.getItem("userKind") || "";
  subscriberId = localStorage.getItem('parentId') || "";
  logo: string = localStorage.getItem("RqLogo") || null
  logoURL: string;
  viewOutput: string = "ViewOutput.aspx";

  constructor(private router: Router, private userPipe: UserTypePipe, private httpClient:HttpClient) { }

  ngOnInit() {
    this.setLogo();
  }

  async setLogo(){
    if(this.logo){
      var parsedLogo: Logo = JSON.parse(this.logo);
      if(parsedLogo.url){
        this.logoURL = environment.apiUrl + "/"+ this.viewOutput + "?ProfileId=" + this.subscriberId
      }
      else{
        this.logoURL =  environment.apiUrl + '/api/Account/privateLabelLogos';
      }
    }
    else{
      this.logoURL =  environment.apiUrl + '/api/Account/privateLabelLogos';
    }
  }

  routeToClientPage(){
    let route = ""
    if(this.UserType == 'ProviderUser'){
      route = `app/Provider/clients/list`;
    }
    else{
      const type = this.userPipe.transform(this.UserType)
      route = `app/${type}/clients/details`;
    }
    this.router.navigate([route]);
  }

  routeToUserPage(){
    let route = ""
    if(this.UserType == "ProviderUser"){
      route =`app/Provider/client-users/active`;
    }
    else{
      const type = this.userPipe.transform(this.UserType)
      route =`app/${type}/users`;
    }
    this.router.navigate([route]);
  }

  routeToProvider(){
    let route = ""
    const type = this.userPipe.transform(this.UserType)
    route = `app/${type}/managers/details`
    this.router.navigate([route]);
  }

  routeToOrders(){
    let route = ""
    const type = this.userPipe.transform(this.UserType)
    route = `app/${type}/orders/list`
    this.router.navigate([route]);
  }

  routeToReports(){
    let route = ""
    const type = this.userPipe.transform(this.UserType)
    route = `app/${type}/reports/list`
    this.router.navigate([route]);
  }

  goToHomeState(){
    switch(this.UserType){
      case "ProviderUser":
        this.routeToClientPage();
        break;
      case "RootUser":
        this.routeToProvider();
        break;
      default:
        this.routeToOrders();
        break;
    }
  }

}
