import { Activities } from './Activities';

export class Request {
  Id: string
  XOLRefNum: string;
  address: string;
  archived: boolean;
  activities: Activities[];
  city: string;
  contactAllowed: boolean;
  dateCompleted: Date;
  dateRequested: Date;
  email: string;
  firstName:string;
  lastName:string;
  loanTypeName:string;
  middleName:string;
  originatorID: string;
  originatorName: string;
  phone: number;
  prequalified: boolean;
  providerId: string;
  refNum: number;
  score: number;
  status: number;
  statusName: string;
  state:string;
  suscriberId: string;
  subscriberLoanTypeId: string;
  xolOutputURL:boolean;
  zip: number;
}

export class RequestData{
  address: string;
  agreed: boolean;
  city: string;
  contactAllowed: boolean;
  email: string;
  firstName: string;
  lastName: string;
  middleName: string;
  originatorId: string;
  phone: string;
  ssn: string;
  state: string;
  subscriberId: string;
  subscriberLoanTypeId: string;
  zip: string = "";
}

export class RequestResponse{
  LoanType: string;
  contactHeader: string;
  header: string;
  message: string;
  prequalified: string;
  qualification: string;
  requestId: string;
}

export namespace Request {
  export enum RequestStatus {
      Requested = 1,
      Completed = 2,
      Rejected = 3,
      Archived = 4,
      Error = 9
    }
}
