import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { DeclineReasons, LoanCategory, LoanType, NewLoanType, Product, Qualifications, UserInfo } from 'src/app/components/classes/LoanType';
import { User } from 'src/app/components/classes/User';
import { scoreQualificationValid } from 'src/app/components/validators/scoresValidator';
import { ClientService } from 'src/app/services/client.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-product-setup-new',
  templateUrl: './product-setup-new.component.html',
  styleUrls: ['./product-setup-new.component.scss']
})
export class ProductSetupNewComponent implements OnInit {

  newProductForm: FormGroup;
  newProduct: NewLoanType;
  @Input() addableUsers: Array<User> = [];
  @Input() loanCategory: Array<LoanCategory>;
  addUsers: boolean;
  @Output() cancelNewProduct: EventEmitter<any> = new EventEmitter;
  @Output() addedNewProduct: EventEmitter<any> = new EventEmitter;

  constructor(private clientService:ClientService, private usersService:UsersService) { }

  async ngOnInit() {
    this.newProduct = new NewLoanType();
    await this.generateForm();
    await this.addQualification();
  }

  generateForm(){
    this.newProductForm = new FormGroup({
      categoryId: new FormControl(this.newProduct.categoryId, {validators: [Validators.required]},),
      description: new FormControl(this.newProduct.description,{validators: [Validators.required]},),
      // status: new FormControl(this.newProduct.status, {nonNullable: true, validators: [Validators.required]},),
      qualifications: new FormArray([]),
      bankruptcy: new FormControl(false),
      foreclosure: new FormControl(false),
      pastDueMortgagePayment: new FormControl(false),
      shortSale: new FormControl(false),
    });

  }

  addQualification(){
    const newQual = new Qualifications()
    this.newProduct.qualifications.push(newQual);
    const newFormGroup = this.qualificationValues(this.newProduct.qualifications[this.newProduct.qualifications.length - 1])
    this.qualifications.push(newFormGroup);
  }

  get qualifications() {
    return this.newProductForm.get('qualifications') as FormArray;
  }

  removeQualification(index){
    this.qualifications.removeAt(index)
  }

  async saveNewProduct(){
    await this.setNewProductValues()
    this.addedNewProduct.emit(this.newProduct)
  }

  closeNewProduct(){
    this.cancelNewProduct.emit();
  }

  setNewProductValues(){
    this.newProduct.declineReasons = new DeclineReasons();
    this.newProduct.declineReasons.bankruptcy = this.newProductForm.value.bankruptcy;
    this.newProduct.declineReasons.foreclosure = this.newProductForm.value.foreclosure;
    this.newProduct.declineReasons.pastDueMortgagePayment = this.newProductForm.value.pastDueMortgagePayment;
    this.newProduct.declineReasons.shortSale = this.newProductForm.value.shortSale;
    this.newProduct.qualifications = this.newProductForm.value.qualifications;
    this.newProduct.categoryId = this.newProductForm.value.categoryId;
    this.newProduct.description = this.newProductForm.value.description;

    this.newProduct.qualifications.forEach( x =>{
      x.prequalifiedAmount = parseInt(x.prequalifiedAmount.toString())
      x.scoreEnd = parseInt(x.scoreEnd.toString())
      x.scoreStart = parseInt(x.scoreStart.toString())
    })

    this.newProduct.userInfo.forEach(user => {
      this.newProduct.users.push(user.Id);
    })
  }

  qualificationValues(qual: Qualifications){
    const values = new FormGroup({
      apr: new FormControl(qual.apr, {nonNullable: true, validators: [Validators.required]}) ,
      monthlyPayment: new FormControl(qual.monthlyPayment, {nonNullable: true, validators: [Validators.required]}),
      prequalifiedAmount:new FormControl(qual.prequalifiedAmount, {nonNullable: true, validators: [Validators.required]}),
      scoreEnd: new FormControl(qual.scoreEnd, {nonNullable: true, validators: [Validators.required]}),
      scoreStart: new FormControl(qual.scoreStart, {nonNullable: true, validators: [Validators.required]}),
      terms: new FormControl(qual.terms, {nonNullable: true, validators: [Validators.required]})
    },{validators: scoreQualificationValid});
    return values;
  }

  filterUser(item){
    const length = this.newProduct.userInfo.filter(x => x.Id == item.Id).length
    return length == 0;
  }

  toggleAddUsers(){
    this.addUsers = !this.addUsers
  }

  async removeUserFromProduct(user: UserInfo){
    const userIndex = this.newProduct.userInfo.indexOf(user);
    this.newProduct.userInfo.splice(userIndex, 1);
  }

  async addUserToProduct(user: User){
    this.newProduct.userInfo.push({Id: user.Id, code: user.code, contact: user.contact})
  }


}


