import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProgressAlertComponent } from '../components/dialogs/progress-alert/progress-alert.component';
import { Client } from '../components/classes/Client';
import { ClientService } from '../services/client.service';
import { plainToClass } from 'class-transformer';

@Component({
  selector: 'app-client-setup',
  templateUrl: './client-setup.component.html',
  styleUrls: ['./client-setup.component.scss']
})
export class ClientSetupComponent implements OnInit {

  client: Client;
  subscriberID = localStorage.getItem("parentId") || null;
  userKind = localStorage.getItem("userKind") || null;
  editingClient: boolean;
  clientsList: Array<Client> = [];
  filteredClients: Array<Client> = [];
  clientsListLength: number;
  showNewClient: boolean;


  constructor(private clientService:ClientService, private matSnackBar: MatSnackBar, private dialog:MatDialog) { }

  ngOnInit() {
  }


  toggleNewClientVisibility(){
    this.showNewClient = !this.showNewClient;
  }


}
