import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { lastValueFrom } from 'rxjs';
import { Profile } from '../components/classes/Profile';
import { Consumer } from '../components/classes/Consumer';

@Injectable({
  providedIn: 'root'
})
export class ConsumerService {

  constructor(private httpClient:HttpClient) { }

  async getClientInfo(clientCode: string){
    const data = await lastValueFrom(this.httpClient.get(`/api/Consumer/${clientCode}`))
    return data;
  }

  async sendRequest(requestInfo){
    return await lastValueFrom(this.httpClient.post(`api/Request`, requestInfo))
  }

  async postContact(id){
    await lastValueFrom(this.httpClient.post("api/Contact?RequestId=" + id, {}))
  }


}
