<div class="row justify-content-end" >
  <div class="col-md-2">
    <input type="text" class="form-control pull-right w m-r-lg" placeholder="SEARCH" [(ngModel)]="searchText" (change)="filterProvider()"/>
  </div>
</div>
<div class="row">
  <div class="col-md-4">
    <h2>
      Provider Setup
      <span class="badge pl bg-primary" style="font-size: 12px;"> {{providers?.length}} </span>
    </h2>
  </div>
  <div class="col-md-4 offset-md-4">
    <span class="fa-stack fa-pull-right text-lg m-t-10 clickable" (click)="toggleNewProviderVisibility()">
      <i class="fa fa-circle fa-stack-2x pl" [ngClass]="{'text-danger':showNewProvider, 'text-primary':!showNewProvider}"></i>
      <i class="fa fa-stack-1x fa-inverse" [ngClass]="{'fa-plus':!showNewProvider, 'fa-minus':showNewProvider}"></i>
    </span>
  </div>
</div>
<mat-divider class="m-t-10 m-b-10"></mat-divider>
<div *ngIf="showNewProvider" class="m-b-10">
  <app-provider-setup-new-provider (addedNewProvider)="addedNewProvider($event)" (cancelNewProvider)="closeNewUser($event)"></app-provider-setup-new-provider>
</div>
<div>
  <div *ngFor="let item of currentItemsToShow" class="m-b-10">
    <app-provider-setup-main [provider]="item"></app-provider-setup-main>
  </div>
</div>
<mat-paginator [length]="providers?.length" (page)="onPageChange($event)" [pageSize]="10" [showFirstLastButtons]="true" [pageSizeOptions]="[5, 10, 25, 100]" class="m-t-10"></mat-paginator>

