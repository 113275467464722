<div class="p-a-20">
  <div [ngStyle]="{'color':branding.HeaderTextColor}">
    <h2 >Legal Terms</h2>
  </div>


  <div class="neutral-bar"></div>

  <p>I certify that:</p>

  <ul>
      <li>I am a U.S. citizen or permanent resident.</li>
      <li>I am 18-years of age or older.</li>
      <li>I am not applying on behalf of someone else.</li>
      <li>I understand that this is not an application for credit.</li>
      <li>I understand that this is a soft inquiry and will not impact my credit score.</li>
      <li>I understand that I will have to complete a full application and meet the underwriting criteria including but not limited to income, debt and credit.</li>
      <li>I understand that I may be contacted by this lender for additional information</li>
  </ul>

  <p>For other questions, please contact us directly at {{details.phone | mask:'(000) 000-0000'}}</p>

  <div class="text-center">
    <button mat-flat-button style="color: #fff;"  class="m-t" (click)="closeDialog()" [ngStyle]="{background:branding.ButtonBackgroundColor}">Close</button>
  </div>
</div>
