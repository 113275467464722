import { Client } from "./Client";

export class LoanType {
  Id: string;
  categoryId: string;
  categoryName : string;
  dateCreated: string;
  declineReasons: DeclineReasons;
  description: string;
  qualifications: Array<Qualifications> = [];
  status: number;
  userInfo: Array<UserInfo> = [];
  users: Array<Client> = [];
}

export class NewLoanType {
  Id: string;
  categoryId: string;
  categoryName : string;
  dateCreated: string;
  declineReasons: DeclineReasons;
  description: string;
  qualifications: Array<Qualifications> = [];
  status: number;
  userInfo: Array<UserInfo> = [];
  users: Array<string> = [];
}

export class DeclineReasons{
  bankruptcy: boolean = false;
  foreclosure: boolean = false;
  shortSale: boolean = false;
  pastDueMortgagePayment: boolean = false;
}

export class Qualifications{
  apr: string;
  monthlyPayment: string;
  prequalifiedAmount: number;
  scoreEnd: number;
  scoreStart: number;
  terms: string;
}

export class Product{
  Id: string;
  categoryId: string;
}

export class UserInfo{
  Id: string;
  code: string;
  contact: string;
}

export class LoanCategory{
  Id: string;
  dateCreated: string;
  description: string;
  icon: string;
  products: Array<Product>;
  status: string;
  subscriberId: string;
}
