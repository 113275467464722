import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ReportOptions, Reports } from 'src/app/components/classes/Reports';

@Component({
  selector: 'app-report-new',
  templateUrl: './report-new.component.html',
  styleUrls: ['./report-new.component.scss']
})
export class ReportNewComponent implements OnInit {

  @Input() reportOptions: Array<ReportOptions>
  newReport: Reports = new Reports();
  // newReportForm: FormGroup;
  @ViewChild("newReportForm") newReportForm: NgForm;
  @Output() closeView: EventEmitter<any> = new EventEmitter;
  @Output() newReportAdded: EventEmitter<any> = new EventEmitter;



  constructor() { }

  ngOnInit() {
    this.newReport.type = "Custom"
    // this.newReportForm = new FormGroup({
    //   userOnly: new FormControl(this.newReport.userOnly, [Validators.required]),
    //   name: new FormControl(this.newReport.name, [Validators.required]),
    // })
  }

  async createReport(){
    await this.setReportFields();
    this.newReportAdded.emit(this.newReport);
  }

  setReportFields(){
   this.reportOptions.forEach(element=>{
    if(element.select){
      this.newReport.fields.push({Id: element.Id, sort: element.reportSort})
    }
   });
  }

  closeViewFunc(){
    this.closeView.emit();
  }

}
