<div class="center-screen consumerScreen">
    <div class="topBar p-t-15 p-l-15" [style.background-color]="branding?.HeaderBackgroundColor" [style.color]="branding?.HeaderTextColor">
      <div class="consumerTitle" style="margin-bottom: 0;">Contact</div>
    </div>
    <div class="consumerContent centerDiv" style="display: inline-grid;" *ngIf="consumerInfo">
      <div>
        <h2 *ngIf="contactError" class="text-danger">An error has occurred.</h2>
        <h2 *ngIf="setContact"[ngStyle]="{'color':branding?.FinishHeadingTextColor}">Thank you! You will be contacted soon.</h2>
        <mat-divider></mat-divider>
        <div class="text-center">
          <h2 class="m-t-20" [ngStyle]="{'color':branding?.FinishHeadingTextColor}">Contact or Visit Us<br/>{{consumerInfo?.companyName}}</h2>
          <div class="m-t-20" [hidden]="!consumerInfo?.loPhone">{{consumerInfo?.loPhone | mask:'(000) 000-000'}}</div>
          <div class="m-t-5"><a href="{{'mailto:'+ consumerInfo.loEmail}}">{{consumerInfo?.loEmail}}</a></div>
          <div class="m-t-10">Pre-Qualification From {{consumerInfo?.companyName}}</div>
          <div class="m-t-5">{{consumerInfo?.address}}, {{consumerInfo?.city}}, {{consumerInfo?.state}} {{consumerInfo?.zip}}</div>
        </div>
      </div>
    </div>\
</div>
