<div class="fillBG">

    <div class="loginFieldsSection rqModal">

        <div class="pl gradientPrimary text-white loginTitle title">
            <span class="loginText">RESET PASSWORD</span>
        </div>

        <div style="padding:20px">
            <form *ngIf="resetPasswordForm || !passwordChanged" [formGroup]="resetPasswordForm">

                <div class="loginFieldSection">
                    <div class="w100">
                        <input type="text" maxlength="50" disabled  placeholder="User Name" [ngModelOptions]="{standalone: true}" [ngModel]="resetPwParams?.UserName" /> 
                    </div>
                </div>
                <div class="loginFieldSection" style="margin-bottom: 10px;">
                    <div class="w100">
                      <input type="password" maxlength="50" placeholder="Password" formControlName="newPassword"/>
                      <div class="text-danger" *ngIf="resetPasswordForm.controls['newPassword'].dirty && resetPasswordForm.controls['newPassword'].invalid">
                        <small class="text-danger" *ngIf="resetPasswordForm.controls['newPassword'].hasError('pattern')">
                          Invalid password format. Passwords must be at least 8 characters in length, with at least one of
                          each of the following: digit, lowercase letter, uppercase letter, and non-letter/non-digit.
                        </small>
                          <small class="text-danger" *ngIf="resetPasswordForm.controls['newPassword'].hasError('required')">
                              Password is required.
                          </small>
                      </div>
                    </div>
                </div>
                <div class="loginFieldSection" style="margin-bottom: 10px;">
                    <div class="w100">
                        <input type="password" maxlength="50" placeholder="Confirm Password" formControlName="confirmPassword"  (keyup.enter)="forgotPassword()"/>
                        <small class="text-danger" *ngIf="resetPasswordForm.controls['confirmPassword'].dirty && resetPasswordForm.errors?.['PasswordNoMatch']">
                            Passwords don't match.
                          </small>
                        <div class="text-danger" *ngIf="resetPasswordForm.controls['confirmPassword'].dirty && resetPasswordForm.controls['confirmPassword'].invalid">
                            <small class="text-danger" *ngIf="resetPasswordForm.controls['confirmPassword'].hasError('required')">
                                Confirm password is required.
                            </small>
                        </div>
                  </div>
                </div>
                <div class="loginButtonPosition">
                    <button type="button" class="pl btn-primary loginButton" (click)="forgotPassword()" [disabled]="resetPasswordForm.invalid && resetPwParams.UserName != ''" >CHANGE PASSWORD</button>
                </div>
            </form>   
            
         <div *ngIf="errorMessage">
            <mat-divider></mat-divider>
            <p class="text-danger">{{errorMessage}}</p>
                <button type="button" class="pl btn-primary loginButton m-t-15" (click)="goToLogin()" >Go to Login Page</button>
             </div>
            
            <div *ngIf="passwordChanged">
                <p>Your password has been changed.</p>
                <button type="button" class="pl btn-primary loginButton m-t-15" (click)="goToLogin()" >Go to Login Page</button>
            </div>
        </div>
    </div>

</div>
