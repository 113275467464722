<div class="selectedProduct" >
  <div class="row" *ngIf="!testProduct">
    <div class="col centerDiv" [style.color]="branding.CategoryHeadingTextColor">
      <i [ngClass]="logo" class="m-r-10"></i><span >{{selectedProduct?.description}}</span>
      <button class="m-l-10 buttonColor" mat-flat-button [style.background-color]="branding?.ButtonBackgroundColor" (click)="changeProduct()" [hidden]="isFinish">Change</button>
    </div>
  </div>
  <div class="row" *ngIf="testProduct">
    <div class="col centerDiv" [style.color]="branding?.CategoryHeadingTextColor">
      <i [ngClass]="getDesctiptionIcon(testProduct)" class="m-r-10"></i><span >Test Product</span>
      <button class="m-l-10 buttonColor" mat-flat-button [style.background-color]="branding?.ButtonBackgroundColor" (click)="changeProduct()" [hidden]="isFinish">Change</button>
    </div>
  </div>
</div>
