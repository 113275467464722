import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConsumerService } from '../services/consumer.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ProgressAlertComponent } from '../components/dialogs/progress-alert/progress-alert.component';
import { ConsmerLoanCategory, Consumer, ConsumerColors, ConsumerProduct, ZipLookUpInfo } from '../components/classes/Consumer';
import { plainToClass } from 'class-transformer';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClientColors } from '../components/classes/Client';
import { RequestData, RequestResponse } from '../components/classes/Request';
import { MatStepper } from '@angular/material/stepper';
import { RECAPTCHA_V3_SITE_KEY, ReCaptchaV3Service } from 'ng-recaptcha';
import { States } from '../components/constants/UsStates';
import { environment } from 'src/environments/environment';
import { ToolsService } from '../services/tools-service';

@Component({
  selector: 'app-consumer',
  templateUrl: './consumer.component.html',
  styleUrls: ['./consumer.component.scss']
})
export class ConsumerComponent implements OnInit {

  requestInfo: RequestData;
  clientCode: string;
  previewMode: boolean;
  consumerInfo: Consumer;
  informationForm: FormGroup;
  clientColors: ConsumerColors;
  selectedProduct = {
    product: new ConsumerProduct(),
    icon: ''
  };
  recaptcha: string;
  // key = RECAPTCHA_V3_SITE_KEY.toString();
  @ViewChild('stepper') myStepper: MatStepper;
  zipData: ZipLookUpInfo;
  requestResponse: RequestResponse;
  statesList = States;
  consumerTitle: string = "Welcome";
  beginRequest: boolean;
  companyLogoUrl: string;
  viewOutput: string = "ViewOutput.aspx";
  tuEnabled: boolean;
  xpnEnabled: boolean;
  efxEnabled: boolean;

  constructor(private route: ActivatedRoute, private toolService: ToolsService ,private consumerService: ConsumerService, private matSnackBar: MatSnackBar, private dialog:MatDialog) { }

  ngOnInit() {
    this.requestInfo = new RequestData();
    this.zipData = new ZipLookUpInfo();
    this.clientCode = this.route.snapshot.paramMap.get('clientCode');
    if(this.clientCode){
      this.previewMode = true;
    }
    this.getConsumerData();
    this.checkBureausEnabled();
    this.setForm();
    this.setComsumerTitle();

    // this.recaptchaV3Service.execute('importantAction')
    // .subscribe((token) => console.log(token));
  }

  setStepperStyles(){
    document.documentElement.style.setProperty('--mat-stepper-header-selected-state-icon-background-color', this.clientColors.SelectedTabBackgroundColor);
    document.documentElement.style.setProperty('--mat-stepper-header-done-state-icon-background-color', this.clientColors.SelectedTabBackgroundColor);
    document.documentElement.style.setProperty('--mat-stepper-header-selected-state-label-text-color', this.clientColors.SelectedTabBackgroundColor);
    document.documentElement.style.setProperty('--mat-stepper-header-edit-state-icon-background-color', this.clientColors.UnselectedTabBackgroundColor);
    document.documentElement.style.setProperty('--mat-stepper-header-icon-background-color', this.clientColors.UnselectedTabBackgroundColor);
    document.documentElement.style.setProperty('--mat-stepper-header-optional-label-text-color', this.clientColors.UnselectedTabBackgroundColor);
    document.documentElement.style.setProperty('--mat-stepper-header-label-text-color', this.clientColors.UnselectedTabBackgroundColor);
  }

  setLogo(){
    if(this.consumerInfo.logo && this.consumerInfo.logo.showOnConsumer){
      this.companyLogoUrl = environment.apiUrl + "/"+ this.viewOutput + "?ProfileId=" + this.consumerInfo.subscriberId
   }
   else{
    this.companyLogoUrl = null;
   }
  }

  checkBureausEnabled(){
    this.tuEnabled = this.consumerInfo?.productSetup?.TU.enabled;
    this.xpnEnabled = this.consumerInfo?.productSetup?.XPN.enabled;
    this.efxEnabled = this.consumerInfo?.productSetup?.EFX.enabled;
  }

  setForm(){
    this.informationForm = new FormGroup({
      firstName: new FormControl(this.requestInfo.firstName,{nonNullable: true, validators: [Validators.required]}),
      middleName: new FormControl(this.requestInfo.middleName),
      lastName: new FormControl(this.requestInfo.firstName,{nonNullable: true, validators: [Validators.required]}),
      email: new FormControl(this.requestInfo.email,{nonNullable: true, validators: [Validators.required, Validators.email]}),
      ssn: new FormControl(this.requestInfo.ssn,{nonNullable: true}),
      contactPhone: new FormControl(this.requestInfo.phone,{nonNullable: true, validators: [Validators.required]}),
      address: new FormControl(this.requestInfo.address,{nonNullable: true, validators: [Validators.required]}),
      zip: new FormControl(this.requestInfo.zip,{nonNullable: true, validators: [Validators.required]}),
      city: new FormControl(this.requestInfo.city,{nonNullable: true, validators: [Validators.required]}),
      state: new FormControl(this.requestInfo.state,{nonNullable: true, validators: [Validators.required]})
    });
  }


  resolvedCaptcha(captchaResponse: string){
    this.recaptcha = captchaResponse;
  }

  async zipcodeLookUp(zip: string){
    if(this.informationForm.value.zip.length >= 5){
      this.zipData =  await this.toolService.zipLookUp(this.informationForm.value.zip);
      if(this.zipData){
        this.informationForm.patchValue({
          state: this.zipData.state,
          city: this.zipData.city
        })
      }
    }
  }

  setComsumerTitle(){
    const index = this.myStepper?.selectedIndex;
    switch(index){
      case 0:
        this.consumerTitle = "Choose a Product"
        break;
      case 1:
        this.consumerTitle = "Information"
        break;
      case 2:
        this.consumerTitle = "Agreements"
        break;
      case 3:
        this.consumerTitle = "Finish"
        break;
      default:
        this.consumerTitle = "Welcome"
        break;
    }
  }

  begin(){
    this.beginRequest = true;
    this.consumerTitle = "Choose a Product"
  }

  goBack(){
    this.myStepper.previous();
    this.setComsumerTitle()

  }

  goForward(){
    this.myStepper.next();
    this.setComsumerTitle()
  }

  goToStepperIndex(index){
    this.myStepper.selectedIndex = index;
  }

  getDesctiptionIcon(item){
    if(item == 'Mortgage'){
      return "fa-solid fa-house";
    }
    else if(item == 'Auto'){
      return "fa-solid fa-car";
    }
    else if(item == 'Credit'){
      return "fa-solid fa-credit-card";
    }
    else if(item == 'Business Finance'){
      return "fa-solid fa-dollar-sign";
    }
    else{
      return "";
    }
  }

  selectProduct(product: ConsumerProduct, category: ConsmerLoanCategory ){
    this.selectedProduct.product = product;
    this.requestInfo.subscriberLoanTypeId = this.selectedProduct.product.Id;
    this.selectedProduct.icon = this.getDesctiptionIcon(category.description);
    this.goForward();
  }

  async getConsumerData(){
    const progressDialog = this.dialog.open(ProgressAlertComponent, {
      data: {
        title: 'Loading...'
      }
    });
    try{
      const data = await this.consumerService.getClientInfo(this.clientCode);
      this.consumerInfo = plainToClass(Consumer, data);
      this.requestInfo.originatorId = this.consumerInfo.originatorId;
      this.requestInfo.subscriberId = this.consumerInfo.subscriberId;
      this.setClientStyles();
      this.setLogo();
      progressDialog.close();
    }
    catch{
      progressDialog.close();
      this.matSnackBar.open('Something went wrong');
    }
  }

  setClientStyles(){
    this.clientColors = JSON.parse(this.consumerInfo.branding);
    this.setStepperStyles();
  }

  setInformation(){
    // this.requestInfo.
    this.requestInfo.firstName = this.informationForm.value.firstName;
    this.requestInfo.middleName = this.informationForm.value.middleName;
    if(!this.requestInfo.middleName){
      this.requestInfo.middleName = "";
    }
    this.requestInfo.lastName = this.informationForm.value.lastName;
    this.requestInfo.email = this.informationForm.value.email;
    this.requestInfo.ssn = this.informationForm.value.ssn;
    this.requestInfo.phone = this.informationForm.value.contactPhone;
    this.requestInfo.address = this.informationForm.value.address;
    this.requestInfo.city = this.informationForm.value.city;
    this.requestInfo.state = this.informationForm.value.state;
    this.requestInfo.zip = this.informationForm.value.zip;
    if(this.recaptcha){
      this.requestInfo.agreed = true;
    }
    this.requestInfo.contactAllowed = false;
  }

  async sendRequest(){
    const progressDialog = this.dialog.open(ProgressAlertComponent, {
      data: {
        title: 'Running Report...'
      }
    });
    try{
      await this.setInformation();
      const data = await this.consumerService.sendRequest(this.requestInfo);
      this.requestResponse = plainToClass(RequestResponse, data);
      this.goForward();
      progressDialog.close();
    }
    catch{
      progressDialog.close();
      this.matSnackBar.open('Something went wrong');
    }
  }
}
