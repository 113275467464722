import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { InboxOrdersComponent } from './inbox-orders/inbox-orders.component';
import { AuthRoutingService } from './services/auth-routing.service';
import { UserSetupComponent } from './user-setup/user-setup.component';
import { ClientSetupComponent } from './client-setup/client-setup.component';
import { ProductSetupComponent } from './product-setup/product-setup.component';
import { ReportsComponent } from './reports/reports.component';
import { UserprofileSettingsComponent } from './userprofile-settings/userprofile-settings.component';
import { ProviderSetupComponent } from './provider-setup/provider-setup.component';
import { ConsumerComponent } from './consumer/consumer.component';
import { ConsumerContactComponent } from './consumer/consumer-contact/consumer-contact.component';
import { ResetpwComponent } from './resetpw/resetpw.component';

const routes: Routes = [
  { path:'', redirectTo:'app/access/signin', pathMatch: 'full' },
  { path:'app/access/signin', component: LoginComponent, pathMatch: 'full' },
  { path:'access/resetpwd', component: ResetpwComponent},
  { path:'app/:userType/orders/list', component: InboxOrdersComponent, canActivate:[AuthRoutingService]},
  { path:'app/:userType/clients/details', component: ClientSetupComponent, canActivate:[AuthRoutingService]},
  { path:'app/:userType/clients/list', component: ClientSetupComponent, canActivate:[AuthRoutingService]},
  { path:'app/:userType/users', component: UserSetupComponent, canActivate:[AuthRoutingService]},
  { path:'app/:userType/client-users/:status', component: UserSetupComponent, canActivate:[AuthRoutingService]},
  { path:'app/:userType/products', component: ProductSetupComponent, canActivate:[AuthRoutingService]},
  { path:'app/:userType/managers/details', component: ProviderSetupComponent, canActivate:[AuthRoutingService]},
  { path:'app/:userType/managers/list', component: ProviderSetupComponent, canActivate:[AuthRoutingService]},
  { path:'app/:userType/reports/list', component: ReportsComponent, canActivate:[AuthRoutingService]},
  { path:'app/profile', component: UserprofileSettingsComponent, canActivate:[AuthRoutingService]},
  { path:'consumer/:clientCode', component: ConsumerComponent},
  { path:'consumer/:clientCode/contact/:requestId', component: ConsumerContactComponent},
  { path:'**', redirectTo:'app/access/signin'},

]

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {


}
