<div class="contentContainer">
  <h2>Profile
    <div class="pull-right">

        <button type="button" class="pl btn btn-primary m-l" [hidden]="showChangePassword" (click)="toggleChangePassword()">
            Change Password
        </button>
    </div>
  </h2>
  <mat-divider class="m-t-10 m-b-10"></mat-divider>

  <div class="providerUserSettings">
    <form *ngIf="profileForm" [formGroup]="profileForm">
      <div class="row m-t-20">
        <div class="col-md-4 text-secondary">Username:</div>
        <div class="col-md-4 text-secondary">Email:</div>
        <div class="col-md-4 text-secondary">ID:</div>
      </div>
      <div class="row m-t-5 m-b-30">
        <div class="col-md-4">
          <span>{{profile?.userName}}</span>
        </div>
        <div class="col-md-4">
          <span *ngIf="!editingProfile"  name="email" maxlength="255">{{profile?.email}}</span>
          <input *ngIf="editingProfile" class="form-control" type="text" id="email" formControlName="email">

          <div class="text-danger" *ngIf="profileForm.controls['email'].dirty && profileForm.controls['email'].invalid && editingProfile">
              <small class="text-danger" *ngIf="profileForm.controls['email'].hasError('email')">
                  Invalid email format.
              </small>
              <small class="text-danger" *ngIf="profileForm.controls['email'].hasError('required')">
                  Email is required.
              </small>
          </div>
        </div>
        <div class="col-md-4">
          <span>{{profile?.code}}</span>
          <i class="fa fa-pull-right fa-pencil fa-lg clickable " (click)="editingProfile = true"
             tooltip="Edit Profile Information" [hidden]="editingProfile"></i>
        </div>

      </div>
      <div class="row m-t-20">
        <div class="col-md-4 text-secondary">Name:</div>
        <div class="col-md-4 text-secondary">Phone:</div>
        <div class="col-md-4 text-secondary">Cell:</div>
      </div>

      <div class="row m-t-5 m-b-20">
        <div class="col-md-4">
          <span *ngIf="!editingProfile" id="contact">{{profile?.contact}}</span>
          <input *ngIf="editingProfile" class="form-control" type="text" formControlName="contact" id="contact" maxlength="100">
          <div class="text-danger" *ngIf="profileForm.controls['contact'].dirty && profileForm.controls['contact'].invalid && editingProfile">
              <small class="text-danger" *ngIf="profileForm.controls['contact'].hasError('required')">
                  Name is required.
              </small>
          </div>
        </div>
        <div class="col-md-4">
          <span  *ngIf="!editingProfile" id="phone">{{profile?.phone | mask: '(000) 000-0000'}}</span>
          <input *ngIf="editingProfile" class="form-control" type="text" mask="(000) 000-0000" formControlName="phone" id="phone" >
          <div class="text-danger"*ngIf="profileForm.controls['phone'].dirty &&  profileForm.controls['phone'].invalid && editingProfile">
              <small class="text-danger" *ngIf="profileForm.controls['phone'].hasError('pattern')">
                  Invalid phone number format.
              </small>
              <small class="text-danger" *ngIf="profileForm.controls['phone'].hasError('required')" >
                  Phone number is required.
              </small>
          </div>
        </div>

        <div class="col-md-4">
          <span *ngIf="!editingProfile" [hidden]="!profile?.cell" id="cell" >{{profile?.cell || 'N/A' | mask: '(000) 000-0000'}}</span>
          <input *ngIf="editingProfile" class="form-control"  type="text" id="cell" formControlName="cell" mask="(000) 000-0000">
          <div><small>If provided, this phone number will receive text notifications for new inquiries.</small></div>
          <div class="text-danger"*ngIf="profileForm.controls['cell'].dirty &&  profileForm.controls['cell'].invalid && editingProfile">
            <small class="text-danger" *ngIf="profileForm.controls['cell'].hasError('pattern')">
                Invalid phone number format.
            </small>
            <small class="text-danger" *ngIf="profileForm.controls['cell'].hasError('required')" >
                Phone number is required.
            </small>
          </div>
        </div>
      </div>
      <div class="row m-t-20" *ngIf="showWebAddress">
        <div class="col-md-4 text-secondary">Web Address:</div>
      </div>
      <div class="row m-t-5 m-b-20" *ngIf="showWebAddress">
          <div class="col">
            <span><a [routerLink]="'/consumer/'+ profile.code" target="_blank">{{webAddress}}</a></span>
          </div>
        </div>
        <mat-divider *ngIf="editingProfile"></mat-divider>
        <div *ngIf="editingProfile" class="row m-t-10">
          <div class="col-md-12 text-center">
              <div>
                  <button class="pl btn btn-primary m-r-5" type="submit" [disabled]="!profileForm.dirty && !profileForm.touched" (click)="updateProfile()">
                      Save
                  </button>
                  <button class="btn btn-neutral" type="button" (click)="cancelEditProfile()">
                      Cancel
                  </button>
              </div>
          </div>
      </div>
    </form>

    <div *ngIf="showChangePassword" >
      <mat-divider class="m-t-10 m-b-10"></mat-divider>
      <h3>Change Password</h3>
      <form [formGroup]="changePasswordForm" class="form-validation">
        <div class="row m-t-10">
          <div class="col-md-4 text-secondary">Current Password:</div>
          <div class="col-md-4 text-secondary">New Password:</div>
          <div class="col-md-4 text-secondary">Confirm New Password:</div>
        </div>
        <div class="row m-t-5">
          <div class="col-md-4">
            <input class="form-control" type="password" placeholder="Current Password" id="oldPassword" formControlName="oldPassword" />
            <div class="text-danger" *ngIf="changePasswordForm.controls['oldPassword'].dirty && changePasswordForm.controls['confirmPassword'].invalid">
               <small class="text-danger" *ngIf="changePasswordForm.controls['oldPassword'].hasError('required')">
                   Password is required.
               </small>
            </div>
        </div>
        <div class="col-md-4">
          <input class="form-control" type="password" placeholder="New Password" id="newPassword" formControlName="newPassword"/>
          <div class="text-danger" *ngIf="changePasswordForm.controls['newPassword'].dirty && changePasswordForm.controls['confirmPassword'].invalid">
            <small class="text-danger" *ngIf="changePasswordForm.controls['newPassword'].hasError('pattern')">
              Invalid password format. Passwords must be at least 8 characters in length, with at least one of
              each of the following: digit, lowercase letter, uppercase letter, and non-letter/non-digit.
            </small>
              <small class="text-danger" *ngIf="changePasswordForm.controls['newPassword'].hasError('required')">
                  Password is required.
              </small>
          </div>
        </div>
        <div class="col-md-4">
          <input class="form-control" type="password" placeholder="Confirm New Password" id="confirmPassword" formControlName="confirmPassword"/>
          <div class="text-danger"
               *ngIf="changePasswordForm.controls['confirmPassword'].dirty && changePasswordForm.controls['confirmPassword'].invalid">
              <!-- <small class="text-danger" *ngIf="changePasswordForm.controls['confirmPassword'].hasError('pattern')">
                  Passwords don't match.
              </small> -->
              <small class="text-danger" *ngIf="changePasswordForm.controls['confirmPassword'].hasError('required')">
                  Confirm password is required.
              </small>
          </div>
        </div>
          </div>
          <mat-divider class="m-t-20 m-b-10"></mat-divider>
          <div class="row m-t-15">
              <div class="col-md-12 text-center">
                  <div>
                      <button class="btn btn-primary m-r-10" type="button" (click)="changePassword()" [disabled]="changePasswordForm.invalid">
                          Change Password
                      </button>
                      <button class="btn btn-neutral" type="button" (click)="toggleChangePassword()">
                          Cancel
                      </button>
                  </div>
              </div>
          </div>


      </form>
  </div>
  </div>
</div>
