import {Injectable} from "@angular/core";
import { plainToClass } from "class-transformer";
import {saveAs} from "file-saver";
import { ZipLookUpInfo } from "../components/classes/Consumer";
import { lastValueFrom } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";

@Injectable()
export class ToolsService {

  constructor(private httpClient:HttpClient, private matSnackBar: MatSnackBar, private router: Router){}

  openBlob (file: any, target: string ="_blank") {
    const nav = (window.navigator as any);
    if (nav && nav.msSaveOrOpenBlob) {
      nav.msSaveOrOpenBlob(file);
    }
    else {
      const fileURL = URL.createObjectURL(file);
      const newWindow = window.open(fileURL, target);//,'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,fullscreen=yes');
      if (newWindow == null)
        throw new Error("Please disable your popup blocker.");
    }
  }

  public deepClone<T>(source : T) : T {
    return ToolsService.deepClone(source);
  }

  public static deepClone<T>(source : T) : T {
    return Array.isArray(source)
    ? source.map(item => this.deepClone(item))
    : source instanceof Date
    ? new Date(source.getTime())
    : source && typeof source === 'object'
          ? Object.getOwnPropertyNames(source).reduce((o, prop) => {
             Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop));
             o[prop] = this.deepClone(source[prop]);
             return o;
          }, Object.create(Object.getPrototypeOf(source)))
    : source as T;
  }

  public downloadCSV(originalFile, fileName){
    let file = new Blob([originalFile], { type: 'text/csv;charset=utf-8' });
    saveAs(file, fileName);
  };

  public downloadReportCSV(originalFile, fileName){
    let file = originalFile.body // new Blob([originalFile], { type: 'text/csv;charset=utf-8' });
    saveAs(file, fileName + ".csv");
  };

  public getFilenameFromURL(url){
    return url?url.split('/').pop():"";
  }

  async readFile(file: File) : Promise<string> {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event: any) => {
          if (typeof event.target.result === "string") {
            resolve(event.target.result.split('base64,')[1]);
          }
          else {
            const bArray = event.target.result;
            let binary = '';
            const bytes = new Uint8Array( bArray );
            const len = bytes.byteLength;
            for (let i = 0; i < len; i++) {
              binary += String.fromCharCode( bytes[ i ] );
            }
            resolve(window.btoa( binary ));
          }
        };
        reader.readAsDataURL(file);
    });
  }

  async zipLookUp(zipCode: string){
    try{
      var substrValue = zipCode.substring(0, 5);//Only pass in first five digits since web service only allows 5
      const data = await lastValueFrom(this.httpClient.get(`/api/ZipCodeLookup/${substrValue}`))
      const zipData = plainToClass(ZipLookUpInfo, data);
      return zipData;
    }
    catch{
      this.matSnackBar.open('Something went wrong when trying to lookup zip code');
      return null
    }
  }


  public hasRoute(route: string) {
    return this.router.url.includes(route);
  }

}
