import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConsumerColors, ConsumerProduct } from 'src/app/components/classes/Consumer';

@Component({
  selector: 'app-consumer-selected-product',
  templateUrl: './consumer-selected-product.component.html',
  styleUrls: ['./consumer-selected-product.component.scss']
})
export class ConsumerSelectedProductComponent implements OnInit {

  @Output() changedProduct: EventEmitter<any> = new EventEmitter();
  @Input() selectedProduct: ConsumerProduct;
  @Input() logo: string;
  @Input() isFinish: boolean;
  @Input() branding: ConsumerColors;
  @Input() testProduct: string;

  constructor() { }

  ngOnInit() {
  }

  getDesctiptionIcon(item){
    if(item == 'Mortgage'){
      return "fa-solid fa-house";
    }
    else if(item == 'Auto'){
      return "fa-solid fa-car";
    }
    else if(item == 'Credit'){
      return "fa-solid fa-credit-card";
    }
    else if(item == 'Business Finance'){
      return "fa-solid fa-dollar-sign";
    }
    else{
      return "";
    }
  }

  changeProduct(){
    this.changedProduct.emit(0);
  }

}
