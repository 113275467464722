import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Client } from 'src/app/components/classes/Client';
import { LoanType } from 'src/app/components/classes/LoanType';
import { User } from 'src/app/components/classes/User';
import { matchPasswordValidator } from 'src/app/components/constants/confirm-password.validator';
import { ProgressAlertComponent } from 'src/app/components/dialogs/progress-alert/progress-alert.component';
import { ClientService } from 'src/app/services/client.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-user-setup-new',
  templateUrl: './user-setup-new.component.html',
  styleUrls: ['./user-setup-new.component.scss']
})
export class UserSetupNewComponent implements OnInit {

  newUsers: User;
  confirmPassword: string;
  newUserForm: FormGroup;
  userKind: string = localStorage.getItem("userKind") || "";
  @Output() cancelNewUser: EventEmitter<any> = new EventEmitter;
  @Output() addedNewUser: EventEmitter<any> = new EventEmitter;
  @Input() loanType: Array<LoanType>;
  @Input() clients: Array<Client>;
  @Input() parentId: string;
  selectedLoantTypes : Array<LoanType>;


  constructor(private clientService:ClientService,private usersService:UsersService, private matSnackBar: MatSnackBar, private dialog:MatDialog ) { }

  ngOnInit() {
    this.newUsers = new User();
    this.newUserForm = new FormGroup({
      email: new FormControl(this.newUsers.email, {nonNullable: true, validators: [Validators.required, Validators.email]}),
      contact: new FormControl(this.newUsers.contact, {nonNullable: true, validators: [Validators.required]}, ),
      phone: new FormControl(this.newUsers.phone, {nonNullable: true, validators: [Validators.required, Validators.pattern("^[0-9]*$")]}),
      cell: new FormControl(this.newUsers.cell, {nonNullable: true, validators: [Validators.pattern("^[0-9]*$")]} ),
      userName: new FormControl(this.newUsers.userName, Validators.required),
      password: new FormControl(this.newUsers.password, [
        Validators.required,
        Validators.pattern("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&]).{8,50}")
      ]),
      matchPassword: new FormControl(this.confirmPassword, [Validators.required]),
      kind:  new FormControl(this.newUsers.kind, {nonNullable: true, validators: [Validators.required]}, ),
    },
    {validators: matchPasswordValidator})
    if(this.userKind == "ProviderUser"){
      this.newUserForm.addControl('parentId', new FormControl(this.newUsers.parentId ,{nonNullable: false, validators: [Validators.required]}))
    }
    else{
      this.newUserForm.addControl('selectedLoanTypes', new FormControl(this.selectedLoantTypes, {nonNullable: false}))
    }
  }

  async addNewUser(){
    const progressDialog = this.dialog.open(ProgressAlertComponent, {
      data: {
        title: 'Adding New User...'
      }
    });
    try{
      this.newUsers = this.newUserForm.value
      this.newUsers.products = [];
      if(this.userKind != "ProviderUser"){
        this.newUsers.parentId = this.parentId;
        this.selectedLoantTypes = this.newUserForm.value.selectedLoanTypes
        if(this.selectedLoantTypes){
          await this.setProducts();
        }
      }
      await this.usersService.addNewUser(this.newUsers);
      progressDialog.close();
      this.addedNewUser.emit();
    }
    catch (e){
      progressDialog.close();
      if(e.error.includes("already exists")){
        this.matSnackBar.open( e.error + '. Please choose a different username.');
      }
      else{
       this.matSnackBar.open('Something went wrong.');
      }
    }
  }


  getLoanTypeName(item: LoanType){
  }

  setProducts(){
    this.selectedLoantTypes.forEach(x =>{
      this.newUsers.products.push({Id:x.Id , categoryId:  x.categoryId})
    });
  }

  closeNewUser(){
    this.cancelNewUser.emit()
  }

}
