
<div class="providerSetup">
  <form *ngIf="provider" [formGroup]="providerForm">
    <div class="row itemCenterVertical">
      <div class="col-4">
        <span *ngIf="!editingProvider">{{provider?.companyName}}</span>
        <input class="form-control" type="text" *ngIf="editingProvider" formControlName="companyName">
      </div>
      <div class="col-4">Manager Code: {{provider?.code}}</div>
      <div class="col d-flex justify-content-end">
        <i class="fa fa-pull-right fa-pencil fa-lg clickable " style="line-height: 30px;" (click)="editingProvider = true; element = provider" tooltip="Edit Profile Information" [hidden]="editingProvider"></i>
        <div [hidden]="!editingProvider" >
          <button mat-flat-button class="m-r-10" color="secondary" (click)="cancelChanges()">CANCEL</button>
          <button mat-flat-button class="pl btn-primary" [disabled]="providerForm?.invalid" (click)="saveProvider()">SAVE</button>
        </div>
        <span class="fa-stack pull-right text-sm m-l-10" *ngIf="userKind == 'RootUser'">
          <i class="fa fa-circle-thin fa-stack-2x"></i>
          <i class="fa fa-stack-1x " (click)="element = element === provider ? null : provider" [ngClass]="{'fa-caret-down':element != provider, 'fa-caret-up':element == provider}"></i>
        </span>
      </div>
    </div>

    <div *ngIf="element">
      <mat-divider class="m-t-20 m-b-10"></mat-divider>
      <div class="row m-b-5">
        <div class="col">Address:</div>
        <div class="col">City:</div>
        <div class="col">State:</div>
        <div class="col">Zip:</div>
      </div>
      <div class="row m-b-10">
        <div class="col"><span *ngIf="!editingProvider">{{provider?.address}}</span> <input class="form-control" type="text" *ngIf="editingProvider" formControlName="address"></div>
        <div class="col"><span *ngIf="!editingProvider">{{provider?.city}}</span> <input class="form-control" type="text" *ngIf="editingProvider" formControlName="city"></div>
        <div class="col">
          <span *ngIf="!editingProvider">{{provider?.state}}</span>
          <mat-select *ngIf="editingProvider" class="form-control" formControlName="state" placeholder="State">
            <mat-option [value]="item.abbreviation" *ngFor="let item of statesList">{{item.abbreviation}}</mat-option>
          </mat-select>
          <!-- <input class="form-control" type="text" *ngIf="editingProvider" formControlName="state"> -->
        </div>
        <div class="col"><span *ngIf="!editingProvider">{{provider?.zip}}</span> <input class="form-control" type="text" *ngIf="editingProvider" formControlName="zip"></div>
      </div>
      <div class="row m-b-5">
        <div class="col">Phone:</div>
        <div class="col">Email:</div>
        <div class="col">Contact:</div>
        <div class="col">Status:</div>
      </div>
      <div class="row">
        <div class="col"><span *ngIf="!editingProvider">{{provider?.phone | mask: '(000) 000-0000'}}</span> <input class="form-control" type="text" *ngIf="editingProvider" mask="(000) 000-0000" formControlName="phone"></div>
        <div class="col"><span *ngIf="!editingProvider">{{provider?.email}}</span> <input class="form-control" type="text" *ngIf="editingProvider" formControlName="email"></div>
        <div class="col"><span *ngIf="!editingProvider">{{provider?.contact}}</span> <input class="form-control" type="text" *ngIf="editingProvider" formControlName="contact"></div>
        <div class="col"><span>{{provider?.status | clientStatus}}</span></div>
      </div>
      <div class="row m-t-20" style="font-size: 10pt;">
        <div class="col-3">Created {{provider?.dateCreated | date:'MM/dd/YYYY h:mm:ss a'}} </div>
        <div class="col-4">Modified {{provider?.dateModified | date:'MM/dd/YYYY h:mm:ss a'}}</div>
      </div>
    </div>
  </form>
  <div *ngIf="element">
    <div class="m-t-20 userSection">
        <div class="row itemCenterVertical m-t-5">
          <div class="col">
            <h4>
              Users
              <span class="badge pl bg-primary" style="font-size: 12px;"> {{providerUsers?.length}}</span>
            </h4>
          </div>
          <div class="col d-flex justify-content-end">
            <span class="fa-stack fa-pull-right text-lg clickable" (click)="toggleNewProviderVisibility()">
              <i class="fa fa-circle fa-stack-2x pl" [ngClass]="{'text-danger':addNewUser, 'text-primary':!addNewUser}"></i>
              <i class="fa fa-stack-1x fa-inverse" [ngClass]="{'fa-plus':!addNewUser, 'fa-minus':addNewUser}"></i>
            </span>
          </div>
        </div>
        <mat-divider class="m-t-10 m-b-10"></mat-divider>
        <div *ngIf="addNewUser">
          <app-provider-setup-new-user [provider]="provider" (addedNewUser)="newUserAdded($event)" (cancelNewUser)="addNewUser = false"></app-provider-setup-new-user>
        </div>
        <div *ngFor="let item of providerUsers">
          <app-user-setup-item [isProviderSetup]="true"  [user]="item"></app-user-setup-item>
        </div>
      </div>
  </div>
</div>

