<div *ngIf="product" class="productItem m-t-10">
  <form #productForm="ngForm">
    <div class="row no-gutters productUserCenter">
      <div class="col-4">
        <span *ngIf="!editingProduct"> {{product.description}}</span><input class="form-control"  *ngIf="editingProduct" required [(ngModel)]="product.description" name="description">
      </div>
      <div class="col-3">
        <span *ngIf="!editingProduct">{{product.categoryName}}</span>
        <mat-select class="form-control"  *ngIf="editingProduct" required [(ngModel)]="product.categoryName" name="categoryName">
          <mat-option *ngFor=" let item of loanCategory" [value]="item.description">{{item.description}}</mat-option>
        </mat-select>
      </div>

      <div class="col-2">
        <span *ngIf="!editingProduct">{{product.status | clientStatus}}</span>
        <mat-select class="form-control"  *ngIf="editingProduct" required [(ngModel)]="product.status" name="status">
          <mat-option [value]="1">Active</mat-option>
          <mat-option [value]="3">Inactive</mat-option>
          <mat-option [value]="2">Deleted</mat-option>
        </mat-select>
      </div>
      <div class="col d-flex justify-content-end">
        <i class="fa fa-pull-right fa-pencil fa-lg clickable " (click)="editingProduct = true; element = product"
        tooltip="Edit" [hidden]="editingProduct" style="line-height: 30px;"></i>
        <div [hidden]="!editingProduct">
          <button mat-flat-button class="m-r-10" color="secondary" (click)="cancelChanges()">CANCEL</button>
          <button mat-flat-button class="pl btn-primary" [disabled]="productForm.invalid" (click)="saveProduct()">SAVE</button>
        </div>
        <span class="fa-stack pull-right text-sm m-l-10">
          <i class="fa fa-circle-thin fa-stack-2x"></i>
          <i class="fa fa-stack-1x fa-caret-down" (click)="element = element === product ? null : product;  editingProduct = editingProduct && element === product" [ngClass]="{'indicator_collapsed':element != product, 'indicator_expanded':element == product}"></i>
        </span>
      </div>
    </div>
    <div class="p-a-10" *ngIf="element">
      <mat-divider class="m-t-10 m-b-10"></mat-divider>
      <div class="qualifications">
        <div class="row qualificationsTitle">
          <div class="col">Scores</div>
          <div class="col"></div>
          <div class="col">Loan Range</div>
          <div class="col">APR</div>
          <div class="col">Terms</div>
          <div class="col">Monthly Payment</div>
          <div class="col-1" *ngIf="editingProduct">
            <span class="fa-stack fa-pull-right text-sm clickable" style="font-size: 9pt" matTooltip="Add Qualification" (click)="addQualification()">
              <i class="fa fa-circle fa-stack-2x text-success"></i>
              <i class="fa fa-stack-1x fa-inverse fa-plus"></i>
            </span>
          </div>
        </div>
        <div class="row m-b-10 itemCenterVertical" *ngFor="let item of product.qualifications; let i = index">
           <div class="col input-group">
              <span [ngClass]="editingProduct ? 'input-group-text' : ''">From</span>
              <span class="m-l-10" *ngIf="!editingProduct">{{item.scoreStart}}</span><input type="number" class="form-control" *ngIf="editingProduct" required [(ngModel)]="item.scoreStart" [name]="'scoreStart-' + i">
            </div>
          <div class="col input-group">
             <span [ngClass]="editingProduct ? 'input-group-text' : ''">To</span>
             <span class="m-l-10" *ngIf="!editingProduct">{{item.scoreEnd}}</span><input type="number" class="form-control" *ngIf="editingProduct" required [(ngModel)]="item.scoreEnd" [name]="'scoreEnd-' + i">
          </div>
          <div class="col input-group">
            <span [ngClass]="editingProduct ? 'input-group-text' : ''">$</span>
            <span *ngIf="!editingProduct">{{item.prequalifiedAmount}}</span><input type="number" class="form-control" *ngIf="editingProduct" required [(ngModel)]="item.prequalifiedAmount" [name]="'prequalifiedAmount-' + i"></div>
          <div class="col input-group">
            <span *ngIf="!editingProduct">{{item.apr}}</span><input type="number" class="form-control" *ngIf="editingProduct" required [(ngModel)]="item.apr" [name]="'apr-' + i"><span [ngClass]="editingProduct ? 'input-group-text' : ''">%</span>
          </div>

          <div class="col">
              <span *ngIf="!editingProduct">{{item.terms}}</span><input type="number" class="form-control" *ngIf="editingProduct" required [(ngModel)]="item.terms" [name]="'terms-' + i">
            </div>
          <div class="col input-group">
            <span [ngClass]="editingProduct ? 'input-group-text' : ''">$</span>
            <span *ngIf="!editingProduct">{{item.monthlyPayment}}</span><input type="number" class="form-control" *ngIf="editingProduct"  required [(ngModel)]="item.monthlyPayment" [name]="'monthlyPayment-' + i">
          </div>
          <div class="col-1" *ngIf="editingProduct">
            <span class="fa-stack fa-pull-right text-sm clickable"  style="font-size: 9pt" matTooltip="Remove Qualification" (click)="removeQualification(item)">
              <i class="fa fa-circle fa-stack-2x text-danger"></i>
              <i class="fa fa-stack-1x fa-inverse fa-minus"></i>
            </span>
          </div>
        </div>
      </div>
      <mat-divider class="m-t-10 m-b-10"></mat-divider>
      <div class="row">
        <div class="col">Decline Reason</div>
      </div>
      <div class="row m-t-10 m-b-10">
        <div class="col">
          <label class="i-checks i-checks-sm">
            <input type="checkbox" id="cbRememberMe" [disabled]="!editingProduct" [(ngModel)]="product.declineReasons.bankruptcy" name="bankruptcy" >
            <i></i>
            Had bankruptcies in the last 4 years
          </label>
        </div>
      </div>
      <div class="row m-t-10 m-b-10">
        <div class="col">
          <label class="i-checks i-checks-sm">
            <input type="checkbox" id="cbRememberMe" [disabled]="!editingProduct" [(ngModel)]="product.declineReasons.foreclosure" name="foreclosure" >
            <i></i>
            Had foreclosures in the last 7 years
          </label>
        </div>
      </div>
      <div class="row m-t-10 m-b-10">
        <div class="col">
          <label class="i-checks i-checks-sm">
            <input type="checkbox" id="cbRememberMe" [disabled]="!editingProduct" [(ngModel)]="product.declineReasons.shortSale" name="shortSale" >
            <i></i>
            Had short sales in the last 4 years
          </label>
        </div>
      </div>
      <div class="row m-t-10 m-b-10">
        <div class="col">
          <label class="i-checks i-checks-sm">
            <input type="checkbox" id="cbRememberMe" [disabled]="!editingProduct" [(ngModel)]="product.declineReasons.pastDueMortgagePayment" name="pastDueMortgagePayment" >
            <i></i>
            Had mortgage payments 60 days past due in the last 12 months
          </label>
        </div>
      </div>
      <div class="productUsers p-a-10">
        <div class="row m-t-10 m-b-10">
          <div>
            <span class="fa-stack text-md  clickable" (click)="toggleAddUsers()">
              <i class="fa fa-circle fa-stack-2x pl" [ngClass]="{'text-danger':addUsers, 'text-primary':!addUsers}"></i>
              <i class="fa fa-stack-1x fa-inverse" [ngClass]="{'fa-plus':!addUsers, 'fa-minus':addUsers}"></i>
            </span>
            <span class="m-t-10">Users</span>
          </div>
        </div>
        <div *ngIf="addUsers">
          <div *ngFor="let item of users">
            <div *ngIf="filterUser(item)" class="productItem p-a-10 m-b-10">
              <div class="row productUserCenter">
              <div class="col"><i class="fa fa-user m-t-xs m-r-5"></i>{{item.contact}}</div>
                <div class="col">Web Address: <a [routerLink]="'/consumer/'+ item.code" target="_blank">{{setWebAddress(item.code)}}</a></div>
                <div class="col">
                  <span class="fa-stack fa-pull-right text-md clickable"  matTooltip="Add User"  (click)="addUserToProduct(product.Id ,item)">
                    <i class="fa fa-circle fa-stack-2x pl text-primary"></i>
                    <i class="fa fa-stack-1x fa-inverse fa-plus"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <mat-divider class="m-t-10 m-b-10"></mat-divider>

        <div class="productItem p-a-10 m-b-10" *ngFor="let item of product.userInfo">
          <div class="row productUserCenter">
            <div class="col"><i class="fa fa-user m-t-xs m-r-5"></i>{{item.contact}}</div>
            <div class="col">Web Address: <a [routerLink]="'/consumer/'+ item.code" target="_blank">{{setWebAddress(item.code)}}</a> </div>
            <div class="col">
              <span class="fa-stack fa-pull-right text-md clickable"  matTooltip="Remove User" (click)="removeUserFromProduct(product.Id, item)">
                <i class="fa fa-circle fa-stack-2x text-danger"></i>
                <i class="fa fa-stack-1x fa-inverse fa-minus"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
