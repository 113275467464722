import { Component, OnInit, ViewChild } from '@angular/core';
import { Profile } from '../components/classes/Profile';
import { Form, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ProgressAlertComponent } from '../components/dialogs/progress-alert/progress-alert.component';
import { ProfileService } from '../services/profile.service';
import { WebAddressService } from '../services/webaddress.service';
import { UserPassword } from '../components/classes/UserPassword';
import { User } from '../components/classes/User';
import { confirmPasswordValidator } from '../components/constants/confirm-password.validator';

@Component({
  selector: 'app-userprofile-settings',
  templateUrl: './userprofile-settings.component.html',
  styleUrls: ['./userprofile-settings.component.scss']
})
export class UserprofileSettingsComponent implements OnInit {

  profile: Profile;
  profileForm: FormGroup;
  changePasswordForm: FormGroup;
  passwordUser: UserPassword;
  initialProfileValues;
  editingProfile: boolean;
  userId = localStorage.getItem("RqUserId") || null;
  showChangePassword: boolean;
  webBase: string;
  webAddress: string;
  userKind = localStorage.getItem("userKind") || null;
  showWebAddress: boolean = true

  constructor(private profileService:ProfileService, private matSnackBar: MatSnackBar, private dialog:MatDialog, private webaddress: WebAddressService) { }

  async ngOnInit() {
    this.profile = new Profile();
    await this.getProfile();
    this.profileForm = new FormGroup({
      email: new FormControl(this.profile.email, {nonNullable: true, validators: [Validators.required, Validators.email]}),
      contact: new FormControl(this.profile.contact, {nonNullable: true, validators: [Validators.required]}, ),
      phone: new FormControl(this.profile.phone, {nonNullable: true, validators: [Validators.required, Validators.pattern("^[0-9]*$")]}),
      cell: new FormControl(this.profile.cell, {nonNullable: true, validators: [Validators.pattern("^[0-9]*$")]} )
    });

    this.passwordUser = new UserPassword();

    this.changePasswordForm = new FormGroup({
      oldPassword: new FormControl(this.passwordUser.oldPassword, Validators.required),
      newPassword: new FormControl(this.passwordUser.newPassword, [
        Validators.required,
        Validators.pattern(`^(?!${this.passwordUser.oldPassword}$).*$`),
        Validators.pattern("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&]).{8,50}")
      ]),
      confirmPassword: new FormControl(this.passwordUser.confirmPassword, [Validators.required])
    },
    {validators: confirmPasswordValidator})

    this.setWebAddress();

    if(this.userKind == "RootUser" || this.userKind == "ProviderUser"){
      this.showWebAddress = false
    }
  }



  setWebAddress(){
    this.webBase = this.webaddress.generateWebAddress();
    this.webAddress = this.webBase + this.profile.code
  }

  async getProfile(){
    const progressDialog = this.dialog.open(ProgressAlertComponent, {
      data: {
        title: 'Loading...'
      }
    });
    try{
      this.profile = await this.profileService.getProfile(this.userId);
      this.initialProfileValues = this.profile;
      progressDialog.close();
    }
    catch(e){
      progressDialog.close();
      this.matSnackBar.open('Something went wrong. Please refresh.');
    }
  }


  cancelEditProfile(){
    this.profileForm.reset();
    this.profile = this.initialProfileValues
    this.editingProfile = !this.editingProfile;
  }

  toggleChangePassword(){
    this.changePasswordForm.reset();
    this.showChangePassword = !this.showChangePassword;
  }

  async changePassword(){
    const progressDialog = this.dialog.open(ProgressAlertComponent, {
      data: {
        title: 'Loading...'
      }
    });
    try{
      this.passwordUser = this.changePasswordForm.value;
      await this.profileService.changePassword(this.passwordUser)
      this.toggleChangePassword();
      progressDialog.close();
    }
    catch(e){
      progressDialog.close();
      this.matSnackBar.open('Something went wrong. Please refresh.');
    }
  }

  setNewProfileValues(){
    this.profile.email = this.profileForm.value.email;
    this.profile.contact = this.profileForm.value.contact;
    this.profile.phone = this.profileForm.value.phone;
    this.profile.cell = this.profileForm.value.cell;
  }

  async updateProfile(){
    const progressDialog = this.dialog.open(ProgressAlertComponent, {
      data: {
        title: 'Loading...'
      }
    });
    try{
      this.setNewProfileValues();
      this.profile = await this.profileService.putProfile(this.profile);
      this.editingProfile = false;
      progressDialog.close();
    }
    catch(e){
      progressDialog.close();
      if(e.error.includes("already exists")){
        this.matSnackBar.open( e.error + '. Please choose a different username.');
      }
      else{
        this.matSnackBar.open('Something went wrong.');
      }
    }
  }

}
