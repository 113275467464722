export class Logo {
  delete_type: string;
  delete_url: string;
  dir:  string;
  error:  string;
  file_key: string;
  height: string;
  name: string;
  showOnConsumer: boolean;
  size: number;
  thumbnail_url: string;
  type: string;
  url: string;
  width: number;
}
