import { Component, Input, OnInit } from '@angular/core';
import { Consumer, ConsumerColors, ConsumerProduct } from 'src/app/components/classes/Consumer';
import { RequestResponse } from 'src/app/components/classes/Request';
import { ConsumerService } from 'src/app/services/consumer.service';

@Component({
  selector: 'app-consumer-finish',
  templateUrl: './consumer-finish.component.html',
  styleUrls: ['./consumer-finish.component.scss']
})
export class ConsumerFinishComponent implements OnInit {

  @Input() requestResponse: RequestResponse;
  @Input() consumerInfo: Consumer;
  @Input() branding: ConsumerColors;
  @Input() companyLogoUrl: string;
  @Input() selectedProduct: ConsumerProduct;
  @Input() testProduct: string;
  @Input() logo: string;
  @Input() isFinish: boolean;
  @Input() preview: boolean;
  setContact: boolean = false;

  constructor(private consumerService: ConsumerService) { }

  ngOnInit(){
    if(this.preview){
      this.setPreviewData();
    }
  }

  setPreviewData(){
    this.requestResponse = new RequestResponse();
    this.requestResponse.header = "Preview Only"
    this.requestResponse.message = "You are prequalified for a test loan. Click here to apply/find out exactly how much/complete the pre-approval process."
    this.consumerInfo.loPhone = "(000) 000-000"
    this.consumerInfo.loEmail = "LoanOfficerTest@test.com"
    this.consumerInfo.companyName = "SharperLending Test"
    this.consumerInfo.address = "4315 S Scott ST"
    this.consumerInfo.city = "Spokane"
    this.consumerInfo.state = "WA"
    this.consumerInfo.zip = "99203"
  }

  printScreen(){
    window.print();
  }

  async contactMe(){
    if(this.preview){
      this.setContact = true;
    }
    else{
      try{
        if(this.requestResponse.requestId){
           const data = await this.consumerService.postContact(this.requestResponse.requestId);
          this.setContact = true;
        }
      }
      catch(e){
        this.setContact = false;
      }
    }
  }
}
