<div class="clientSetup m-t-10">
  <form [formGroup]="clientForm">
    <div class="row no-gutters">
      <div class="col">
        <input type="text" class="form-control" placeholder="Company Name" formControlName="companyName" maxlength="50">
      </div>
      <div class="col">
      </div>
      <div class="col d-flex justify-content-end">
        <button mat-flat-button class="m-r-10" color="secondary" (click)="cancelAddingClient()">CANCEL</button>
        <button mat-flat-button color="primary" [disabled]="clientForm.invalid" (click)="addNewClient()">SAVE</button>
      </div>
    </div>
    <div>
      <mat-divider class="m-t-10 m-b-10"></mat-divider>
      <div >
        <div class="row m-t-10">
          <div class="col"><i class="fa-solid fa-circle-info brandingPreview" matTooltip="Transparent png recommended" [matTooltipPosition]="'above'"></i> Logo:</div>
        </div>

        <div class="m-t-10" >
          <div class="dropzone">
            <img class="logo m-r-10" [src]="logoURL"  *ngIf="client.logo?.url">
            <button for="fileDropRef" class="fileInput m-r-10"  *ngIf="!client.logo?.url">Drag File Attachment Here</button>
            <button for="fileDropRef" class="outlineButton">Upload File</button>
            <input type="file" ng2FileSelect [uploader]="uploader" (onFileSelected)="handleFiles($event)"/>
          </div>
        </div>
        <div class="row" *ngIf="client.logo?.name">
          <div class="col">
            <label class="i-checks i-checks-sm">
              <input type="checkbox" id="cbRememberMe" [(ngModel)]="client.logo.showOnConsumer" [ngModelOptions]="{standalone: true}">
              <i></i>
              Show Logo on Consumer App
            </label><i class="fa-solid fa-circle-info brandingPreview" [matTooltip]="showLogoMessage" [matTooltipPosition]="'above'"></i>
          </div>
        </div>
      </div>
      <div class="row m-t-10">
        <div class="col-4">Address:</div>
        <div class="col">City:</div>
        <div class="col">State:</div>
        <div class="col">Zip:</div>
      </div>
      <div class="row m-t-5">
        <div class="col-4"><input class="form-control" placeholder="Address"  formControlName="address" maxlength="50"></div>
        <div class="col"><input class="form-control" placeholder="City"  formControlName="city" maxlength="50"></div>
        <div class="col">
          <mat-select class="form-control" formControlName="state" placeholder="State">
            <mat-option [value]="item.abbreviation" *ngFor="let item of statesList">{{item.abbreviation}}</mat-option>
          </mat-select>
        </div>
        <div class="col"><input class="form-control" placeholder="Zip"  formControlName="zip" (change)="zipcodeLookUp(clientForm.value.zip)" maxlength="9"></div>
      </div>
      <div class="row m-t-10">
        <div class="col-3">Phone:</div>
        <div class="col-3">Email:</div>
      </div>
      <div class="row m-t-5">
        <div class="col-3"><input class="form-control" placeholder="Phone" mask="(000) 000-0000" formControlName="phone"></div>
        <div class="col-3"><input class="form-control" placeholder="Email"  formControlName="email" maxlength="50"></div>
      </div>
      <mat-divider class="m-t-10 m-b-10"></mat-divider>
      <div class="row m-t-10">
        <div class="col"><i class="fa-solid fa-circle-info brandingPreview" matTooltip="Header and Footer Background Colors" [matTooltipPosition]="'above'"></i> Primary Color:</div>
        <div class="col"><i class="fa-solid fa-circle-info brandingPreview" matTooltip="Selected Tab Background Color, Header Text Color, Button Background Color" [matTooltipPosition]="'above'"></i> Secondary Color:</div>
        <div class="col"><i class="fa-solid fa-circle-info brandingPreview" matTooltip="Unselected Tab Background Color and Category Heading Text Color" [matTooltipPosition]="'above'"></i> Tertiary Color:</div>
        <div class="col d-flex justify-content-end"><button mat-flat-button class="pl btn-primary" color="primary" (click)="previewBranding()"><i class="fa-solid fa-magnifying-glass"></i> Preview Branding</button></div>
      </div>
      <div class="row m-t-5">
        <div class="col">
          <div class="d-flex">
            <ngx-colors ngx-colors-trigger formControlName="headerBackgroundColor" [colorPickerControls]="'no-alpha'"></ngx-colors>
            <span class="m-t-5">{{clientForm.value.headerBackgroundColor}}</span>
          </div>
        </div>
        <div class="col">
          <div class="d-flex">
            <ngx-colors ngx-colors-trigger formControlName="selectedTabBackgroundColor" [colorPickerControls]="'no-alpha'"></ngx-colors>
            <span class="m-t-5">{{clientForm.value.selectedTabBackgroundColor}}</span>
          </div>
        </div>
        <div class="col">
          <div class="d-flex">
            <ngx-colors ngx-colors-trigger formControlName="unselectedTabBackgroundColor" [colorPickerControls]="'no-alpha'"></ngx-colors>
            <span class="m-t-5">{{clientForm.value.unselectedTabBackgroundColor}}</span>
          </div>
        </div>
        <div class="col"></div>
      </div>
      <div>
        <mat-divider class="m-t-10 m-b-10"></mat-divider>
        <div class="row">
          <div class="col">
            XpertOnline
          </div>
        </div>
        <div class="row m-t-10">
          <div class="col"><input class="form-control" placeholder="Provider ID"  formControlName="providerId" maxlength="50"></div>
          <div class="col"><input class="form-control" placeholder="Company ID" formControlName="companyId"></div>
          <div class="col"><input class="form-control" placeholder="User ID" formControlName="userId"></div>
          <div class="col"><input class="form-control" type="password" placeholder="Password" formControlName="xolpassword"></div>
        </div>
      </div>
      <div>
        <mat-divider class="m-t-10 m-b-10"></mat-divider>
        <div class="row">
          <div class="col">
            Products
          </div>
        </div>
        <div class="row m-t-10">
          <div class="col">
            <label class="i-checks i-checks-sm">
              <input type="checkbox" id="cbRememberMe" formControlName="tuEnabled">
              <i></i>
              TU
            </label>
          </div>
          <div class="col">
            <label class="i-checks i-checks-sm">
              <input type="checkbox" id="cbRememberMe" formControlName="xpnEnabled">
              <i></i>
              XPN
            </label>
          </div>
          <div class="col">
            <label class="i-checks i-checks-sm">
              <input type="checkbox" id="cbRememberMe" formControlName="efxEnabled">
              <i></i>
              EFX
            </label>
          </div>
        </div>
      </div>
      <mat-divider class="m-t-10 m-b-10"></mat-divider>
      <div class="row m-t-20" >
        <div class="col"><i class="fa fa-user m-t-xs m-r-xs"></i> Admin User</div>
      </div>
      <div class="adminUser p-a-10">
        <div class="row m-t-10">
          <div class="col">Name:</div>
          <div class="col">Email:</div>
          <div class="col">Phone:</div>
          <div class="col"><i class="fa-solid fa-circle-info brandingPreview" matTooltip="If provided, this phone number will receive text notifications for new inquiries." [matTooltipPosition]="'above'"></i> Cell:</div>
        </div>
        <div class="row">
          <div class="col"><input class="form-control" placeholder="Name"  formControlName="userContact"></div>
          <div class="col"><input class="form-control" placeholder="Email"  formControlName="userEmail"></div>
          <div class="col"><input class="form-control" placeholder="(xxx) xxx-xxxx" mask="(000) 000-0000"  formControlName="userPhone"></div>
          <div class="col"><input class="form-control" placeholder="(xxx) xxx-xxxx" mask="(000) 000-0000"  formControlName="userCell"></div>
        </div>
        <div class="row m-t-10">
          <div class="col">Username</div>
          <div class="col">Password:</div>
          <div class="col">Confirm Password:</div>
        </div>
        <div class="row">
          <div class="col"><input class="form-control" placeholder="Username"   formControlName="userName"></div>
          <div class="col">
            <input class="form-control" type="password" placeholder="Password"  formControlName="password">
            <div class="text-danger" *ngIf="clientForm.controls['password'].dirty && clientForm.controls['password'].invalid">
              <small class="text-danger" *ngIf="clientForm.controls['password'].hasError('pattern')">
                Invalid password format. Passwords must be at least 8 characters in length, with at least one of
                each of the following: digit, lowercase letter, uppercase letter, and non-letter/non-digit.
              </small>
              <small class="text-danger" *ngIf="clientForm.controls['password'].hasError('required')">
                  Password is required.
              </small>
            </div>
          </div>
          <div class="col">
            <input class="form-control" type="password" placeholder="Confirm Password"  formControlName="matchPassword">
            <small class="text-danger" *ngIf="clientForm.controls['matchPassword'].dirty && clientForm.errors?.['PasswordNoMatch']">
              Passwords don't match.
            </small>
            <div class="text-danger" *ngIf="clientForm.controls['matchPassword'].dirty && clientForm.controls['matchPassword'].invalid">
              <small class="text-danger" *ngIf="clientForm.controls['matchPassword'].hasError('required')">
                Confirm password is required.
              </small>
            </div>
          </div>
        </div>
      </div>

    </div>
  </form>
</div>

