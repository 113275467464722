<div class="userSection">
  <form [formGroup]="newProviderUserForm">
    <div class="row m-t-20 m-b-20">
      <div class="col">
        <label for="contact" class="text-secondary m-r-5">Name:<span style="color: red;">*</span></label>
        <input  class="form-control" type="text" formControlName="contact" id="contact" maxlength="100" placeholder="Name">
        <div class="text-danger" *ngIf="newProviderUserForm.controls['contact'].dirty && newProviderUserForm.controls['contact'].invalid">
            <small class="text-danger" *ngIf="newProviderUserForm.controls['contact'].hasError('required')">
                Name is required.
            </small>
        </div>
      </div>
      <div class="col">
        <label for="email" class="text-secondary m-r-5">Email:<span style="color: red;">*</span></label>
        <input class="form-control" type="text" id="email" [required]="true" formControlName="email" placeholder="Email">
        <div class="text-danger" *ngIf="newProviderUserForm.controls['email'].dirty && newProviderUserForm.controls['email'].invalid">
            <small class="text-danger" *ngIf="newProviderUserForm.controls['email'].hasError('email')">
                Invalid email format.
            </small>
            <small class="text-danger" *ngIf="newProviderUserForm.controls['email'].hasError('required')">
                Email is required.
            </small>
        </div>
      </div>
      <div class="col">
        <label for="phone" class="text-secondary m-r-5">Phone:<span style="color: red;">*</span></label>
        <input class="form-control" type="text" placeholder="(xxx) xxx-xxxx" mask="(000) 000-0000" formControlName="phone" id="phone" >
        <div class="text-danger"*ngIf="newProviderUserForm.controls['phone'].dirty &&  newProviderUserForm.controls['phone'].invalid ">
            <small class="text-danger" *ngIf="newProviderUserForm.controls['phone'].hasError('pattern')">
                Invalid phone number format.
            </small>
            <small class="text-danger" *ngIf="newProviderUserForm.controls['phone'].hasError('required')" >
                Phone number is required.
            </small>
        </div>
      </div>

        <div class="col">
          <label for="cell" class="text-secondary m-r-5">Cell:</label>
          <input class="form-control"  type="text" id="cell" formControlName="cell" mask="(000) 000-0000" placeholder="(xxx) xxx-xxxx">
          <div><small>If provided, this phone number will receive text notifications for new inquiries.</small></div>
          <div class="text-danger"*ngIf="newProviderUserForm.controls['cell'].dirty &&  newProviderUserForm.controls['cell'].invalid">
            <small class="text-danger" *ngIf="newProviderUserForm.controls['cell'].hasError('pattern')">
                Invalid phone number format.
            </small>
            <small class="text-danger" *ngIf="newProviderUserForm.controls['cell'].hasError('required')" >
                Phone number is required.
            </small>
          </div>
        </div>
    </div>
    <div class="row m-t-20">
      <div class="col">Username: <span style="color: red;">*</span></div>
      <div class="col">Password: <span style="color: red;">*</span></div>
      <div class="col">Confirm Password: <span style="color: red;">*</span></div>
    </div>
    <div class="row m-t-5">
      <div class="col"><input class="form-control" formControlName="userName" placeholder="Username"></div>
      <div class="col">
        <input class="form-control" type="password" formControlName="password" placeholder="Password">
        <div class="text-danger" *ngIf="newProviderUserForm.controls['password'].dirty && newProviderUserForm.controls['password'].invalid">
          <small class="text-danger" *ngIf="newProviderUserForm.controls['password'].hasError('pattern')">
            Invalid password format. Passwords must be at least 8 characters in length, with at least one of
            each of the following: digit, lowercase letter, uppercase letter, and non-letter/non-digit.
          </small>
          <small class="text-danger" *ngIf="newProviderUserForm.controls['password'].hasError('required')">
              Password is required.
          </small>
        </div>
      </div>
      <div class="col"><input class="form-control" type="password" formControlName="matchPassword" placeholder="Confirm Password"></div>
    </div>

    <mat-divider class="m-t-10 m-b-10"></mat-divider>
    <div class="row">
      <div class="col d-flex justify-content-center">
        <button mat-flat-button class="m-r-10" color="secondary" (click)="closeNewUser()">CANCEL</button>
        <button mat-flat-button class="btn-primary pl" [disabled]="this.newProviderUserForm.invalid" (click)="addNewUser()">CREATE USER</button>
      </div>
    </div>
  </form>
</div>
