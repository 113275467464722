import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userTypePipe'
})
export class UserTypePipe implements PipeTransform {

  transform(value: string): string {
    switch(value)
    {
      case "ProviderUser": return "Provider";
      case "RootUser": return "Root";
      default: return value;
    }
  }

}
