<div class="contentContainer">
  <div class="row justify-content-end" >
    <div class="col-md-2">
      <input type="text" class="form-control pull-right w m-r-lg" placeholder="SEARCH" [(ngModel)]="searchText" (ngModelChange)="filterReports()"/>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4" >
      <h2 style="position: relative;">
        Reports
        <span class="badge pl bg-primary alert_indicator"> {{reports.length}} </span>
      </h2>
    </div>
    <div class="col-md-4 offset-md-4">
      <span class="fa-stack fa-pull-right text-lg m-t-10 clickable" (click)="toggleNewReportVisibility()">
        <i class="fa fa-circle fa-stack-2x pl" [ngClass]="{'text-danger':showNewReport, 'text-primary':!showNewReport}"></i>
        <i class="fa fa-stack-1x fa-inverse" [ngClass]="{'fa-plus':!showNewReport, 'fa-minus':showNewReport}"></i>
      </span>
    </div>
  </div>
  <mat-divider class="m-t-10 m-b-10"></mat-divider>
  <div *ngIf="showNewReport">
    <app-report-new (closeView)="closeNew()" (newReportAdded)="addReport($event)" [reportOptions]="reportOptions"></app-report-new>
  </div>
  <div>
    <div *ngFor="let report of currentItemsToShow; let i = index" class="m-t-10 br-5 p-a-10 bg-white" style="user-select: none;">
      <div class="row g-0 itemCenterVertical">
        <div class="col-md-8">
          <i class="fa fa-globe m-r-5" matTooltip="Company Report" *ngIf="!report.userOnly"></i>
          <i class="fa fa-user m-r-5" matTooltip="My Report" *ngIf="report.userOnly==true"></i>
          {{report.name}}
        </div>
        <div class="col-md-4">
          <span class="fa-stack fa-pull-right text-sm m-l-10 clickable" (click)="element = element === report ? null : report">
            <i class="fa fa-circle-thin fa-stack-2x"></i>
            <i class="fa fa-stack-1x text-black fa-caret-down"
               [ngClass]="{'indicator_collapsed' : element != report, 'indicator_expanded' :element == report}"></i>
          </span>
          <i class="fa fa-pull-right fa-file-text-o fa-lg clickable m-t-15 m-r-10" (click)="showView = showView === report ? null : report; showEdit = null; element = null" matTooltip="View Report" ></i>
          <i class="fa fa-pull-right fa-pencil fa-lg clickable m-t-15 m-r-10" (click)="showEdit = showEdit === report ? null : report; showView = null; element = null" *ngIf="report.type == 'Custom'" matTooltip="Edit Report" ></i>
          <i class="fa fa-pull-right fa-trash-o fa-lg clickable m-t-15 m-r-10" (click)="deleteReport(report)" *ngIf="report.type == 'Custom'" matTooltip="Delete Report"></i>
        </div>
      </div>
      <div *ngIf="element == report" class="reportOptions">
        <mat-divider class="m-t-10 m-b-10"></mat-divider>
        <span>Fields:</span>
        <mat-grid-list cols="5" rowHeight="50px">
          <mat-grid-tile *ngFor="let fields of report.fields">
            {{getReportFieldDescription(fields.Id)}}
          </mat-grid-tile>
        </mat-grid-list>
      </div>
      <div class="row" *ngIf="showEdit == report">
        <app-report-edit (closeView)="closeView()" (saveChanges)="saveReport($event)" [report]="report" [reportOptions]="reportOptions"></app-report-edit>
      </div>
      <div class="row" *ngIf="showView == report">
        <app-report-view (closeView)="closeView()" (viewReport)="runReport($event)" [clients]="clients" [report]="report" [reportOptions]="reportOptions"></app-report-view>
      </div>
    </div>
    <mat-paginator class="m-t-10" [length]="reports?.length" (page)="onPageChange($event)" [pageSize]="10" [showFirstLastButtons]="true" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page"></mat-paginator>
  </div>
</div>
