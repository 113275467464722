<form [formGroup]="newProductForm">
  <div class="productNewItem">
    <div class="row m-t-10">
      <div class="col-4">
        <input class="form-control" placeholder="Product Name" formControlName="description">
      </div>
      <div class="col-4">
        <mat-select class="form-control" placeholder="Select Category" formControlName="categoryId">
          <mat-option *ngFor="let item of loanCategory" [value]="item.Id">{{item.description}}</mat-option>
        </mat-select>
      </div>
      <div class="col d-flex justify-content-end">
        <button mat-flat-button class="m-r-10" color="secondary" (click)="closeNewProduct()">CANCEL</button>
        <button mat-flat-button class="pl btn-primary" [disabled]="!newProductForm?.valid" (click)="saveNewProduct()">SAVE</button>
      </div>
    </div>
    <mat-divider class="m-t-10 m-b-10"></mat-divider>
    <div class="qualifications">
      <div class="row qualificationsTitle">
        <div class="col">Scores</div>
        <div class="col"></div>
        <div class="col">Loan Range</div>
        <div class="col">APR</div>
        <div class="col">Terms</div>
        <div class="col">Monthly Payment</div>
        <div class="col-1" *ngIf="qualifications?.length > 1"></div>
      </div>
      <div class="row m-b-10" formArrayName="qualifications" *ngFor="let item of qualifications.controls; let i = index">
        <div class="row" [formGroupName]="i">
          <div class="col input-group">
            <span class="input-group-text">From</span>
            <input type="text" class="form-control scoreInput" formControlName="scoreStart">
          </div>
          <div class="col input-group">
            <span class="input-group-text">To</span>
            <input type="text" class="form-control scoreInput" formControlName="scoreEnd">
            <!-- <small class="text-danger"  *ngIf="item.errors?.['ScoresQualValid']">
              Score "To" needs to be higher than score "From".
            </small> -->
          </div>
          <div class="col input-group"> <span class="input-group-text">$</span><input type="text" class="form-control" formControlName="prequalifiedAmount"></div>
          <div class="col input-group"> <input type="text" class="form-control" formControlName="apr"><span class="input-group-text">%</span></div>
          <div class="col"><input type="text" class="form-control" formControlName="terms"></div>
          <div class="col input-group"> <span class="input-group-text">$</span><input type="text" class="form-control" formControlName="monthlyPayment" ></div>
          <div class="col-1" *ngIf="qualifications?.length > 1">
            <span class="fa-stack fa-pull-right text-sm clickable"  style="font-size: 9pt" matTooltip="Remove Qualification" (click)="removeQualification(i)">
              <i class="fa fa-circle fa-stack-2x text-danger"></i>
              <i class="fa fa-stack-1x fa-inverse fa-minus"></i>
            </span>
          </div>
        </div>
        <div class="m-t-10">
          <small class="text-danger"  *ngIf="item.errors?.['ScoresQualValid']">
            Score "To" needs to be higher than score "From".
          </small>
        </div>
      </div>
      <div class="row m-t-15 m-b-10">
        <div><button mat-flat-button class="pl btn-primary" (click)="addQualification()">Add Qualification</button></div>
      </div>
    </div>
    <div class="row">
      <div class="col">Decline Reason</div>
    </div>
    <div class="row m-t-10 m-b-10">
      <div class="col">
        <label class="i-checks i-checks-sm">
          <input type="checkbox" id="cbRememberMe"  formControlName="bankruptcy"  name="bankruptcy" >
          <i></i>
          Had bankruptcies in the last 4 years
        </label>
      </div>
    </div>
    <div class="row m-t-10 m-b-10">
      <div class="col">
        <label class="i-checks i-checks-sm">
          <input type="checkbox" id="cbRememberMe" formControlName="foreclosure" name="foreclosure" >
          <i></i>
          Had foreclosures in the last 7 years
        </label>
      </div>
    </div>
    <div class="row m-t-10 m-b-10">
      <div class="col">
        <label class="i-checks i-checks-sm">
          <input type="checkbox" id="cbRememberMe" formControlName="shortSale" name="shortSale" >
          <i></i>
          Had short sales in the last 4 years
        </label>
      </div>
    </div>
    <div class="row m-t-10 m-b-10">
      <div class="col">
        <label class="i-checks i-checks-sm">
          <input type="checkbox" id="cbRememberMe" formControlName="pastDueMortgagePayment" name="pastDueMortgagePayment" >
          <i></i>
          Had mortgage payments 60 days past due in the last 12 months
        </label>
      </div>
    </div>
    <!-- <div class="row"><div class="col"> <mat-checkbox formControlName="bankruptcy" color="primary">Had bankruptcies in the last 4 years</mat-checkbox></div></div>
    <div class="row"><div class="col"> <mat-checkbox formControlName="foreclosure" color="primary">Had foreclosures in the last 7 years</mat-checkbox></div></div>
    <div class="row"><div class="col"> <mat-checkbox formControlName="shortSale" color="primary">Had short sales in the last 4 years</mat-checkbox></div></div>
    <div class="row"><div class="col"> <mat-checkbox formControlName="pastDueMortgagePayment" color="primary">Had mortgage payments 60 days past due in the last 12 months</mat-checkbox></div></div> -->

    <mat-divider class="m-t-10 m-b-10"></mat-divider>
    <div class="productUsers p-a-10">
      <div  *ngIf="addUsers">
        <div *ngFor="let item of addableUsers">
          <div *ngIf="filterUser(item)" class="productNewItem p-a-10 m-b-10">
            <div class="row productUserCenter">
              <div class="col"><i class="fa fa-user m-t-xs m-r-5"></i>{{item.contact}}</div>
              <div class="col">Web Address: {{item.code}}</div>
              <div class="col">
                <span class="fa-stack fa-pull-right text-md clickable"  matTooltip="Add User"  (click)="addUserToProduct(item)">
                  <i class="fa fa-circle fa-stack-2x pl text-primary"></i>
                  <i class="fa fa-stack-1x fa-inverse fa-plus"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row m-t-10 m-b-10">
          <div>
            <span class="fa-stack text-md  clickable" (click)="toggleAddUsers()">
              <i class="fa fa-circle fa-stack-2x pl" [ngClass]="{'text-danger':addUsers, 'text-primary':!addUsers}"></i>
              <i class="fa fa-stack-1x fa-inverse" [ngClass]="{'fa-plus':!addUsers, 'fa-minus':addUsers}"></i>
            </span>
            <span class="m-t-10">Users</span>
          </div>
        </div>
      <div class="productNewItem p-a-10 m-b-10" *ngFor="let item of newProduct.userInfo">
        <div class="row productUserCenter">
          <div class="col"><i class="fa fa-user m-t-xs m-r-5"></i>{{item.contact}}</div>
          <div class="col">Web Address: {{item.code}}</div>
          <div class="col">
            <span class="fa-stack fa-pull-right text-md clickable"  matTooltip="Remove User" (click)="removeUserFromProduct(item)">
              <i class="fa fa-circle fa-stack-2x text-danger"></i>
              <i class="fa fa-stack-1x fa-inverse fa-minus"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
