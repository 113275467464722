import { MatSnackBar } from '@angular/material/snack-bar';
import { RequestsService } from '../services/requests.service';
import { Request } from '../components/classes/Request';
import { Component, OnInit } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { RequestFilters } from '../components/classes/RequestFilters';
import { ProgressAlertComponent } from '../components/dialogs/progress-alert/progress-alert.component';
import { MatDialog } from '@angular/material/dialog';
import dayjs, { Dayjs } from 'dayjs/esm';

@Component({
  selector: 'app-inbox-orders',
  templateUrl: './inbox-orders.component.html',
  styleUrls: ['./inbox-orders.component.scss']
})
export class InboxOrdersComponent implements OnInit {

  element: any | null;
  requests: Array<Request> = [];
  filteredRequests: Array<Request> = [];
  currentItemsToShow = [];
  firstNameSearch: string;
  middleNameSearch: string;
  lastNameSearch: string;
  refNumSearch: string;
  filterDate: {
    startFilterDate: Dayjs,
    endFilterDate: Dayjs
  }
  statusSearch: string = "All Inquiries";
  archivedSearch: boolean;
  isLoading: boolean;
  requestFilters: RequestFilters = {};
  userKind = localStorage.getItem("userKind") || null
  advSearch: boolean = false;
  isProcessing: boolean;
  defaultRecords: any = 10;
  filteredOrders: boolean;
  searchText: string;
  ranges: any = {
    'Today': [dayjs(), dayjs()],
    'Yesterday': [dayjs().subtract(1, 'days'), dayjs().subtract(1, 'days')],
    'Last 7 Days': [dayjs().subtract(6, 'days'), dayjs()],
    'Last 30 Days': [dayjs().subtract(29, 'days'), dayjs()],
    'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
    'Last Month': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')]
  }


  constructor(private requestsService: RequestsService, private matSnackBar: MatSnackBar, private dialog:MatDialog ) { }

  ngOnInit() {
    if(this.userKind != "ProviderUser"){
      this.getOrders();
    }
  }


  async getOrders(){
    this.isLoading = true;
    const progressDialog = this.dialog.open(ProgressAlertComponent, {
      data: {
        title: 'Loading...'
      }
    });
    try{
      const data = await this.requestsService.getRequests(this.requestFilters);
      this.requests = this.requests.concat(data);
      this.setDefaultPaginatorItems();
      progressDialog.close();
      this.isLoading = false;
    }
    catch (e){
      progressDialog.close();
      this.matSnackBar.open('Something went wrong');
      this.isLoading = false;
    }
  }

  getPrequalifiedText(request: Request){
    if(request.status == Request.RequestStatus.Completed){
      if(request.prequalified){
        return "Pre-Qualified";
      }
      else{
        return "Not Pre-Qualified";
      }
    }
    else{
      return request.statusName
    }
  }

  filterRequests(){
    this.filteredRequests = [];
    if(this.searchText){
      this.requests.forEach( product => {
        const lowerRequest = product.lastName.toLowerCase()
        if(lowerRequest.includes(this.searchText.toLowerCase())){
          this.filteredRequests.push(product)
        }
      });
    }
    this.setDefaultPaginatorItems();
  }

  setDefaultPaginatorItems(){
    this.currentItemsToShow = [];
    if(this.searchText){
      this.currentItemsToShow = this.filteredRequests.slice(0, this.defaultRecords)
    }
    else{
      this.currentItemsToShow = this.requests.slice(0, this.defaultRecords)
    }  }

  onPageChange($event) {
    if(this.searchText){
      this.currentItemsToShow = this.filteredRequests.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
    }
    else{
      this.currentItemsToShow = this.requests.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);

    }  }

  filterOrders(){
    if(this.firstNameSearch){
      this.requestFilters.firstName = this.firstNameSearch;
    }

    if(this.middleNameSearch){
      this.requestFilters.middleName = this.middleNameSearch;
    }

    if(this.lastNameSearch){
      this.requestFilters.lastName = this.lastNameSearch;
    }

    if(this.refNumSearch){
      this.requestFilters.refNum = this.refNumSearch;
    }

    if(this.filterDate.startFilterDate){
      this.requestFilters.dateFrom = this.filterDate.startFilterDate.format("MM/DD/YYYY").toString();
      this.requestFilters.dateTo = this.filterDate.endFilterDate.format("MM/DD/YYYY").toString();
    }

    
    this.requestFilters.archived = this.archivedSearch ? true : false;


    if(this.requestFilters){
        this.filteredOrders = true;
    }
    else{
        this.filteredOrders = false;
    }

    this.requests = [];
    this.filteredRequests = [];
    this.getOrders();
  }

  filterStatus(){
    if(this.statusSearch == "All Inquiries"){
      this.setDefaultPaginatorItems();
    }
    else{
      this.filteredRequests = this.requests.filter((request) => this.getPrequalifiedText(request) === this.statusSearch);
      this.currentItemsToShow = this.filteredRequests.slice(0, this.defaultRecords)
    }

  }

  clearFilterOrders(){
    this.firstNameSearch = "";
    this.middleNameSearch = "";
    this.lastNameSearch = "";
    this.refNumSearch = "";
    this.filterDate = null;

    this.requestFilters.archived = false;
    this.requestFilters = {};
    this.requests = [];
    if(this.userKind != "ProviderUser"){
      this.getOrders();
    }
  }

  getOrdersLength(){
    return this.requests.length;
  }

  async removedRequestFromInbox(request){
    if(request){
      const progressDialog = this.dialog.open(ProgressAlertComponent, {
        data: {
          title: 'Deleting Order...'
        }
      });
      request.archived = true;
      await this.requestsService.putRequests(request);
      const index = this.requests.indexOf(request)
      this.requests.splice(index, 1);
      this.setDefaultPaginatorItems();
      progressDialog.close();
    }
  }
}
