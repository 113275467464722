import { Component, OnInit } from '@angular/core';
import { LoanCategory, LoanType, Product } from '../components/classes/LoanType';
import { ProgressAlertComponent } from '../components/dialogs/progress-alert/progress-alert.component';
import { ClientService } from '../services/client.service';
import { UsersService } from '../services/users.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { plainToClass } from 'class-transformer';
import { User } from '../components/classes/User';

@Component({
  selector: 'app-product-setup',
  templateUrl: './product-setup.component.html',
  styleUrls: ['./product-setup.component.scss']
})
export class ProductSetupComponent implements OnInit {

  products:Array<LoanType> = [];
  filteredProducts: Array<LoanType> = [];
  currentItemsToShow = [];
  users: Array<User> = [];
  loanCategories: Array<LoanCategory> = [];
  showNewProduct: boolean;
  searchText: string;
  subscriberID = localStorage.getItem("parentId") || null;
  defaultRecords: any = 10;


  constructor(private clientService:ClientService, private usersService:UsersService, private matSnackBar: MatSnackBar, private dialog:MatDialog) { }

  ngOnInit() {
    this.loadProductPage()
  }

  filterProduct(){
    this.filteredProducts = [];
    if(this.searchText){
      this.products.forEach( product => {
        const lowerProduct = product.description.toLowerCase()
        if(lowerProduct.includes(this.searchText.toLowerCase())){
          this.filteredProducts.push(product)
        }
      });
    }
    this.setDefaultPaginatorItems();
  }

  async loadProductPage(){
    const progressDialog = this.dialog.open(ProgressAlertComponent, {
      data: {
        title: 'Loading...'
      }
    });
    await this.getUsers();
    await this.getLoanCategories();
    await this.getLoanTypes();
    progressDialog.close();
  }

  toggleNewProductVisibility(){
    this.showNewProduct = !this.showNewProduct;
  }

  setDefaultPaginatorItems(){
    this.currentItemsToShow = [];
    if(this.searchText){
      this.currentItemsToShow = this.filteredProducts.slice(0, this.defaultRecords)
    }
    else{
      this.currentItemsToShow = this.products.slice(0, this.defaultRecords)
    }
  }

  onPageChange($event) {
    if(this.searchText){
      this.currentItemsToShow = this.filteredProducts.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
    }
    else{
      this.currentItemsToShow = this.products.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
    }
  }

  async addNewProduct(newProduct){
    const progressDialog = this.dialog.open(ProgressAlertComponent, {
      data: {
        title: 'Loading...'
      }
    });
    try{
      await this.clientService.addNewProduct(newProduct);
      this.showNewProduct = !this.showNewProduct;
      this.products = [];
      this.getLoanTypes();
      progressDialog.close();
    }
    catch{
      progressDialog.close();
      this.matSnackBar.open('Something went wrong');
    }
  }

  async getLoanTypes(){
    try{
      const data = await this.clientService.getLoanTypes(this.subscriberID);
      this.products = this.products.concat(plainToClass(LoanType, data));
      this.setDefaultPaginatorItems()
    }
    catch (e){
      this.matSnackBar.open('Something went wrong');
    }
  }

  async getUsers(){
    try{
      const data = await this.usersService.getUsers(this.subscriberID);
      this.users = this.users.concat(plainToClass(User, data));
    }
    catch (e){
      this.matSnackBar.open('Something went wrong');
    }
  }

  async getLoanCategories(){
    try{
      const data = await this.clientService.getCategories(this.subscriberID);
      this.loanCategories = this.loanCategories.concat(plainToClass(LoanCategory, data))
    }
    catch{
      this.matSnackBar.open('Something went wrong');
    }
  }


}
