import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Inject, Injectable} from "@angular/core";
import { Observable } from 'rxjs/internal/Observable';
@Injectable()
export class APIInterceptor implements HttpInterceptor {
  constructor(
    @Inject('BASE_API_URL') private baseUrl: string) {
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const apiReq = req.clone({url: `${this.baseUrl}/${req.url}`});
    return next.handle(apiReq);
  }
}
