import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Request } from 'src/app/components/classes/Request';
import { AlertsComponent } from 'src/app/components/dialogs/alerts/alerts.component';
import { ProgressAlertComponent } from 'src/app/components/dialogs/progress-alert/progress-alert.component';
import { ReportService } from 'src/app/services/report.service';
import { RequestsService } from 'src/app/services/requests.service';
import { ToolsService } from 'src/app/services/tools-service';

@Component({
  selector: 'app-inbox-order-details',
  templateUrl: './inbox-order-details.component.html',
  styleUrls: ['./inbox-order-details.component.scss']
})
export class InboxOrderDetailsComponent implements OnInit {

  @Input() request: Request;
  isGettingReport: boolean;
  isProcessing: boolean;
  @Output() removedFromInbox: EventEmitter<any> = new EventEmitter;

  constructor(private matSnackBar: MatSnackBar, private dialog:MatDialog, private reportService:ReportService, private toolsService:ToolsService, private requestsService: RequestsService ) { }

  ngOnInit() {

  }


  getPrequalifiedText(request: Request){
    if(request.status == Request.RequestStatus.Completed){
      if(request.prequalified){
        return "Pre-Qualified";
      }
      else{
        return "Not Pre-Qualified";
      }
    }
    else{
      return request.statusName
    }

  }

  async viewReport(e: Event, request: Request){
    e.stopPropagation()
    const progressDialog = this.dialog.open(ProgressAlertComponent, {
      data: {
        title: 'Opening Report...'
      }
    });

    try {
      const response = this.reportService.getOrderReport(request.Id);
      this.toolsService.openBlob((await response).body, request.Id);
      progressDialog.close();
    }
    catch {
      progressDialog.close();
      this.matSnackBar.open('Something went wrong while trying to open the report');
    }
  }

  removeFromInbox(e: Event,request: Request){
    e.stopPropagation()
    const dialogRef = this.dialog.open(AlertsComponent, {
      data: {
        message: `Are you sure you want to remove ref #${request.refNum}? This will archive the request for all users.`,
        title: "Confirm Removal",
        confirmButtonName: "OK",
        isConfirmation: true,
        destructive: true
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if(result) {
        this.removedFromInbox.emit(request);
      }
    });
  }

}
