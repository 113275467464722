<div class="p-a-10">
  <form #viewReportForm="ngForm">
    <span>Add Additional Fields: </span>
    <div class="reportOptions">
      <mat-grid-list cols="6" rowHeight="30px">
        <mat-grid-tile *ngFor="let option of reportOptions">
          <label class="i-checks i-checks-sm">
            <input type="checkbox" id="cbRememberMe" [(ngModel)]="option.select" [name]="option.displayName">
            <i></i>
            {{option.displayName}}
          </label>
          <!-- <mat-checkbox [(ngModel)]="option.select" [name]="option.displayName" color="primary">{{option.displayName}}</mat-checkbox> -->
        </mat-grid-tile>
      </mat-grid-list>
    </div>
    <mat-divider class="m-t-10 m-b-10"></mat-divider>
    <div *ngIf="reportVariables">
      <div class="row m-t-10 m-b-5">
        <div class="col-4">Date Range</div>
      </div>
      <div class="row m-b-10">
        <div class="col-md-4">
          <input type="text" required ngxDaterangepickerMd readonly [ranges]="ranges" [locale]="{format: 'MM/DD/YYYY'}" [alwaysShowCalendars]="true" startKey="startFilterDate" endKey="endFilterDate" [autoApply]="true" [(ngModel)]="filterDate"  class="dateRangeInput form-control" placeholder="Date Range" name="dateRange">
        </div>
        <div class="col-md-4">
          <mat-radio-group [(ngModel)]="reportVariables.filterDateCompleted" name="filterDateCompleted">
            <mat-radio-button color="primary" [value]="true">Filter by Date Requested</mat-radio-button>
            <mat-radio-button color="primary" [value]="false">Filter by Date Completed</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="col-md-2 m-t-5">
          <label class="i-checks i-checks-sm">
            <input type="checkbox" id="cbRememberMe" [(ngModel)]="reportVariables.emailReport" name="emailReport" >
            <i></i>
            Send Report by Email
          </label>
        </div>
      </div>
      <div class="row m-t-10 m-b-5">
        <div class="col-3">Status</div>
      </div>
      <div class="row m-b-10">
        <div class="col-md-3">
          <mat-select [(ngModel)]="reportVariables.status" class="form-control" name="status" placeholder="Choose a Status">
            <mat-option *ngFor="let item of status" [value]="item.value">{{item.name}}</mat-option>
          </mat-select>
        </div>
      </div>
      <div *ngIf="userKind=='ProviderUser'">
          <div class="row m-t-10 m-b-5">
            <div class="col-3">Client</div>
            <div class="col-3">Client Code</div>
          </div>
          <div class="row">
            <div class="col-3">
              <mat-select  class="form-control" [(ngModel)]="reportVariables.clientId" name="clientId" placeholder="Choose Client">
                <mat-option [value]="'0'">All Clients</mat-option>
                <mat-option *ngFor="let item of clients" [value]="item.Id">{{item.companyName}}</mat-option>
              </mat-select>
            </div>
            <div class="col-3"><input class="form-control" [(ngModel)]="reportVariables.clientCode" name="clientCode" type="text" placeholder="Client Code"></div>
          </div>
      </div>
    </div>

    <mat-divider class="m-t-10 m-b-10"></mat-divider>
    <div class="text-center">
      <button mat-flat-button class="btn-primary pl m-r-10" [disabled]="viewReportForm.invalid" (click)="runReport()">RUN REPORT</button>
      <button mat-flat-button color="secondary" (click)="closeViewFunc()">CANCEL</button>
    </div>
  </form>
</div>
