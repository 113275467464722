import { Component, OnInit, Input, EventEmitter, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { plainToClass } from 'class-transformer';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { Client, ClientColors, ProductSetupEnabled } from 'src/app/components/classes/Client';
import { Logo } from 'src/app/components/classes/Logo';
import { States } from 'src/app/components/constants/UsStates';
import { ProgressAlertComponent } from 'src/app/components/dialogs/progress-alert/progress-alert.component';
import { ConsumerPreviewComponent } from 'src/app/consumer/consumer-preview/consumer-preview.component';
import { ClientService } from 'src/app/services/client.service';
import { ToolsService } from 'src/app/services/tools-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-client-setup-item',
  templateUrl: './client-setup-item.component.html',
  styleUrls: ['./client-setup-item.component.scss']
})
export class ClientSetupItemComponent implements OnInit {

  @Input() client: Client;
  // clientForm: FormGroup
  @ViewChild("clientForm") clientForm: NgForm;
  originalClientData: Client
  subscriberID = localStorage.getItem("parentId") || null;
  userKind = localStorage.getItem("userKind") || null;
  editingClient: boolean;
  element: any | null;
  colorPickerControls: string = "";
  viewOutput: string = "ViewOutput.aspx";
  attachmentHandler: string = "AttachmentHandler.aspx"
  showLogoMessage = "If checked, the logo will appear on both the administrative site and consumer site. If unchecked, the logo will only appear on the administrative site.";
  logoURL: string;
  uploader:FileUploader;
  response:string;
  allowedFileTypes: string = '|jpg|png|jpeg|bmp|gif|';
  parsedBranding: ClientColors;
  statesList = States;

  constructor(private clientService:ClientService, private toolService: ToolsService,private matSnackBar: MatSnackBar, private dialog:MatDialog) {}

  async ngOnInit() {

    if(this.client == null){
      await this.getClient();
      this.element = this.client;
      this.originalClientData = this.toolService.deepClone(this.client);
    }
    else{
      this.parsedBranding = JSON.parse(this.client.branding);
      this.originalClientData = this.toolService.deepClone(this.client);
      this.checkProviderSetUp();
      this.setLogo();
    }
    this.uploader = new FileUploader({
      url: environment.apiUrl + "/" + this.attachmentHandler,
      method: 'post',
    })
    this.uploader.onSuccessItem = (fileItem , response) => this.saveFileResponse(response) ;
  }

  startEdit(){
    this.element = this.client;
    this.editingClient = true;
  }

  cancelClientEdit(){
    this.editingClient = false;
    this.client = this.originalClientData;
  }

  async getClient(){
    const progressDialog = this.dialog.open(ProgressAlertComponent, {
      data: {
        title: 'Loading...'
      }
    });
    try{
      const data = await this.clientService.getClient(this.subscriberID);
      this.client = plainToClass(Client, data);
      this.parsedBranding = JSON.parse(this.client.branding);
      this.checkProviderSetUp();
      progressDialog.close();

    }
    catch{
      progressDialog.close();
      this.matSnackBar.open('Something went wrong');
    }
  }

  setLogo(){
    if(this.client.logo){
      this.logoURL = environment.apiUrl + "/"+ this.viewOutput + "?filename=" + this.toolService.getFilenameFromURL(this.client.logo.url);
    }
    else{
      this.logoURL = null
    }
  }

  handleFiles(fileItem) {
    //check file type first before upload
    var type = '|' + fileItem[0].type?.slice(fileItem[0].type.lastIndexOf('/') + 1) + '|';
    const validType = this.allowedFileTypes.indexOf(type) !== -1
    if(validType){
      this.uploader.queue[0].upload();
    }
    else{
      //this.uploader.cancelAll(); //remove file from the uploader queue
      this.uploader.clearQueue();
      console.log(this.uploader.queue)
      this.matSnackBar.open('Error file is an invalid type.', "OK");
    }
  }

  saveFileResponse(response){
    const newLogo = JSON.parse(response);
    this.client.logo = newLogo[0];
    this.setLogo();
  }

  removeFile() {
    this.client.logo = new Logo();
  };

  checkProviderSetUp(){
    if(this.client.productSetup.EFX == null){
      this.client.productSetup.EFX = new ProductSetupEnabled();
      this.client.productSetup.EFX.enabled = false;
    }
    if(this.client.productSetup.TU == null){
      this.client.productSetup.TU = new ProductSetupEnabled();
      this.client.productSetup.TU.enabled = false;
    }
    if(this.client.productSetup.XPN == null){
      this.client.productSetup.XPN = new ProductSetupEnabled();
      this.client.productSetup.XPN.enabled = false;
    }
  }

  setBranding(){
    this.parsedBranding.FooterBackgroundColor = this.parsedBranding.HeaderBackgroundColor
    this.parsedBranding.ButtonBackgroundColor =this.parsedBranding.SelectedTabBackgroundColor
    this.parsedBranding.HeaderTextColor = this.parsedBranding.SelectedTabBackgroundColor
    this.parsedBranding.FinishHeadingTextColor = this.parsedBranding.SelectedTabBackgroundColor
    this.parsedBranding.CategoryHeadingTextColor =  this.parsedBranding.UnselectedTabBackgroundColor
    this.client.branding = JSON.stringify(this.parsedBranding);
  }


  async saveClient(){
    const progressDialog = this.dialog.open(ProgressAlertComponent, {
      data: {
        title: 'Saving Client...'
      }
    });
    try{
      this.setBranding()
      await this.clientService.updateClient(this.client);
      this.originalClientData = this.client;
      this.editingClient = false;
      progressDialog.close();
    }
    catch{
      progressDialog.close();
      this.matSnackBar.open('Something went wrong');
    }
  }

  previewBranding(){
    this.dialog.open(ConsumerPreviewComponent, {
      data:{
        branding: this.parsedBranding
      },
      width: '1500px'
    })
  }


}
