import { ToolsService } from 'src/app/services/tools-service';
import { confirmPasswordValidator, matchPasswordValidator } from './../../components/constants/confirm-password.validator';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { plainToClass } from 'class-transformer';
import { Client } from 'src/app/components/classes/Client';
import { ZipLookUpInfo } from 'src/app/components/classes/Consumer';
import { User } from 'src/app/components/classes/User';
import { States } from 'src/app/components/constants/UsStates';
import { ProgressAlertComponent } from 'src/app/components/dialogs/progress-alert/progress-alert.component';
import { ClientService } from 'src/app/services/client.service';
import { ConsumerService } from 'src/app/services/consumer.service';

@Component({
  selector: 'app-provider-setup-new-provider',
  templateUrl: './provider-setup-new-provider.component.html',
  styleUrls: ['./provider-setup-new-provider.component.scss']
})
export class ProviderSetupNewProviderComponent implements OnInit {

  newProvider: Client;
  newUser: User;
  statesList = States;
  newProviderForm: FormGroup;
  newProviderUserForm: FormGroup;
  confirmPassword: string;
  zipData: ZipLookUpInfo;
  @Output() cancelNewProvider: EventEmitter<any> = new EventEmitter();
  @Output() addedNewProvider: EventEmitter<any> = new EventEmitter();

  constructor(private clientService:ClientService, private toolService: ToolsService ,private consumerService: ConsumerService, private matSnackBar: MatSnackBar, private dialog:MatDialog) { }

  ngOnInit() {
    this.newProvider = new Client();
    this.newUser = new User();
    this.newProviderForm = new FormGroup({
      email: new FormControl(this.newProvider.email, {nonNullable: true, validators: [Validators.required, Validators.email]}),
      contact: new FormControl(this.newProvider.contact),
      phone: new FormControl(this.newProvider.phone, {nonNullable: true, validators: [Validators.required, Validators.pattern("^[0-9]*$"), ]}),
      companyName: new FormControl(this.newProvider.companyName, {nonNullable: true, validators: [Validators.required,]}),
      zip: new FormControl(this.newProvider.zip, {nonNullable: true, validators: [Validators.required]},),
      state: new FormControl(this.newProvider.state, {nonNullable: true, validators: [Validators.required]},),
      city: new FormControl(this.newProvider.city, {nonNullable: true, validators: [Validators.required]},),
      address: new FormControl(this.newProvider.address, {nonNullable: true, validators: [Validators.required]},),
      userEmail: new FormControl(this.newProvider.email, {nonNullable: true, validators: [Validators.required, Validators.email]}),
      userContact: new FormControl(this.newUser.contact, {nonNullable: true, validators: [Validators.required]}, ),
      userPhone: new FormControl(this.newUser.phone, {nonNullable: true, validators: [Validators.pattern("^[0-9]*$")]}),
      userCell: new FormControl(this.newUser.cell, {nonNullable: true, validators: [Validators.pattern("^[0-9]*$")]} ),
      userName: new FormControl(this.newUser.userName, {validators:[ Validators.required]}),
      password: new FormControl(this.newUser.password, [
        Validators.required,
        Validators.pattern("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&]).{8,50}")
      ]),
      matchPassword: new FormControl(this.confirmPassword, [Validators.required]),
    },
    {validators: matchPasswordValidator})
  }

  async zipcodeLookUp(zip: string){
    if(zip?.length >= 5){
        this.zipData =  await this.toolService.zipLookUp(zip);
        this.newProviderForm.patchValue({
          state: this.zipData.state,
          city: this.zipData.city
        })
    }
  }

  closeNewProvider(){
    this.cancelNewProvider.emit()
  }

  setProviderValues(){
    this.newProvider.address = this.newProviderForm.value.address;
    this.newProvider.email = this.newProviderForm.value.email;
    this.newProvider.phone = this.newProviderForm.value.phone;
    this.newProvider.companyName = this.newProviderForm.value.companyName;
    this.newProvider.zip = this.newProviderForm.value.zip;
    this.newProvider.state = this.newProviderForm.value.state;
    this.newProvider.city = this.newProviderForm.value.city;
  }

  setUserValues(){
    this.newUser.contact = this.newProviderForm.value.userContact;
    this.newUser.email = this.newProviderForm.value.userEmail;
    this.newUser.phone = this.newProviderForm.value.userPhone;
    this.newUser.cell = this.newProviderForm.value.userCell;
    this.newUser.userName = this.newProviderForm.value.userName;
    this.newUser.password = this.newProviderForm.value.password;
    this.newUser.matchPassword = this.newProviderForm.value.matchPassword;
    this.newUser.kind = "ProviderUser"
  }

  async addNewProvider(){
    const progressDialog = this.dialog.open(ProgressAlertComponent, {
      data: {
        title: 'Adding New Provider...'
      }
    });
    try{
      //Set the form values back to the objects to set to the server
      await this.setProviderValues();
      await this.setUserValues();
      this.newProvider.users = [];
      this.newProvider.users.push(this.newUser);
      await this.clientService.addProvider(this.newProvider);
      this.addedNewProvider.emit();
      progressDialog.close();
    }
    catch{
      progressDialog.close();
      this.matSnackBar.open('Something went wrong');
    }
  }

}
