import { Request } from '../components/classes/Request';
import { HttpHeaders , HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { RequestFilters } from '../components/classes/RequestFilters';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RequestsService {

constructor(private httpClient:HttpClient) { }

  async getRequests(request:RequestFilters){
    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json');

    const data = await lastValueFrom(this.httpClient.post('api/Requests', request, {headers: headers}))
    return plainToClass(Request, data);
  }
  
  async putRequests(request:Request){
    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json');

    const data = await lastValueFrom(this.httpClient.put("api/Request/" + request.Id, request, {headers: headers}));
    return plainToClass(Request, data);
  }

}


