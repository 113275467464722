<div class="center-screen consumerScreen">
  <div style="display: block;">
    <div class="topBar p-t-15 p-l-15" [style.background-color]="clientColors?.HeaderBackgroundColor" [style.color]="clientColors?.HeaderTextColor">
      <div class="consumerTitle" style="margin-bottom: 0;">{{consumerTitle}}</div>
    </div>
    <div class="consumerContent centerDiv" *ngIf="!beginRequest" style="display:inline-grid;">
      <div class="row" *ngIf="companyLogoUrl">
        <div class="col"><img class="logo" src="{{companyLogoUrl}}" alt="Test Logo"/></div>
      </div>
      <div class="row m-t-20" style="text-align: center ;">
        <div class="col"><button mat-raised-button style="color: #fefefe;" [style.background-color]="clientColors?.ButtonBackgroundColor" (click)="begin()">BEGIN</button></div>
      </div>
    </div>
    <mat-stepper [linear]="false" #stepper *ngIf="beginRequest">
      <mat-step label="CHOOSE YOUR PRODUCT">
        <div class="consumerContent">
          <div class="row m-b-20 m-t-20">
            <div class="col justify-content-center consumerTitle">
              <h2>What are you looking for today?</h2>
            </div>
          </div>
          <mat-divider class="m-t-10 m-b-10"></mat-divider>
          <div class="row justify-content-center">
            <div class="col">
              <h3 [style.color]="clientColors?.CategoryHeadingTextColor"><i [ngClass]="getDesctiptionIcon('Auto')" class="m-r-10"></i>Auto</h3>
              <div class="loanItem" [ngClass]="{'selectedProduct': selectProduct == 'Auto'}" (click)="selectProduct = 'Auto'; goForward()">
                  Test Product
              </div>
            </div>
            <div class="col">
              <h3 [style.color]="clientColors?.CategoryHeadingTextColor"><i [ngClass]="getDesctiptionIcon('Credit')" class="m-r-10"></i>Credit</h3>
              <div class="loanItem" [ngClass]="{'selectedProduct': selectProduct == 'Credit'}" (click)="selectProduct = 'Credit'; goForward()">
                  Test Product
              </div>
            </div>
            <div class="col">
              <h3 [style.color]="clientColors?.CategoryHeadingTextColor"><i [ngClass]="getDesctiptionIcon('Mortgage')" class="m-r-10"></i>Mortgage</h3>
              <div class="loanItem" [ngClass]="{'selectedProduct': selectProduct == 'Motrgage'}" (click)="selectProduct = 'Mortgage'; goForward()">
                  Test Product
              </div>
            </div>
          </div>
          <mat-divider class="m-t-10 m-b-10"></mat-divider>
        </div>
      </mat-step>
      <mat-step label="YOUR INFORMATION" >
        <ng-template matStepContent>
            <div class="consumerContent">
              <div class="m-b-25">
                <app-consumer-selected-product (changedProduct)="goToStepperIndex($event)"  [testProduct]="selectProduct" [branding]="clientColors" ></app-consumer-selected-product>
              </div>
              <h3>Personal Information</h3>
              <div class="row">
                <div class="col">First Name <span style="color: red;">*</span></div>
                <div class="col">Middle Name</div>
                <div class="col">Last Name <span style="color: red;">*</span></div>
              </div>
              <div class="row m-b-15">
                <div class="col "><input type="text" class="form-control" placeholder="First Name" disabled></div>
                <div class="col"><input type="text" class="form-control" placeholder="Middle Name" disabled></div>
                <div class="col"><input type="text" class="form-control" placeholder="Last Name" disabled></div>
              </div>
              <div class="row">
                <div class="col">Email <span style="color: red;">*</span></div>
                <div class="col">SSN <span style="color: red;">*</span></div>
                <div class="col">Contact Phone <span style="color: red;">*</span></div>
              </div>
              <div class="row m-b-20">
                <div class="col"><input type="text" class="form-control" placeholder="Email" disabled></div>
                <div class="col"><input type="text" class="form-control" mask="000-00-0000" placeholder="xxx-xx-xxxx" disabled></div>
                <div class="col"><input type="text" class="form-control" mask="(000) 000-0000" placeholder="(xxx) xxx-xxxx" disabled></div>
              </div>
              <mat-divider class="m-t-20 m-b-25"></mat-divider>
              <h3>Current Address</h3>
              <span>Do not enter PO Box address</span>
              <div class="row m-t-10">
                <div class="col-5">Address <span style="color: red;">*</span></div>
                <div class="col">Zip <span style="color: red;">*</span></div>
                <div class="col">City <span style="color: red;">*</span></div>
                <div class="col">State <span style="color: red;">*</span></div>
              </div>
              <div class="row m-b-30">
                <div class="col-5"><input type="text" class="form-control" placeholder="Address" disabled></div>
                <div class="col"><input type="text" class="form-control" placeholder="Zip" disabled></div>
                <div class="col"><input type="text" class="form-control" placeholder="City" disabled></div>
                <div class="col">
                  <mat-select class="form-control" placeholder="State" [disabled]="true">
                    <mat-option></mat-option>
                  </mat-select>
                </div>
              </div>
              <div class="p-a-10" [style.background-color]="clientColors?.HeaderBackgroundColor" [style.color]="clientColors?.HeaderTextColor">
                <button mat-flat-button style="color: #fefefe;" [style.background-color]="clientColors?.ButtonBackgroundColor" (click)="goForward()">NEXT</button>
              </div>
            </div>
        </ng-template>
      </mat-step>
      <mat-step label="AGREEMENTS">
        <ng-template matStepContent>
          <div class="consumerContent"  >
            <div class="m-b-20">
              <app-consumer-selected-product (changedProduct)="goToStepperIndex($event)" [testProduct]="selectProduct" [branding]="clientColors" ></app-consumer-selected-product>
            </div>
            <app-consumer-agreement [preview]="true" [branding]="clientColors" ></app-consumer-agreement>
            <div class="m-t-15 m-b-15" style="text-align: center;">Click "agree" to indicate your acceptance of the agreements above. <button mat-flat-button class="btn btn-success" color="primary" matStepperNext>AGREE</button></div>
            <div class="p-a-10" style="text-align: center;" [style.background-color]="clientColors?.HeaderBackgroundColor" [style.color]="clientColors?.HeaderTextColor">
              <button mat-flat-button style="color: #fefefe;" [style.background-color]="clientColors?.ButtonBackgroundColor" (click)="goBack()">BACK</button>
            </div>
          </div>
        </ng-template>
      </mat-step>
      <mat-step label="FINISH">
        <ng-template matStepContent>
          <div class="consumerContent">
            <app-consumer-finish [consumerInfo]="consumerInfo" [testProduct]="selectProduct" [preview]="true" [branding]="clientColors" [branding]="clientColors" [companyLogoUrl]="companyLogoUrl"></app-consumer-finish>
          </div>
        </ng-template>
      </mat-step>
    </mat-stepper>
  </div>
</div>
