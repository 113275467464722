import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AlertsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData | any) {}

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }
}

export interface DialogData {
  message: string;
  title: string;
  confirmButtonName: string;
  isConfirmation: boolean;
  destructive: boolean;
}
