import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { Consumer, ConsumerColors } from 'src/app/components/classes/Consumer';
import { ProgressAlertComponent } from 'src/app/components/dialogs/progress-alert/progress-alert.component';
import { ConsumerService } from 'src/app/services/consumer.service';
import { ToolsService } from 'src/app/services/tools-service';

@Component({
  selector: 'app-consumer-contact',
  templateUrl: './consumer-contact.component.html',
  styleUrls: ['./consumer-contact.component.scss']
})
export class ConsumerContactComponent implements OnInit {

  consumerInfo: Consumer;
  branding: ConsumerColors;
  clientCode: string;
  requestId: string;
  setContact: boolean;
  contactError: boolean;

  constructor(private route: ActivatedRoute, private toolService: ToolsService ,private consumerService: ConsumerService, private matSnackBar: MatSnackBar, private dialog:MatDialog) { }

  ngOnInit() {
    this.clientCode = this.route.snapshot.paramMap.get('clientCode');
    this.requestId = this.route.snapshot.paramMap.get('requestId');

    this.getConsumerData();
  }

  async getConsumerData(){
    const progressDialog = this.dialog.open(ProgressAlertComponent, {
      data: {
        title: 'Submitting Contact Request...'
      }
    });
    try{
      const data = await this.consumerService.getClientInfo(this.clientCode);
      this.consumerInfo = plainToClass(Consumer, data);
      this.setClientStyles();
      await this.contactMe()
      progressDialog.close();
    }
    catch{
      progressDialog.close();
      this.matSnackBar.open('Something went wrong');
    }
  }

  setClientStyles(){
    this.branding = JSON.parse(this.consumerInfo.branding);
  }

  async contactMe(){
    try{
      if(this.requestId){
         const data = await this.consumerService.postContact(this.requestId);
         this.setContact = true;
       }
    }
    catch(e){
      this.contactError = false;
    }
  }


}
