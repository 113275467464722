
import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export const scoreQualificationValid =  ( control: AbstractControl): ValidationErrors | null => {
  if(control.value.scoreEnd != null && control.value.scoreStart != null){
    var start: number = parseInt(control.value.scoreStart)
    var end: number = parseInt(control.value.scoreEnd)
    const data = end > start ? null : { ScoresQualValid: true };
    return data;
  }
  else{
    return null
  }
}
