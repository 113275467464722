<div class="p-a-20">
  <div class="row m-b-30">
        <div class="col"><img *ngIf="companyLogoUrl" class="logo" src="{{companyLogoUrl}}" alt="{{consumerInfo.companyName}}"/></div>
        <div class="col print"><i class="fa-solid fa-print fa-lg clickable" matTooltip="Print" (click)="printScreen()"></i></div>
  </div>
  <div class="row">
    <div class="col text-center" [ngStyle]="{'color':branding.FinishHeadingTextColor}">
      <h2>{{requestResponse?.header}}</h2>
  </div>
  <div class="row">
    <div class="col text-center">
      <h3>{{requestResponse?.message}}</h3></div>
  </div>
  </div>
  <mat-divider class="m-b-30 m-t-10"></mat-divider>
  <div class="row">
    <div class="col-5 contactMe text-center">
      <h2 [ngStyle]="{'color':branding.FinishHeadingTextColor}">{{requestResponse?.contactHeader}}</h2>
      <div class="m-t-20">
         <button *ngIf="!setContact" class="btn m-t-lg m-b-md" type="button" (click)="contactMe()" style="color: white;"  [ngStyle]="{background:branding.UnselectedTabBackgroundColor}">Contact Me</button>
         <p *ngIf="setContact" class="text-success">Thank you! You will be contacted soon.</p>
      </div>
    </div>
    <div class="col-2 text-center">
      <div class="vertical-line-word-wrapper">
        <div class="vertical-line"></div>
        <div class="word-wrapper">
            <div class="word h3 text-dark">or</div>
        </div>
      </div>
    </div>
    <div class="col-5 text-center">
      <h2 [ngStyle]="{'color':branding.FinishHeadingTextColor}">Contact or Visit Us<br/>{{consumerInfo?.companyName}}</h2>
      <div class="m-t-20" [hidden]="!consumerInfo?.loPhone">{{consumerInfo?.loPhone | mask:'(000) 000-000'}}</div>
      <div class="m-t-5"><a href="{{'mailto:'+ consumerInfo?.loEmail}}">{{consumerInfo?.loEmail}}</a></div>
      <div class="m-t-10">Pre-Qualification From {{consumerInfo?.companyName}}</div>
      <div class="m-t-5">{{consumerInfo?.address}}, {{consumerInfo?.city}}, {{consumerInfo?.state}} {{consumerInfo?.zip}}</div>
    </div>
  </div>
  <div class="centerDiv m-t-20">
     <div *ngIf="!preview">
        <app-consumer-selected-product [isFinish]="true" [branding]="branding" [selectedProduct]="selectedProduct" [logo]="logo"></app-consumer-selected-product>
     </div>
     <div *ngIf="preview">
      <app-consumer-selected-product [isFinish]="true" [branding]="branding" [testProduct]="testProduct" [logo]="logo"></app-consumer-selected-product>
   </div>
  </div>
</div>

