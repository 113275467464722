import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

constructor() { }

public getToken(): string {
  return localStorage.getItem('token');
}//End getToken


public isAuthenticated(): boolean {
  const token_exp = Date.parse(localStorage.getItem('token_exp'));
  if(!token_exp) //if true, then the user isn't logged in.
    return false;
  const now = Date.now();
  const tokenIsExp = token_exp < now; //set to true if the token_exp is in the past
  return !tokenIsExp; //returns inverse because if the token is expired, then it is not authenticated
}//End isAuthenticated

}
