import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { Client } from 'src/app/components/classes/Client';
import { LoanType } from 'src/app/components/classes/LoanType';
import { User } from 'src/app/components/classes/User';
import { ProgressAlertComponent } from 'src/app/components/dialogs/progress-alert/progress-alert.component';
import { UserTypePipe } from 'src/app/components/pipes/userTypePipe';
import { ClientService } from 'src/app/services/client.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-user-setup-provider',
  templateUrl: './user-setup-provider.component.html',
  styleUrls: ['./user-setup-provider.component.scss']
})
export class UserSetupProviderComponent implements OnInit {

  @ViewChild("userTab") userTab: MatTabGroup;
  activeUsers: Array<User> = [];
  inactiveUsers: Array<User> = [];
  filteredActiveUsers: Array<User> = [];
  filteredInactiveUsers: Array<User> = [];
  currentItemsToShowActive = [];
  currentItemsToShowInActive = [];
  searchUser: string;
  activeSearchUser: string;
  inactiveSearchUser: string;
  clientToSelect:string = "All Clients";
  clientToSelectInactive:string = "All Clients";
  clients: Array<Client> = [];
  addingNew: boolean = false;
  defaultRecords: any = 10;
  clientFilter: string;
  loanTypes: Array<LoanType> = [];
  subscriberID = localStorage.getItem("parentId") || null;
  userKind = localStorage.getItem("userKind") || null;

  constructor(
      private clientService:ClientService, private usersService:UsersService,
      private matSnackBar: MatSnackBar, private dialog:MatDialog,
      private router: Router, private activatedRoute: ActivatedRoute,
      private userPipe: UserTypePipe,
      private cdRef:ChangeDetectorRef
    ) { }

  ngOnInit() {
    this.getActiveUsers();
    this.getInactiveUsers();
    this.getClients();
  }

  ngAfterViewInit(){
    this.checkTab();
  }

  checkTab(){
    this.activatedRoute.paramMap.subscribe(params => {
      var status = params.get('status')
      if(status == "active"){
        this.userTab.selectedIndex = 1;
      }
      else if(status == "inactive"){
        this.userTab.selectedIndex = 0;
      }
      else{
        this.userTab.selectedIndex = 1;
      }
    });
  }

  tabChanged(event){
    let userType = this.userPipe.transform(this.userKind)
    if(event.tab.textLabel == "Active User"){
      this.router.navigate([`app/${userType}/client-users/active`])
    }
    else if(event.tab.textLabel == "Inactive User"){
      this.router.navigate([`app/${userType}/client-users/inactive`])

    }
  }

  setDefaultActivePaginatorItems(){
    if(this.activeSearchUser == null || this.activeSearchUser == ""){
      this.currentItemsToShowActive = this.activeUsers.slice(0, this.defaultRecords)
    }
    else{
      this.currentItemsToShowActive = this.filteredActiveUsers.slice(0, this.defaultRecords)
    }
  }

  setDefaultInActivePaginatorItems(){
    if(this.activeSearchUser == null || this.activeSearchUser == ""){
      this.currentItemsToShowInActive = this.inactiveUsers.slice(0, this.defaultRecords)
    }
    else{
      this.currentItemsToShowInActive = this.filteredInactiveUsers.slice(0, this.defaultRecords)
    }
  }

  onPageChangeActive($event) {
    if(this.activeSearchUser){
      this.currentItemsToShowActive = this.filteredActiveUsers.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
    }
    else{
      this.currentItemsToShowActive = this.activeUsers.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
    }
  }

  onPageChangeInactive($event) {
    if(this.inactiveSearchUser){
      this.currentItemsToShowActive = this.filteredInactiveUsers.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
    }
    else{
      this.currentItemsToShowActive = this.inactiveUsers.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
    }
  }

  toggleAddingNew(){
    this.addingNew= !this.addingNew
  }

  newUserAdded(data){
    this.toggleAddingNew();
    this.getActiveUsers();
  }

  filterActivebyClient(){
    if(this.clientToSelect == "All Clients"){
      this.currentItemsToShowActive = this.activeUsers.slice(0, this.defaultRecords)
    }
    else{
      const clientFilter = this.activeUsers.filter( user => user.parentId == this.clientToSelect);
      this.currentItemsToShowActive = clientFilter.slice(0, this.defaultRecords);
    }
  }

  filterInActivebyClient(){
    if(this.clientToSelectInactive == "All Clients"){
      this.currentItemsToShowActive = this.inactiveUsers.slice(0, this.defaultRecords)
    }
    else{
      const clientFilter = this.inactiveUsers.filter( user => user.parentId == this.clientToSelectInactive);
      this.currentItemsToShowActive = clientFilter.slice(0, this.defaultRecords);
    }
  }

  filterActiveUser(){
    this.filteredActiveUsers = []
    this.activeUsers.forEach( user => {
      const lowerUser = user.contact.toLowerCase()
      if(lowerUser.includes(this.activeSearchUser.toLowerCase())){
        this.filteredActiveUsers.push(user);
      }
    });
    this.setDefaultActivePaginatorItems();
  }

  filterInActiveUser(){
    this.filteredInactiveUsers = [];
    this.inactiveUsers.forEach( user => {
      const lowerUser = user.contact.toLowerCase()
      if(lowerUser.includes(this.inactiveSearchUser.toLowerCase())){
        this.filteredInactiveUsers.push(user);
      }
    });
    this.setDefaultActivePaginatorItems();
  }


  async getClients(){
    const data = await this.clientService.getClientUnderProvider();
    this.clients = this.clients.concat(plainToClass(Client, data));
  }

  async getInactiveUsers(){
    const progressDialog = this.dialog.open(ProgressAlertComponent, {
      data: {
        title: 'Loading...'
      }
    });
    try{
      this.inactiveUsers = [];
      const data = await this.usersService.getProviderUsers("inactive");
      this.inactiveUsers = this.inactiveUsers.concat(plainToClass(User, data));
      this.setDefaultInActivePaginatorItems();
      progressDialog.close();
    }
    catch (e){
      progressDialog.close();
      this.matSnackBar.open('Something went wrong');
    }
  }

  async getActiveUsers(){
    const progressDialog = this.dialog.open(ProgressAlertComponent, {
      data: {
        title: 'Loading...'
      }
    });
    try{
      this.activeUsers = [];
      const data = await this.usersService.getProviderUsers("active");
      this.activeUsers = this.activeUsers.concat(plainToClass(User, data));
      this.setDefaultActivePaginatorItems()
      progressDialog.close();
    }
    catch (e){
      progressDialog.close();
      this.matSnackBar.open('Something went wrong');
    }
  }

}
