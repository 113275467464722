export const Statuses: any = [
  {
    value: '0' ,
    name:'All Statuses'
  },
  {
    value: '1' ,
    name:'Completed'
  },
  {
    value: '2' ,
    name:'Error'
  },
  {
    value: '3' ,
    name:'Rejected'
  },
  {
    value: '4' ,
    name:'Requested'
  }

]


