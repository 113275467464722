<div class="p-a-10">
  <form #editReportForm="ngForm">
    <mat-divider class="m-t-10 m-b-10"></mat-divider>
    <div class="row">
      <div class="col">
        <input class="form-control" type="text" [(ngModel)]="report.name" requrired name="name">
      </div>
    </div>
    <div class="row m-t-10 m-b-10">
      <div class="col">
        <label class="i-checks i-checks-sm m-r-10">
          <input type="checkbox" id="cbRememberMe" [(ngModel)]="report.userOnly" name="userOnly">
          <i></i>
          My Report
        </label>
        <label class="i-checks i-checks-sm">
          <input type="checkbox" id="cbRememberMe" [(ngModel)]="report.options" name="options">
          <i></i>
          Include Totals
        </label>
        <!-- <mat-checkbox [(ngModel)]="report.userOnly" color="primary" name="userOnly">My Report</mat-checkbox> -->
        <!-- <mat-checkbox [(ngModel)]="report.options"  color="primary" name="options">Include Totals</mat-checkbox> -->
      </div>
    </div>
    <span>Fields: </span>
    <div class="reportOptions">
      <mat-grid-list cols="6" rowHeight="30px">
        <mat-grid-tile *ngFor="let option of reportOptions">
          <label class="i-checks i-checks-sm">
            <input type="checkbox" id="cbRememberMe" [(ngModel)]="option.select" [name]="option.displayName">
            <i></i>
            {{option.displayName}}
          </label>
          <!-- <mat-checkbox [(ngModel)]="option.select" [name]="option.displayName" color="primary">{{option.displayName}}</mat-checkbox> -->
        </mat-grid-tile>
      </mat-grid-list>
    </div>
    <mat-divider class="m-t-10 m-b-10"></mat-divider>
    <div class="text-center">
      <button mat-flat-button [disabled]="editReportForm?.invalid && !editReportForm?.dirty" class="btn-primary pl m-r-10" (click)="saveReport()">SAVE</button>
      <button mat-flat-button color="secondary" (click)="closeViewFunc()">CANCEL</button>
    </div>
  </form>
</div>
