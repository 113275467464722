<div class="contentContainer">
  <div class="ordersInbox" >
    <div class="row m-b-20">
      <div class="col"></div>
      <div class="col-2 clickable" (click)="advSearch = !advSearch">
        <div style="float: right;">
          Advanced search
          <span class="fa-stack text-sm" >
            <i class="fa fa-circle-thin fa-stack-2x"></i>
            <i class="fa fa-stack-1x fa-caret-down" [ngClass]="{'indicator_collapsed':!advSearch , 'indicator_expanded':advSearch}"></i>
          </span>
        </div>
      </div>
      <div class="expandable_content" [ngClass]="{'content_collapsed':!advSearch , 'content_expanded':advSearch}">

        <mat-divider class="m-t-10 m-b-10"></mat-divider>
        <div class="row m-t-10">
          <div class="col">First Name</div>
          <div class="col">Middle Name</div>
          <div class="col">Last Name</div>
        </div>
        <div class="row m-t-5">
          <div class="col-md-4">
              <input id="firstName" name="firstName" type="search" class="form-control" placeholder="First Name" [(ngModel)]="firstNameSearch"/>
          </div>
          <div class="col-md-4">
              <input id="middleName" name="middleName" type="search" class="form-control" placeholder="Middle Name" [(ngModel)]="middleNameSearch"/>
          </div>
          <div class="col-md-4">
              <input id="lastName" name="lastName" type="search" class="form-control" placeholder="Last Name"  [(ngModel)]="lastNameSearch"/>
          </div>
        </div>
        <div class="row m-t-10">
          <div class="col">Originated Date Range</div>
          <div class="col">Reference Number</div>
          <div class="col"></div>
        </div>
        <div class="row m-t-5">
            <div class="col-md-4">
              <input type="text" ngxDaterangepickerMd readonly [ranges]="ranges" [locale]="{format: 'MM/DD/YYYY'}" [alwaysShowCalendars]="true" startKey="startFilterDate" endKey="endFilterDate" [autoApply]="true" [(ngModel)]="filterDate"  class="dateRangeInput form-control" placeholder="Originated Date Range">
            </div>
            <div class="col-md-4">
                <input id="refNum" name="refNum" type="search" class="form-control" placeholder="Reference Number" [(ngModel)]="refNumSearch"/>
            </div>

            <div class="col-md-4">
              <label class="i-checks i-checks-sm">
                <input type="checkbox" id="cbRememberMe" name="archived" [(ngModel)]="archivedSearch">
                <i></i>
                Archived
              </label>
            </div>

        </div>
        <div class="m-t-10 m-b-10 text-right" style="text-align: right;" >
          <button class="btn pl btn-primary" type="button"  (click)="filterOrders()">Apply</button>
          <button class="btn btn-neutral m-l-10" type="button"  (click)="clearFilterOrders()">Clear</button>
        </div>
      </div>
    </div>
    <div class="row clear-fix m-t-10" >
      <div class="col">
        <h2 style="position: relative">Inbox <span class="badge pl bg-primary alert_indicator" >{{requests?.length}}</span></h2 >
      </div>
      <div class="col-2 justify-content-end">
        <div >
          <mat-select class="m-r-10 form-control" [(ngModel)]="statusSearch" style="width: 200px;" (ngModelChange)="filterStatus()">
            <mat-option [value]="'All Inquiries'">All Inquiries</mat-option>
            <mat-option [value]="'Not Pre-Qualified'">Not Pre-Qualified</mat-option>
            <mat-option [value]="'Error'">Error</mat-option>
            <mat-option [value]="'Pre-Qualified'">Pre-Qualified</mat-option>
          </mat-select>
        </div>
      </div>
      <div class="col-2">
        <input class="form-control" type="text" placeholder="Search Last Name" [(ngModel)]="searchText" (ngModelChange)="filterRequests()">
      </div>
    </div>
    <mat-divider></mat-divider>
   <div *ngIf="getOrdersLength()">
      <div *ngFor="let request of currentItemsToShow; let i = index" class="row g-0 m-t-10 br-5"
      [ngClass]="{'filled':request.contactAllowed || element == request, 'greyed':!request.contactAllowed, }"
      style="min-height: 50px; border-radius: 5px; overflow: hidden;">
        <div class="col-md-2 pl bg-primary txt-white r-l rowleft"  *ngIf="userKind != 'Originator'">
          <i class="fa fa-user m-t-xs p-r-5"></i>  {{request.originatorName}}
        </div>
        <div class="col-md-10 row g-0 p-a-10 bg-white clickable itemCenterVertical" [ngClass]="{'col-md-10': userKind != 'Originator', }" (click)="element = (element === request ? null : request)">
          <div class="col-3"> {{request.firstName}} {{request.lastName}}</div>
          <div class="col-md-2">{{request.loanTypeName}}</div>
          <div class="col-md-2">Ref #: {{request.refNum}}</div>
          <div class="col-md-2" >{{request.dateRequested | date:'MM/dd/YYYY'}}</div>
          <div class="col-md-2">
              <i class="fa fa-archive" *ngIf="request.archived" matTooltip="Archived"></i>
              <span [ngClass]="{'text-danger':!request.prequalified, 'text-success':request.prequalified}">{{getPrequalifiedText(request)}}</span>
          </div>
          <div class="col-md-1">
            <span class="fa-stack pull-right text-sm">
              <i class="fa fa-circle-thin fa-stack-2x"></i>
              <i class="fa fa-stack-1x fa-caret-down" [ngClass]="{'indicator_collapsed':element != request, 'indicator_expanded':element == request}"></i>
            </span>
          </div>

        </div>
        <div class="row g-0 " *ngIf="element == request">
          <div class="col-md-2 pl bg-primary">&nbsp;</div>
          <div class="col p-a-10  bg-white">
            <app-inbox-order-details [request]="request" (removedFromInbox)="removedRequestFromInbox($event)"></app-inbox-order-details>
          </div>
        </div>
      </div>
      </div>
    <mat-divider></mat-divider>

    <mat-paginator class="m-t-10" [length]="requests?.length" (page)="onPageChange($event)" [pageSize]="10" [showFirstLastButtons]="true" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page"></mat-paginator>
  </div>
</div>
