export class Profile {
  Id: string;
  address: string;
  branding :string;
  cell: string;
  city: string;
  code: string;
  companyName: string;
  completionMessage: string;
  contact: string;
  dateCreated: string;
  dateLastLogon: string;
  dateModified: string;
  email: string;
  hierarchicalCompletionMessage: string;
  hierarchicalRejectMessage: string;
  hierarchicalWelcomeMessage: string;
  kind: string;
  logo: string;
  notificationEmail: string;
  parentId: string;
  password: string;
  phone: string;
  productSetup: string;
  providerId: string;
  rejectMessage: string;
  securityCookie: string;
  state: string;
  status: string;
  url: string;
  userName: string;
  welcomeMessage: string;
  xolCredentials: string;
  zip: string;

}
