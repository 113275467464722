<form [formGroup]="newUserForm">
  <div class="userNewItem" >
    <!-- <div class="row m-t-10">
      <div class="col">Client: <span style="color: red;">*</span></div>
      <div class="col">Type: <span style="color: red;">*</span></div>
    </div>
    <div class="row m-t-5">
      <div class="col"><input class="form-control" formControlName="client" placeholder="Client"></div>
      <div class="col"><input class="form-control" formControlName="email" placeholder="Email"></div>
    </div> -->
    <div class="row m-t-10">
      <div class="col">Name: <span style="color: red;">*</span></div>
      <div class="col">Email: <span style="color: red;">*</span></div>
      <div class="col">Phone: <span style="color: red;">*</span></div>
      <div class="col"><i class="fa-solid fa-circle-info" matTooltip="If provided, this phone number will receive text notifications for new inquiries." [matTooltipPosition]="'above'"></i> Cell:</div>
    </div>
    <div class="row m-t-5">
      <div class="col"><input class="form-control" formControlName="contact" placeholder="Name"></div>
      <div class="col"><input class="form-control" formControlName="email" placeholder="Email"></div>
      <div class="col"><input class="form-control" mask="(000) 000-0000" formControlName="phone" placeholder="(xxx) xxx-xxxx"></div>
      <div class="col"><input class="form-control" mask="(000) 000-0000" formControlName="cell" placeholder="(xxx) xxx-xxxx"></div>
    </div>
    <div class="row m-t-20">
      <div class="col">Username: <span style="color: red;">*</span></div>
      <div class="col">Password: <span style="color: red;">*</span></div>
      <div class="col">Confirm Password: <span style="color: red;">*</span></div>
    </div>
    <div class="row m-t-5">
      <div class="col"><input class="form-control" formControlName="userName" placeholder="Username"></div>
      <div class="col">
        <input class="form-control" type="password"  formControlName="password" placeholder="Password">
        <div class="text-danger" *ngIf="newUserForm.controls['password'].dirty && newUserForm.controls['password'].invalid">
          <small class="text-danger" *ngIf="newUserForm.controls['password'].hasError('pattern')">
            Invalid password format. Passwords must be at least 8 characters in length, with at least one of
            each of the following: digit, lowercase letter, uppercase letter, and non-letter/non-digit.
          </small>
          <small class="text-danger" *ngIf="newUserForm.controls['password'].hasError('required')">
              Password is required.
          </small>
        </div>
      </div>
      <div class="col">
        <input class="form-control" type="password" formControlName="matchPassword" placeholder="Confirm Password">
        <small class="text-danger" *ngIf="newUserForm.controls['matchPassword'].dirty && newUserForm.errors?.['PasswordNoMatch']">
          Passwords don't match.
        </small>
        <div class="text-danger" *ngIf="newUserForm.controls['matchPassword'].dirty && newUserForm.controls['matchPassword'].invalid">
          <small class="text-danger" *ngIf="newUserForm.controls['matchPassword'].hasError('required')">
            Confirm password is required.
          </small>
        </div>
      </div>
    </div>
    <div class="row m-t-20">
      <div class="col">Type: <span style="color: red;">*</span></div>
      <div class="col" *ngIf="userKind != 'ProviderUser'">Loan Types:</div>
      <div class="col" *ngIf="userKind == 'ProviderUser'">Client: <span style="color: red;">*</span></div>
    </div>
    <div class="row m-t-5">
      <div class="col">
        <mat-select placeholder="Type" formControlName="kind">
          <mat-option [value]="'Administrator'">Administrator</mat-option>
          <mat-option [value]="'Supervisor'">Supervisor</mat-option>
          <mat-option [value]="'Originator'">Originator</mat-option>
        </mat-select>
      </div>
      <div class="col" *ngIf="userKind != 'ProviderUser'">
        <mat-select  placeholder="Select Loan Types..." formControlName="selectedLoanTypes" multiple disableRipple>
          <mat-option *ngFor="let item of loanType" [value]="item">{{item.description}}</mat-option>
        </mat-select>
      </div>
      <div class="col" *ngIf="userKind == 'ProviderUser'">
        <mat-select placeholder="Client" formControlName="parentId">
         <mat-option *ngFor="let item of clients" [value]="item.Id">{{item.companyName}}</mat-option>
        </mat-select>
      </div>
    </div>
    <mat-divider class="m-t-10 m-b-10"></mat-divider>
    <div class="row">
      <div class="col d-flex justify-content-center">
        <button mat-flat-button class="m-r-10" color="secondary" (click)="closeNewUser()">CANCEL</button>
        <button mat-flat-button class="btn-primary pl" color="primary" [disabled]="newUserForm?.invalid" (click)="addNewUser()">CREATE USER</button>
      </div>
    </div>
  </div>
</form>
