<div class="userProviderSetup">
  <div class="">
    <span class="fa-stack addButtonPosition text-lg clickable" (click)="toggleAddingNew()">
      <i class="fa fa-circle fa-stack-2x pl" [ngClass]="{'text-danger':addingNew, 'text-primary':!addingNew}"></i>
      <i class="fa fa-stack-1x fa-inverse" [ngClass]="{'fa-plus':!addingNew, 'fa-minus':addingNew}"></i>
    </span>
  </div>
  <div class="m-b-10" *ngIf="addingNew">
    <h4>New User</h4>
    <mat-divider class="m-t-10 m-b-10"></mat-divider>
    <app-user-setup-new [clients]="clients" (addedNewUser)="newUserAdded($event)" (cancelNewUser)="toggleAddingNew()"></app-user-setup-new>
  </div>
  <mat-tab-group [disableRipple]="true" mat-align-tabs="start" mat-stretch-tabs="false" (selectedTabChange)="tabChanged($event)" #userTab>
    <mat-tab label="Inactive User">
      <div class="UsersContainer p-a-10">
        <div class="row m-t-10">
         <div class="col">
            <h2>Inactive Users <span class="badge pl bg-primary" style="font-size: 12px;">{{inactiveUsers?.length}}</span></h2>
          </div>
          <div class="col-3"></div>
          <div class="col-2">
            <input type="text" class="form-control" placeholder="SEARCH" [(ngModel)]="inactiveSearchUser" (ngModelChange)="filterInActiveUser()">
          </div>
          <div class="col">
            <span class="p-r-10">Filter Users by Client:</span>
            <mat-select [(ngModel)]="clientToSelectInactive" style="width: 50%;"  class="form-control" placeholder="Select Client" (ngModelChange)="filterInActivebyClient()">
              <mat-option [value]="'All Clients'">All Clients</mat-option>
              <mat-option *ngFor="let item of clients" [value]="item.companyName">{{item.companyName}}</mat-option>
            </mat-select>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="m-t-10" *ngFor="let user of inactiveUsers">
          <app-user-setup-item [isProviderSetup]="false" [user]="user"></app-user-setup-item>
        </div>
        <mat-paginator *ngIf="inactiveUsers == null" [length]="inactiveUsers?.length" (page)="onPageChangeActive($event)" [pageSize]="10" [showFirstLastButtons]="true" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </mat-tab>
    <mat-tab label="Active User">
      <div class="UsersContainer p-a-10">
        <div class="row m-t-20">
         <div class="col">
            <h2>Active Users  <span class="badge pl bg-primary" style="font-size: 12px;">{{activeUsers?.length}}</span></h2>
          </div>
          <div class="col-2"></div>
          <div class="col-2">
            <input type="text" class="form-control" placeholder="SEARCH" [(ngModel)]="activeSearchUser" (ngModelChange)="filterActiveUser()">
          </div>
          <div class="col">
            <span class="p-r-10">Filter Users by Client:</span>
            <mat-select [(ngModel)]="clientToSelect" style="width: 50%;" class="form-control"  placeholder="Select Client" (ngModelChange)="filterActivebyClient()">
              <mat-option [value]="'All Clients'">All Clients</mat-option>
              <mat-option *ngFor="let item of clients" [value]="item.Id">{{item.companyName}}</mat-option>
            </mat-select>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="m-t-10" *ngFor="let user of currentItemsToShowActive">
          <app-user-setup-item [isProviderSetup]="false" [user]="user"></app-user-setup-item>
        </div>
        <mat-paginator class="m-t-10" [length]="activeUsers?.length" (page)="onPageChangeActive($event)" [pageSize]="10" [showFirstLastButtons]="true" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
