<div class="providerSetup">
  <form [formGroup]="newProviderForm">
    <div class="row no-gutters">
      <div class="col">
        <input type="text" class="form-control" placeholder="Company Name" formControlName="companyName" maxlength="50">
      </div>
      <div class="col">
      </div>
      <div class="col d-flex justify-content-end">
        <button mat-flat-button class="m-r-10" color="secondary" (click)="closeNewProvider()">CANCEL</button>
        <button mat-flat-button class="btn-primary pl" [disabled]="newProviderForm.invalid" (click)="addNewProvider()">SAVE</button>
      </div>
    </div>
    <mat-divider class="m-t-10 m-b-10"></mat-divider>
    <div class="row m-t-10">
      <div class="col">Phone: <span style="color: red;">*</span></div>
      <div class="col">Email: <span style="color: red;">*</span></div>
      <div class="col">Contact:</div>
    </div>
    <div class="row m-t-5">
      <div class="col"><input class="form-control" mask="(000) 000-0000" placeholder="Phone" formControlName="phone"> </div>
      <div class="col"><input class="form-control" placeholder="Email"  formControlName="email" maxlength="50"></div>
      <div class="col"><input class="form-control" placeholder="Contact"  formControlName="contact" maxlength="50"></div>
    </div>
    <div class="row m-t-10">
      <div class="col-5">Address: <span style="color: red;">*</span></div>
      <div class="col-2">Zip: <span style="color: red;">*</span></div>
      <div class="col">City: <span style="color: red;">*</span></div>
      <div class="col-2">State: <span style="color: red;">*</span></div>
    </div>
    <div class="row m-t-5">
      <div class="col-5"><input class="form-control" placeholder="Address"  formControlName="address" maxlength="50"> </div>
      <div class="col-2"><input class="form-control" placeholder="Zip" (change)="zipcodeLookUp(newProviderForm.value.zip)" formControlName="zip" maxlength="10"></div>
      <div class="col"><input class="form-control" placeholder="City"  formControlName="city" maxlength="30"></div>
      <div class="col-2">
        <mat-select class="form-control" formControlName="state" placeholder="State">
          <mat-option [value]="item.abbreviation" *ngFor="let item of statesList">{{item.abbreviation}}</mat-option>
        </mat-select>
      </div>
    </div>
    <mat-divider class="m-t-20 m-b-10"></mat-divider>
    <div class="row m-t-20 m-l-2">
      <div class="col">
        <h5><i class="fa fa-user m-t-xs m-r-5"></i> Provider User</h5>
      </div>
    </div>
    <div class="p-a-10">
      <div class="row m-t-10">
        <div class="col">Name: <span style="color: red;">*</span></div>
        <div class="col">Email: <span style="color: red;">*</span></div>
        <div class="col">Phone: <span style="color: red;">*</span></div>
        <div class="col">Cell:</div>
      </div>
      <div class="row m-t-5">
        <div class="col"><input class="form-control" formControlName="userContact" placeholder="Name" maxlength="50"></div>
        <div class="col"><input class="form-control" formControlName="userEmail" placeholder="Email" maxlength="50"></div>
        <div class="col"><input class="form-control" formControlName="userPhone" mask="(000) 000-0000" placeholder="Phone"></div>
        <div class="col"><input class="form-control" formControlName="userCell" mask="(000) 000-0000" placeholder="Cell"></div>
      </div>
      <div class="row m-t-20">
        <div class="col">Username: <span style="color: red;">*</span></div>
        <div class="col">Password: <span style="color: red;">*</span></div>
        <div class="col">Confirm Password: <span style="color: red;">*</span></div>
      </div>
      <div class="row m-t-5">
        <div class="col"><input class="form-control" formControlName="userName" placeholder="Username"></div>
        <div class="col"><input class="form-control" type="password" formControlName="password" placeholder="Password">
          <div class="text-danger" *ngIf="newProviderForm.controls['password'].dirty && newProviderForm.controls['password'].invalid">
            <small class="text-danger" *ngIf="newProviderForm.controls['password'].hasError('pattern')">
              Invalid password format. Passwords must be at least 8 characters in length, with at least one of
              each of the following: digit, lowercase letter, uppercase letter, and non-letter/non-digit.
            </small>
            <small class="text-danger" *ngIf="newProviderForm.controls['password'].hasError('required')">
                Password is required.
            </small>
          </div>
        </div>
        <div class="col"><input class="form-control" type="password" formControlName="matchPassword" placeholder="Confirm Password">

          <small class="text-danger" *ngIf="newProviderForm.controls['matchPassword'].dirty && newProviderForm.errors?.['PasswordNoMatch']">
              Passwords don't match.
            </small>
          <div class="text-danger" *ngIf="newProviderForm.controls['matchPassword'].dirty && newProviderForm.controls['matchPassword'].invalid">
            <small class="text-danger" *ngIf="newProviderForm.controls['matchPassword'].hasError('required')">
              Confirm password is required.
            </small>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
