import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { APIInterceptor } from './services/api.interceptor';
import { InboxOrdersComponent } from './inbox-orders/inbox-orders.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { TopnavComponent } from './topnav/topnav.component';
import { ClientSetupComponent } from './client-setup/client-setup.component';
import { ProductSetupComponent } from './product-setup/product-setup.component';
import { UserSetupComponent } from './user-setup/user-setup.component';
import { TokenInterceptor } from './services/token.interceptor';
import { AlertsComponent } from './components/dialogs/alerts/alerts.component';
import { ProgressAlertComponent } from './components/dialogs/progress-alert/progress-alert.component';
import { InboxOrderDetailsComponent } from './inbox-orders/inbox-order-details/inbox-order-details.component';
import { ToolsService } from './services/tools-service';
import { ReportsComponent } from './reports/reports.component';
import { UserprofileSettingsComponent } from './userprofile-settings/userprofile-settings.component';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask, provideNgxMask } from 'ngx-mask';
import { ReportEditComponent } from './reports/report-edit/report-edit.component';
import { ReportViewComponent } from './reports/report-view/report-view.component';
import { ReportNewComponent } from './reports/report-new/report-new.component';
import { JwtInterceptor } from './services/jwt.interceptor';
import { ClientSetupProviderComponent } from './client-setup/client-setup-provider/client-setup-provider.component';
import { HideSpanPipe } from './components/pipes/hideSpanPipe';
import { ClientSetupItemComponent } from './client-setup/client-setup-item/client-setup-item.component';
import { ClientStatusPipe } from './components/pipes/clientStatusPipe';
import { NgxColorsModule } from 'ngx-colors';
import { ProductSetupCheckPipe } from './components/pipes/productSetupCheck';
import { ClientSetupNewComponent } from './client-setup/client-setup-new/client-setup-new.component';
import { UserSetupProviderComponent } from './user-setup/user-setup-provider/user-setup-provider.component';
import { UserSetupAdminComponent } from './user-setup/user-setup-admin/user-setup-admin.component';
import { UserSetupItemComponent } from './user-setup/user-setup-item/user-setup-item.component';
import { UserSetupNewComponent } from './user-setup/user-setup-new/user-setup-new.component';
import { ProductSetupItemComponent } from './product-setup/product-setup-item/product-setup-item.component';
import { NgIdleModule } from '@ng-idle/core';
import { ProductSetupNewComponent } from './product-setup/product-setup-new/product-setup-new.component';
import { ProviderSetupComponent } from './provider-setup/provider-setup.component';
import { ProviderSetupNewUserComponent } from './provider-setup/provider-setup-new-user/provider-setup-new-user.component';
import { ConsumerComponent } from './consumer/consumer.component';
import { WebAddressService } from './services/webaddress.service';
import { CommonModule } from '@angular/common';
import { ConsumerAgreementComponent } from './consumer/consumer-agreement/consumer-agreement.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaFormsModule, RecaptchaModule, RecaptchaV3Module } from "ng-recaptcha";
import { ConsumerSelectedProductComponent } from './consumer/consumer-selected-product/consumer-selected-product.component';
import { ConsumerFinishComponent } from './consumer/consumer-finish/consumer-finish.component';
import { ConsumerTermsConditionsComponent } from './consumer/consumer-terms-conditions/consumer-terms-conditions.component';
import { ConsumerPreviewComponent } from './consumer/consumer-preview/consumer-preview.component';
import { ProviderSetupManagerComponent } from './provider-setup/provider-setup-manager/provider-setup-manager.component';
import { ProviderSetupMainComponent } from './provider-setup/provider-setup-main/provider-setup-main.component';
import { ProviderSetupNewProviderComponent } from './provider-setup/provider-setup-new-provider/provider-setup-new-provider.component';
import { DragAndDropDirective } from './components/directive/drag-drop.directive';
import {  FileUploadModule } from 'ng2-file-upload';
import { UserTypePipe } from './components/pipes/userTypePipe';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ConsumerContactComponent } from './consumer/consumer-contact/consumer-contact.component';
import { MomentPipe } from './components/pipes/momentPipe';
import { ResetpwComponent } from './resetpw/resetpw.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    InboxOrdersComponent,
    SidenavComponent,
    TopnavComponent,
    ClientSetupComponent,
    ProductSetupComponent,
    ProductSetupNewComponent,
    UserSetupComponent,
    UserSetupProviderComponent,
    UserSetupAdminComponent,
    UserSetupItemComponent,
    UserSetupNewComponent,
    AlertsComponent,
    ProgressAlertComponent,
    InboxOrderDetailsComponent,
    ReportsComponent,
    UserprofileSettingsComponent,
    ReportEditComponent,
    ReportViewComponent,
    ReportNewComponent,
    ClientSetupProviderComponent,
    ClientSetupItemComponent,
    ClientSetupNewComponent,
    ClientStatusPipe,
    HideSpanPipe,
    ProductSetupCheckPipe,
    UserTypePipe,
    ProductSetupItemComponent,
    ProviderSetupComponent,
    ProviderSetupNewUserComponent,
    ProviderSetupManagerComponent,
    ProviderSetupMainComponent,
    ProviderSetupNewProviderComponent,
    ConsumerComponent,
    ConsumerAgreementComponent,
    ConsumerTermsConditionsComponent,
    ConsumerSelectedProductComponent,
    ConsumerFinishComponent,
    ConsumerPreviewComponent,
    ConsumerContactComponent,
    DragAndDropDirective,
    MomentPipe,
    ResetpwComponent
   ],
  imports: [
    BrowserModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    RecaptchaV3Module,
    AppRoutingModule,
    CommonModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxColorsModule,
    FileUploadModule,
    NgxDaterangepickerMd.forRoot(),
    NgIdleModule.forRoot(),
  ],
  providers: [
    { provide: "BASE_API_URL", useValue: environment.apiUrl },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: APIInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    // { provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LcALQ4UAAAAAOJo-YoRsYBc1zkn5OvLnd6Bc1Hn" },
    ToolsService,
    UserTypePipe,
    provideEnvironmentNgxMask(),
    provideNgxMask()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
