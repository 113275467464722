import { Injectable } from '@angular/core';
import { TokenService } from './token.service';
import { Router } from '@angular/router';
import { globalUser } from '../global/globaluser';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable({
  providedIn: 'root'
})
export class AuthRoutingService {

  constructor(private token:TokenService, private router:Router, private matSnackbar: MatSnackBar) { }

  canActivate():boolean{
    if(!this.token.isAuthenticated()){
      this.router.navigate(['/login']);
      return false;
    }
    if(globalUser.globalUserID == null){
      globalUser.setglobalUserID(localStorage.getItem('RqUserId'))
    }
    else if(globalUser.globalUserID != localStorage.getItem('RqUserId')){
      this.matSnackbar.open("Session expired. Refreshing.")
      this.router.navigate(['/login']);
      setTimeout(() => {window.location.reload()}, 1000);
      return false;
    }

    return true;
  }//End canActivate

}
