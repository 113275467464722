import { lastValueFrom } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { ReportOptions, Reports } from '../components/classes/Reports';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

constructor(private httpClient:HttpClient) { }

  async getOrderReport(id: string){
    return await lastValueFrom(this.httpClient.get('api/Request/' + id + '/Report', { observe: 'response', responseType: 'blob' as 'json'}));
  }

  async getReports(){
    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json');

    const data = await lastValueFrom(this.httpClient.get('api/Reports/GetReports', {headers: headers}));
    return plainToClass(Reports, data)
  }

  async getReportsOptions(){
    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json');

    const data = await lastValueFrom(this.httpClient.get('api/Reports/GetReportFields', {headers: headers}));
    return plainToClass(ReportOptions, data)
  }

  async postReport(report){
    return await lastValueFrom(this.httpClient.post("api/Reports/PostReport", report, {headers: {'Content-Type': 'application/json' }}));
  }

  async putReport (report){
    return  await lastValueFrom(this.httpClient.put("api/Reports/UpdateReport", report, {headers: {'Content-Type': 'application/json'}}));
  }

  async deleteReport (reportId){
    return await lastValueFrom(this.httpClient.put("api/Reports/UpdateReport", {"Id":reportId, "status": 2}, {headers: {'Content-Type': 'application/json'}}));
  }

  async runReport (reportId, runParams){
    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json');

    const data = await lastValueFrom(this.httpClient.post("api/Reports/RunReport/" + reportId, runParams, {
      headers: headers,
      observe: 'response',
      responseType: 'blob' as 'json'
    }));
    return data;
  }

}
