import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { plainToClass } from 'class-transformer';
import { Client } from 'src/app/components/classes/Client';
import { User } from 'src/app/components/classes/User';
import { States } from 'src/app/components/constants/UsStates';
import { ProgressAlertComponent } from 'src/app/components/dialogs/progress-alert/progress-alert.component';
import { ClientService } from 'src/app/services/client.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-provider-setup-main',
  templateUrl: './provider-setup-main.component.html',
  styleUrls: ['./provider-setup-main.component.scss']
})
export class ProviderSetupMainComponent implements OnInit {

  @Input() provider: Client;
  providerUsers: Array<User> = [];
  editingProvider: boolean;
  providerForm: FormGroup;
  subscriberID = localStorage.getItem("parentId") || null;
  addNewUser: boolean;
  element: any | null;
  userKind = localStorage.getItem("userKind") || null;
  statesList = States;


  constructor(private clientService:ClientService, private usersService:UsersService, private matSnackBar: MatSnackBar, private dialog:MatDialog) { }

  async ngOnInit() {
    if(!this.provider){
      await this.getProviderData();
      this.getProviderUsers();
      this.element = this.provider;
    }
    else{
      this.providerUsers = this.provider.users;
    }
    this.initializeForm();
  }

  initializeForm(){
    this.providerForm = new FormGroup({
      email: new FormControl(this.provider.email, {nonNullable: true, validators: [Validators.required, Validators.email]}),
      contact: new FormControl(this.provider.contact, {nonNullable: true}),
      phone: new FormControl(this.provider.phone, {nonNullable: true, validators: [Validators.required, Validators.pattern("^[0-9]*$")]}),
      companyName: new FormControl(this.provider.companyName, {nonNullable: true, validators: [Validators.required]}),
      zip: new FormControl(this.provider.zip, {nonNullable: true, validators: [Validators.required]},),
      state: new FormControl(this.provider.state, {nonNullable: true, validators: [Validators.required]},),
      city: new FormControl(this.provider.city, {nonNullable: true, validators: [Validators.required]},),
      address: new FormControl(this.provider.address, {nonNullable: true, validators: [Validators.required]},),
    })
  }

  cancelChanges(){
    this.providerForm.reset();
    this.editingProvider = false;
  }

  toggleNewProviderVisibility(){
    this.addNewUser = ! this.addNewUser;
  }

  async getProviderData(){
    const progressDialog = this.dialog.open(ProgressAlertComponent, {
      data: {
        title: 'Loading...'
      }
    });
    try{
      const data = await this.clientService.getProvider(this.subscriberID);
      this.provider = plainToClass(Client, data);
      progressDialog.close();
    }
    catch{
      progressDialog.close();
      this.matSnackBar.open('Something went wrong');
    }
  }

  async getProviderUsers(){
    const progressDialog = this.dialog.open(ProgressAlertComponent, {
      data: {
        title: 'Loading...'
      }
    });
    try{
      this.providerUsers = [];
      const data = await this.usersService.getProviderUsers("");
      this.providerUsers = this.providerUsers.concat(plainToClass(User, data));
      progressDialog.close();
    }
    catch{
      progressDialog.close();
      this.matSnackBar.open('Something went wrong');
    }

  }

  saveFormData(){
    this.provider.companyName = this.providerForm.value.companyName;
    this.provider.email = this.providerForm.value.email;
    this.provider.contact = this.providerForm.value.contact;
    this.provider.phone = this.providerForm.value.phone;
    this.provider.zip = this.providerForm.value.zip;
    this.provider.state = this.providerForm.value.state;
    this.provider.city = this.providerForm.value.city;
    this.provider.address = this.providerForm.value.address;
  }


  async saveProvider(){
    const progressDialog = this.dialog.open(ProgressAlertComponent, {
      data: {
        title: 'Saving...'
      }
    });
    try{
      this.saveFormData();
      if(this.userKind == "RootUser"){
        const data = await this.clientService.postRootProvider(this.provider);
      }
      else{
        const data = await this.clientService.postProvider(this.provider);
      }
      this.editingProvider = false;
      progressDialog.close();
    }
    catch{
      progressDialog.close();
      this.matSnackBar.open('Something went wrong');
    }
  }

  async newUserAdded(event){
    this.addNewUser = false;
    await this.getProviderUsers();
  }

}
