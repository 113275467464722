<div *ngIf="userKind != 'ProviderUser'">
  <div class="row">
    <div class="col">
      <h2>Client Setup</h2>
    </div>
  </div>
  <mat-divider class="m-t-10 m-b-10"></mat-divider>
</div>
<div *ngIf="client" class="clientSetup m-t-10">
  <form #clientForm="ngForm">
    <div class="row no-gutters itemCenterVertical">
      <div class="col">
        <span *ngIf="!editingClient">{{client.companyName}}</span><input class="form-control"  *ngIf="editingClient" required [(ngModel)]="client.companyName" name="companyName">
      </div>
      <div class="col">
        Client Code: {{client.code}}
      </div>
      <div class="col d-flex justify-content-end">
        <i class="fa fa-pull-right fa-pencil fa-lg clickable " (click)="startEdit()"
        tooltip="Edit" [hidden]="editingClient" style="line-height: 30px;"></i>
        <div [hidden]="!editingClient">
          <button mat-flat-button class="m-r-10" color="secondary" (click)="cancelClientEdit()">CANCEL</button>
          <button mat-flat-button class="pl btn-primary" color="primary" [disabled]="clientForm?.invalid && clientForm?.touched" (click)="saveClient()">SAVE</button>
        </div>
        <span class="fa-stack pull-right text-sm m-l-10" *ngIf="userKind == 'ProviderUser'">
          <i class="fa fa-circle-thin fa-stack-2x"></i>
          <i class="fa fa-stack-1x fa-caret-down" (click)="element = element === client ? null : client" [ngClass]="{'indicator_collapsed':element != client, 'indicator_expanded':element == client}"></i>
        </span>
      </div>
    </div>
    <div *ngIf="element">
      <mat-divider class="m-t-10 m-b-10"></mat-divider>
      <div *ngIf="userKind == 'ProviderUser'">
        <div class="row m-t-10">
          <div class="col"><i class="fa-solid fa-circle-info brandingPreview" matTooltip="Transparent png recommended" [matTooltipPosition]="'above'"></i> Logo:</div>
        </div>
        <div class="row m-t-10" *ngIf="!editingClient">
          <div class="col italic" > <span *ngIf="!client.logo.name">No Image Uploaded</span><img class="logo" [src]="logoURL" *ngIf="client.logo.url"></div>
        </div>
        <div class="m-t-10" *ngIf="editingClient">
          <div class="dropzone">
            <img class="logo m-r-10" [src]="logoURL"  *ngIf="client.logo.url">
            <button for="fileDropRef" class="fileInput m-r-10"  *ngIf="!client.logo.url">Drag File Attachment Here</button>
            <button for="fileDropRef" class="outlineButton">Upload File</button>
            <input type="file" ng2FileSelect [uploader]="uploader" (onFileSelected)="handleFiles($event)"/>
          </div>
        </div>
        <div class="row m-t-10" *ngIf="client.logo.name">
          <div class="col">
            <label class="i-checks i-checks-sm">
              <input type="checkbox" id="cbRememberMe" [disabled]="!editingClient" [(ngModel)]="client.logo.showOnConsumer" name="showOnConsumer">
              <i></i>
              Show Logo on Consumer App
            </label><i class="fa-solid fa-circle-info brandingPreview m-l-10" [matTooltip]="showLogoMessage" [matTooltipPosition]="'above'"></i>
          </div>
        </div>
      </div>
      <div class="row m-t-10">
        <div class="col">Address:</div>
        <div class="col">City:</div>
        <div class="col">State:</div>
        <div class="col">Zip:</div>
      </div>
      <div class="row m-t-5">
        <div class="col"><span *ngIf="!editingClient">{{client.address}}</span><input class="form-control"  *ngIf="editingClient" required [(ngModel)]="client.address" name="address">  </div>
        <div class="col"><span *ngIf="!editingClient">{{client.city}}</span> <input class="form-control"  *ngIf="editingClient" required [(ngModel)]="client.city" name="city"></div>
        <div class="col">
          <span *ngIf="!editingClient">{{client.state}}</span>
          <mat-select *ngIf="editingClient" class="form-control" required [(ngModel)]="client.state" name="state" placeholder="State">
            <mat-option [value]="item.abbreviation" *ngFor="let item of statesList">{{item.abbreviation}}</mat-option>
          </mat-select>
        </div>
        <div class="col"><span *ngIf="!editingClient">{{client.zip}}</span> <input class="form-control"  *ngIf="editingClient" required [(ngModel)]="client.zip" name="zip"></div>
      </div>
      <div class="row m-t-10">
        <div class="col">Phone:</div>
        <div class="col">Email:</div>
        <div class="col"></div>
        <div class="col">Status:</div>
      </div>
      <div class="row m-t-5">
        <div class="col"><span *ngIf="!editingClient">{{client.phone | mask: '(000) 000-0000'}}</span> <input class="form-control" mask="(000) 000-0000" *ngIf="editingClient" required [(ngModel)]="client.phone" name="phone"></div>
        <div class="col"><span *ngIf="!editingClient">{{client.email}}</span> <input class="form-control"   *ngIf="editingClient" email required [(ngModel)]="client.email" name="email"></div>
        <div class="col"></div>
        <div class="col">
          <span *ngIf="!editingClient">{{client.status | clientStatus}}</span>
          <mat-select class="form-control"  *ngIf="editingClient" [(ngModel)]="client.status" name="status">
            <mat-option [value]="1">Active</mat-option>
            <mat-option [value]="3">Inactive</mat-option>
            <mat-option [value]="2">Terminated</mat-option>
          </mat-select>
        </div>
      </div>
      <mat-divider class="m-t-10 m-b-10"></mat-divider>
      <div class="row m-t-10">
        <div class="col"><i class="fa-solid fa-circle-info brandingPreview" matTooltip="Header and Footer Background Colors" [matTooltipPosition]="'above'"></i> Primary Color:</div>
        <div class="col"><i class="fa-solid fa-circle-info brandingPreview" matTooltip="Selected Tab Background Color, Header Text Color, Button Background Color" [matTooltipPosition]="'above'"></i> Secondary Color:</div>
        <div class="col"><i class="fa-solid fa-circle-info brandingPreview" matTooltip="Unselected Tab Background Color and Category Heading Text Color" [matTooltipPosition]="'above'"></i> Tertiary Color:</div>
        <div class="col d-flex justify-content-end"><button mat-flat-button class="pl btn-primary" color="primary" (click)="previewBranding()"><i class="fa-solid fa-magnifying-glass"></i> Preview Branding</button></div>
      </div>
      <div class="row m-t-5">
        <div class="col">
          <div *ngIf="!editingClient">
            <i class="fa-solid fa-square" [style.color]="parsedBranding.HeaderBackgroundColor"></i> {{parsedBranding.HeaderBackgroundColor}}
          </div>
          <div *ngIf="editingClient" class="d-flex">
            <ngx-colors ngx-colors-trigger name="headerBackgroundColor" [(ngModel)]="parsedBranding.HeaderBackgroundColor" [colorPickerControls]="'no-alpha'" [hideTextInput]="true"></ngx-colors>
            <span class="m-t-5">{{parsedBranding.HeaderBackgroundColor}}</span>
          </div>
        </div>
        <div class="col">
          <div *ngIf="!editingClient">
            <i class="fa-solid fa-square" [style.color]="parsedBranding.SelectedTabBackgroundColor"></i> {{parsedBranding.SelectedTabBackgroundColor}}
          </div>
          <div *ngIf="editingClient" class="d-flex">
            <ngx-colors ngx-colors-trigger name="selectedTabBackgroundColor" [(ngModel)]="parsedBranding.SelectedTabBackgroundColor" [colorPickerControls]="'no-alpha'" [hideTextInput]="true"></ngx-colors>
            <span class="m-t-5">{{parsedBranding.SelectedTabBackgroundColor}}</span>
          </div>
        </div>
        <div class="col">
          <div *ngIf="!editingClient">
            <i class="fa-solid fa-square" [style.color]="parsedBranding.UnselectedTabBackgroundColor"></i> {{parsedBranding.UnselectedTabBackgroundColor}}
          </div>
          <div *ngIf="editingClient" class="d-flex">
            <ngx-colors ngx-colors-trigger name="unselectedTabBackgroundColor" [(ngModel)]="parsedBranding.UnselectedTabBackgroundColor" [colorPickerControls]="'no-alpha'" [hideTextInput]="true"></ngx-colors>
            <span class="m-t-5">{{parsedBranding.UnselectedTabBackgroundColor}}</span>
          </div>
        </div>
        <div class="col"></div>
      </div>
      <div *ngIf="userKind == 'ProviderUser'">
        <mat-divider class="m-t-10 m-b-10"></mat-divider>
        <div class="row">
          <div class="col">
            XpertOnline
          </div>
        </div>
        <div class="row m-t-10">
          <div class="col" >Provider ID:<span *ngIf="!editingClient"> {{client.xolCredentials.providerId}}</span> <input class="form-control" *ngIf="editingClient" [(ngModel)]="client.xolCredentials.providerId" required name="providerId"></div>
          <div class="col" >Company ID:<span *ngIf="!editingClient"> {{client.xolCredentials.companyId}}</span> <input class="form-control" *ngIf="editingClient"  [(ngModel)]="client.xolCredentials.companyId"  required name="companyId"></div>
          <div class="col" >User ID:<span *ngIf="!editingClient"> {{client.xolCredentials.userId}}</span> <input class="form-control" *ngIf="editingClient" [(ngModel)]="client.xolCredentials.userId" required name="userId"></div>
          <div class="col" >Password:<span *ngIf="!editingClient" ></span><input class="form-control"  *ngIf="editingClient" type="password" [(ngModel)]="client.xolCredentials.password" required name="password"></div>
        </div>
      </div>
      <div *ngIf="userKind == 'ProviderUser'">
        <mat-divider class="m-t-10 m-b-10"></mat-divider>
        <div class="row">
          <div class="col">
            Products
          </div>
        </div>
        <div class="row m-t-10">
          <div class="col">
            <label class="i-checks i-checks-sm">
              <input type="checkbox" id="cbRememberMe" [(ngModel)]="client.productSetup?.TU.enabled" [disabled]="!editingClient"  name="tuEnabled">
              <i></i>
              TU
            </label>
          </div>
          <div class="col">
            <label class="i-checks i-checks-sm">
              <input type="checkbox" id="cbRememberMe" [(ngModel)]="client.productSetup?.XPN.enabled" [disabled]="!editingClient"  name="xpnEnabled">
              <i></i>
              XPN
            </label>
          </div>
          <div class="col">
            <label class="i-checks i-checks-sm">
              <input type="checkbox" id="cbRememberMe" [(ngModel)]="client.productSetup?.EFX.enabled" [disabled]="!editingClient"  name="efxEnabled">
              <i></i>
              EFX
            </label>
          </div>
        </div>
      </div>
      <mat-divider class="m-t-10 m-b-10"></mat-divider>
      <div class="row m-t-30" style="font-size: 12pt;">
        <div class="col-3">Created {{client.dateCreated | dateFormat:'MM/DD/YYYY h:mm:ss a' }} </div>
        <div class="col-4">Modified {{client.dateModified | dateFormat:'MM/DD/YYYY h:mm:ss a'}}</div>
      </div>
    </div>
  </form>
</div>
