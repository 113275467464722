<div *ngIf="user" class="userItem m-t-10">
  <form action="" #userForm="ngForm">
    <div class="row no-gutters itemCenterVertical">
      <div class="col">
        <span *ngIf="!editingUser"><i class="fa fa-user m-t-xs m-r-5"></i> {{user.contact}}</span><input class="form-control"  *ngIf="editingUser" [(ngModel)]="user.contact"  name="contact" required>
      </div>
      <div class="col" [hidden]="isProviderSetup">
        <span *ngIf="!editingUser">{{user.kind}}</span>
        <mat-select class="form-control"  *ngIf="editingUser" [(ngModel)]="user.kind" name="kind" required>
          <mat-option [value]="'Administrator'">Administrator</mat-option>
          <mat-option [value]="'Supervisor'">Supervisor</mat-option>
          <mat-option [value]="'Originator'">Originator</mat-option>
        </mat-select>
      </div>

      <div class="col">
        ID: {{user.code}}
      </div>
      <div class="col d-flex justify-content-end">
        <i class="fa fa-pull-right fa-pencil fa-lg clickable " (click)="editingUser = true; element = user"
        tooltip="Edit" [hidden]="editingUser" style="line-height: 30px;"></i>
        <div [hidden]="!editingUser">
          <button mat-flat-button class="m-r-10" color="secondary" (click)="cancelEdit()">CANCEL</button>
          <button mat-flat-button class="pl btn-primary" [disabled]="userForm?.invalid && userForm?.touched" (click)="saveUser()">SAVE</button>
        </div>
        <span class="fa-stack pull-right text-sm m-l-10 clickable">
          <i class="fa fa-circle-thin fa-stack-2x"></i>
          <i class="fa fa-stack-1x fa-caret-down" (click)="element = element === user ? null : user" [ngClass]="{'indicator_collapsed' :element != user, 'indicator_expanded':element == user}"></i>
        </span>
      </div>
    </div>
    <div *ngIf="element">
      <mat-divider class="m-t-10 m-b-10"></mat-divider>

      <div class="row m-t-10">
        <div class="col">Phone:</div>
        <div class="col">Cell:</div>
        <div class="col">Username:</div>
        <div class="col">Email:</div>
      </div>
      <div class="row m-t-5">
        <div class="col"><span *ngIf="!editingUser">{{user.phone | mask: '(000) 000-0000'}}</span><input class="form-control"  *ngIf="editingUser" required mask="(000) 000-0000" [(ngModel)]="user.phone" name="phone"></div>
        <div class="col"><span *ngIf="!editingUser" [hidden]="!user.cell">{{user.cell| mask: '(000) 000-0000'}}</span> <input class="form-control"  *ngIf="editingUser" mask="(000) 000-0000" [(ngModel)]="user.cell" name="cell"></div>
        <div class="col"><span *ngIf="!editingUser">{{user.userName}}</span> <input class="form-control"  *ngIf="editingUser" required [(ngModel)]="user.userName" name="userName"></div>
        <div class="col"><span *ngIf="!editingUser">{{user.email}}</span> <input class="form-control"  *ngIf="editingUser" required email [(ngModel)]="user.email" name="email"></div>
      </div>
      <div class="row m-t-10" [hidden]="isProviderSetup">
        <div class="col-5">Web Address:</div>
        <div class="col"></div>
        <div class="col"></div>
        <div class="col-3">Status:</div>
      </div>
      <div class="row m-t-5" [hidden]="isProviderSetup">
        <div class="col-5"><a [routerLink]="'/consumer/'+ user.code" target="_blank">{{webAddres}}</a></div>
        <div class="col"></div>
        <div class="col"></div>
        <div class="col-3">
          <span *ngIf="!editingUser">{{user.status | clientStatus}}</span>
          <mat-select *ngIf="editingUser" [(ngModel)]="user.status" name="status" placeholder="Status">
            <mat-option [value]="1">Active</mat-option>
            <mat-option [value]="2">Terminated</mat-option>
            <mat-option [value]="3">Inactive</mat-option>
          </mat-select>
        </div>
      </div>
      <div *ngIf="editingUser" [hidden]="this.userKind == 'ProviderUser'">
        <div class="row m-t-15">
          <div class="col bold-font">Loan Type: </div>
        </div>
        <div class="col">
          <mat-select [(ngModel)]="editabledProdcuts" name="editabledProdcuts" multiple placeholder="Select Loan Type...">
              <mat-option *ngFor="let item of loanType" [value]="item">{{item.description}}</mat-option>
          </mat-select>
        </div>
      </div>
      <div *ngIf="!editingUser" [hidden]="userKind == 'ProviderUser'">
        <div class="row m-t-15">
          <div class="col bold-font">Loan Type: </div>
        </div>
        <div class="row m-t-5">
          <div class="col-3 m-t-10" *ngFor="let item of user.products">{{getDescription(item.Id)}} - {{getCategoryName(item.categoryId)}}</div>
        </div>
      </div>
    </div>
  </form>
</div>
