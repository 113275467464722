import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { plainToClass } from 'class-transformer';
import { LoanType } from 'src/app/components/classes/LoanType';
import { User } from 'src/app/components/classes/User';
import { ProgressAlertComponent } from 'src/app/components/dialogs/progress-alert/progress-alert.component';
import { ClientService } from 'src/app/services/client.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-user-setup-admin',
  templateUrl: './user-setup-admin.component.html',
  styleUrls: ['./user-setup-admin.component.scss']
})
export class UserSetupAdminComponent implements OnInit {

  showNewUser: boolean = false;
  users: Array<User> = [];
  loanTypes: Array<LoanType> = [];
  searchText: string;
  subscriberID = localStorage.getItem("parentId") || null;
  filteredUsers: Array<User> = [];
  currentItemsToShow = [];
  defaultRecords: any = 10;

  constructor(private clientService:ClientService,private usersService:UsersService, private matSnackBar: MatSnackBar, private dialog:MatDialog ) { }

  ngOnInit() {
    this.getUsers();
    this.getLoanTypes();
  }

  async getUsers(){
    const progressDialog = this.dialog.open(ProgressAlertComponent, {
      data: {
        title: 'Loading...'
      }
    });
    try{
      this.users = [];
      const data = await this.usersService.getUsers(this.subscriberID);
      this.users = this.users.concat(plainToClass(User, data));
      this.setDefaultPaginatorItems();
      progressDialog.close();
    }
    catch (e){
      progressDialog.close();
      this.matSnackBar.open('Something went wrong');
    }
  }

  async getLoanTypes(){
    const progressDialog = this.dialog.open(ProgressAlertComponent, {
      data: {
        title: 'Loading...'
      }
    });
    try{
      const data = await this.clientService.getLoanTypes(this.subscriberID);
      this.loanTypes = this.loanTypes.concat(plainToClass(LoanType, data));
      progressDialog.close();
    }
    catch (e){
      progressDialog.close();
      this.matSnackBar.open('Something went wrong');
    }
  }

  setDefaultPaginatorItems(){
    this.currentItemsToShow = [];
    if(this.searchText){
      this.currentItemsToShow = this.filteredUsers.slice(0, this.defaultRecords)
    }
    else{
      this.currentItemsToShow = this.users.slice(0, this.defaultRecords)
    }  }

  onPageChange($event) {
    if(this.searchText){
      this.currentItemsToShow = this.filteredUsers.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
    }
    else{
      this.currentItemsToShow = this.users.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
    }
  }

  filterUsers(){
    this.filteredUsers = [];
    if(this.searchText){
      this.users.forEach( user => {
        const lowerProduct = user.userName.toLowerCase()
        if(lowerProduct.includes(this.searchText.toLowerCase())){
          this.filteredUsers.push(user)
        }
      });
    }
    this.setDefaultPaginatorItems();
  }

  toggleNewUserVisibility(){
    this.showNewUser = !this.showNewUser;
  }

  newUserAdded(){
    this.toggleNewUserVisibility();
    this.getUsers();
  }



}
