<div class="bg-white p-a-15">
  <form #newReportForm="ngForm">
    <div class="row">
      <div class="col-4">
        <input type="text" class="form-control" placeholder="Report Name" [(ngModel)]="newReport.name" name="name" required>
      </div>
      <div class="col-3">
        <mat-radio-group [(ngModel)]="newReport.userOnly" required name="userOnly">
          <mat-radio-button color="primary" [value]="true">My Report</mat-radio-button>
          <mat-radio-button color="primary" [value]="false">Company Report</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="col-2 m-t-5">
        <label class="i-checks i-checks-sm">
          <input type="checkbox" id="cbRememberMe" [(ngModel)]="newReport.options" name="options">
          <i></i>
          Include Totals
        </label>
      </div>
      <div class="col-3 d-flex justify-content-end">
        <button mat-flat-button class="btn-primary pl m-r-10" type="button" [disabled]="newReportForm?.invalid" (click)="createReport()">SAVE</button>
        <button mat-flat-button color="secondary" type="button" (click)="closeViewFunc()">CANCEL</button>
      </div>
    </div>
    <mat-divider class="m-t-10 m-b-10"></mat-divider>
    <div class="reportOptions">
      <mat-grid-list cols="6" rowHeight="30px">
        <mat-grid-tile *ngFor="let option of reportOptions">
          <label class="i-checks i-checks-sm">
            <input type="checkbox" id="cbRememberMe" [(ngModel)]="option.select" [name]="option.displayName">
            <i></i>
            {{option.displayName}}
          </label>
          <!-- <mat-checkbox [(ngModel)]="option.select" [name]="option.displayName" color="primary">{{option.displayName}}</mat-checkbox> -->
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </form>
</div>
