import { MatSnackBar } from '@angular/material/snack-bar';
import { LoanCategory, Qualifications, UserInfo } from '../../components/classes/LoanType';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { LoanType } from 'src/app/components/classes/LoanType';
import { User } from 'src/app/components/classes/User';
import { ClientService } from 'src/app/services/client.service';
import { UsersService } from 'src/app/services/users.service';
import { MatDialog } from '@angular/material/dialog';
import { ProgressAlertComponent } from 'src/app/components/dialogs/progress-alert/progress-alert.component';
import { WebAddressService } from 'src/app/services/webaddress.service';
import { NgForm } from '@angular/forms';
import { ToolsService } from 'src/app/services/tools-service';

@Component({
  selector: 'app-product-setup-item',
  templateUrl: './product-setup-item.component.html',
  styleUrls: ['./product-setup-item.component.scss']
})
export class ProductSetupItemComponent implements OnInit {

  @Input() product: LoanType
  @Input() users: Array<User> = [];
  originalProduct: LoanType;
  @Input() loanCategory: Array<LoanCategory> = [];
  editingProduct:boolean;
  element: any;
  addUsers: boolean = false;
  webBase: string = "";
  webAddress: string = "";
  @ViewChild("productForm") productForm: NgForm;


  constructor(private clientService:ClientService, private toolsServices: ToolsService, private usersService:UsersService, private matSnackBar: MatSnackBar, private dialog:MatDialog, private webaddress:WebAddressService ) { }

  async ngOnInit() {
    this.originalProduct =this.toolsServices.deepClone(this.product);
  }

  filterUser(item){
    const length = this.product.userInfo.filter(x => x.Id == item.Id).length
    return length == 0;
  }

  setWebAddress(userCode){
    this.webBase = this.webaddress.generateWebAddress();
    this.webAddress = this.webBase + userCode;
    return this.webAddress
  }

  addQualification(){
    const newQual = new Qualifications();
    this.product.qualifications.push(newQual);
  }

  removeQualification(qualification){
    const qualIndex = this.product.qualifications.indexOf(qualification)
    this.product.qualifications.splice(qualIndex, 1);
  }

  toggleAddUsers(){
    this.addUsers = !this.addUsers;
  }

  cancelChanges(){
    this.product = this.originalProduct;
    this.editingProduct = false;
  }

  async removeUserFromProduct(productId: string ,user: UserInfo){
    const userIndex = this.product.userInfo.indexOf(user);
    this.product.userInfo.splice(userIndex, 1);
    await this.clientService.removeUserFromProduct(productId, user.Id);
  }

  async addUserToProduct(productId: string ,user: User){
    this.product.userInfo.push({Id: user.Id, code: user.code, contact: user.contact})
    await this.clientService.addUserToProduct(productId, user.Id)
  }

  async saveProduct(){
    const progressDialog = this.dialog.open(ProgressAlertComponent, {
      data: {
        title: 'Saving Product...'
      }
    });
    try{
      await this.clientService.updateProduct(this.product);
      this.editingProduct = false;
      progressDialog.close();
    }
    catch{
      progressDialog.close();
      this.matSnackBar.open('Something went wrong');
    }
  }

}
