import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReportOptions, Reports } from 'src/app/components/classes/Reports';
import { ProgressAlertComponent } from 'src/app/components/dialogs/progress-alert/progress-alert.component';
import { ReportService } from 'src/app/services/report.service';

@Component({
  selector: 'app-report-edit',
  templateUrl: './report-edit.component.html',
  styleUrls: ['./report-edit.component.scss']
})
export class ReportEditComponent implements OnInit {

  @Input() reportOptions: Array<ReportOptions>;
  @Input() report: Reports;
  @ViewChild("editReportForm") editReportForm: NgForm;
  @Output() closeView: EventEmitter<any> = new EventEmitter;
  @Output() saveChanges: EventEmitter<any> = new EventEmitter;


  constructor(private reportService: ReportService, private matSnackBar: MatSnackBar, private dialog:MatDialog,) { }

  ngOnInit() {
    this.setReportSelectedOptions()
  }

  closeViewFunc(){
    this.closeView.emit();
  }

  setReportSelectedOptions(){
    this.report.fields.forEach(field =>{
      this.reportOptions.forEach(element => {
        if(field.Id == element.Id){
          element.select = true;
        }
      });
    });
  }

  setReportFields(){
    this.report.fields = [];
    this.reportOptions.forEach(element=>{
     if(element.select){
        this.report.fields.push({Id: element.Id, sort: element.reportSort})
      }
    });
  }

  async saveReport(){
    const progressDialog = this.dialog.open(ProgressAlertComponent, {
      data: {
        title: 'Saving Report...'
      }
    });
    try{
      await this.setReportFields();
      await this.reportService.putReport(this.report);
      progressDialog.close();
      this.saveChanges.emit();
    }
    catch(e){
      progressDialog.close();
      this.matSnackBar.open('Something went wrong when trying to add report.');
    }
  }

}
