import {Injectable} from "@angular/core";
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from "@angular/common/http";
import {Observable} from "rxjs";
import { TokenService } from "./token.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor{

  constructor(public token: TokenService){}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(this.token.getToken()) { ///If false, then the user must be logging in
      request = request.clone({
        setHeaders: {Authorization: `Bearer ${this.token.getToken()}`},
      });
    }

    return next.handle(request);
  }//End intercept
}//End class
