import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import { Client } from 'src/app/components/classes/Client';
import { ReportFields, ReportOptions, ReportVariables, Reports } from 'src/app/components/classes/Reports';
import { AlertsComponent } from 'src/app/components/dialogs/alerts/alerts.component';
import { ProgressAlertComponent } from 'src/app/components/dialogs/progress-alert/progress-alert.component';
import { ReportService } from 'src/app/services/report.service';
import { ToolsService } from 'src/app/services/tools-service';
import dayjs, { Dayjs } from 'dayjs/esm';
import { Statuses } from 'src/app/components/constants/Statuses';
import { DaterangepickerComponent, DaterangepickerDirective } from 'ngx-daterangepicker-material';


@Component({
  selector: 'app-report-view',
  templateUrl: './report-view.component.html',
  styleUrls: ['./report-view.component.scss']
})
export class ReportViewComponent implements OnInit {

  @ViewChild(DaterangepickerDirective, { static: true }) pickerDirective: DaterangepickerDirective;
  picker: DaterangepickerComponent;
  @Input() reportOptions: Array<ReportOptions>
  reportSelections: Array<ReportFields>
  @Input() report: Reports;
  @Input() clients: Array<Client> = [];
  reportVariables: ReportVariables = new ReportVariables();
  @Output() viewReport: EventEmitter<any> = new EventEmitter();
  @Output() closeView: EventEmitter<any> = new EventEmitter();
  @ViewChild("viewReportForm") viewReportForm: NgForm;
  userKind = localStorage.getItem("userKind") || null;
  ranges: any = {
    'Today': [dayjs(), dayjs()],
    'Yesterday': [dayjs().subtract(1, 'days'), dayjs().subtract(1, 'days')],
    'Last 7 Days': [dayjs().subtract(6, 'days'), dayjs()],
    'Last 30 Days': [dayjs().subtract(29, 'days'), dayjs()],
    'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
    'Last Month': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')]
  }
  filterDate:any =  {
    startFilterDate: null,
    endFilterDate: null,
  }
  status = Statuses


  constructor(private reportService: ReportService, private matSnackBar: MatSnackBar, private dialog:MatDialog, private toolService: ToolsService) { }

  ngOnInit() {
    this.filterDate.startFilterDate = moment().startOf('day');
    this.filterDate.endFilterDate = moment().endOf('day');
    this.reportVariables.status = '0';
    this.reportVariables.clientId = '0';
    // this.picker = this.pickerDirective.picker;

    this.setReportSelectedOptions()
  }

  closeViewFunc(){
    this.closeView.emit();
  }

  setReportSelectedOptions(){
    this.report.fields.forEach(field =>{
      this.reportOptions.forEach(element => {
        if(field.Id == element.Id){
          element.select = true;
        }
      });
    });
  }

  async runReport(){
    const progressDialog = this.dialog.open(ProgressAlertComponent, {
      data: {
        title: 'Running Report...'
      }
    });
    try{
      await this.setRunReportFields();

      //mat-select does not handle null values so we have give All Statuses a value and then set it back to null before sending
      if(this.reportVariables.status == "0"){
        this.reportVariables.status = null;
      }
      if(this.reportVariables.clientId == "0"){
        this.reportVariables.clientId = null;
      }

      this.reportVariables.dateFrom = this.filterDate.startFilterDate.format("MM/DD/YYYY").toString();
      this.reportVariables.dateTo = this.filterDate.endFilterDate.format("MM/DD/YYYY").toString();
      const data = await this.reportService.runReport(this.report.Id ,this.reportVariables);
      if(this.reportVariables.emailReport){
        this.emailSentConfirmation(this.report)
      }
      else{
        this.toolService.downloadReportCSV(data, this.report.name);
      }
      progressDialog.close();
    }
    catch(e){
      progressDialog.close();
      this.matSnackBar.open('Something went wrong when trying to run report.');
    }
  }

  emailSentConfirmation(report){
    this.dialog.open(AlertsComponent, {
      data: {
        message: 'Report ' + report.name + ' was emailed successfully.',
        title: "Email Confirmation",
        confirmButtonName: "OK",
        isConfirmation: false,
        destructive: true
      }
    });
  }

  setRunReportFields(){
    this.reportOptions.forEach(element=>{
      if(element.select){
        this.reportVariables.runFields.push({Id: element.Id, sort: "0"})
      }
    });
  }

  openDatePicker(e: MouseEvent): void {
    this.pickerDirective.open(e);
  }

}
