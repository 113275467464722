<div class="consumerAgreement">
  <h3 *ngIf="!tuEnabled">Acknowledgement of terms of agreement</h3>
  <h3 *ngIf="tuEnabled">Authorization to obtain Consumer Report</h3>
  <mat-divider class="m-b-10 m-t-10"></mat-divider>

  <div *ngIf="xpnEnabled">
    <p><strong>Experian</strong></p>
    <p>
      You understand that by clicking on the AGREE button immediately following this notice you are providing 'written instructions' to {{consumerInfo.companyName}}
      under the Fair Credit Reporting Act authorizing  {{consumerInfo.companyName}} to obtain information from your personal credit profile or other information from Experian.
      This is a soft inquiry and will not affect your credit score. You authorize {{consumerInfo.companyName}} to obtain such information solely to pre-qualify you for credit options.
    </p>
  </div>
  <div *ngIf="tuEnabled">
    <p><strong>TransUnion</strong></p>
    <p>
      You understand that by clicking on the AGREE button immediately following this notice you are providing 'written instructions' to {{consumerInfo.companyName}}
      under the Fair Credit Reporting Act authorizing  {{consumerInfo.companyName}}  to obtain your consumer report [and score] from TransUnion. This is a soft inquiry and will not affect your credit score.
      You authorize {{consumerInfo.companyName}} to obtain such information solely to pre-qualify you for credit options.
    </p>
  </div>
  <div *ngIf="efxEnabled">
    <p><strong>Equifax</strong></p>
    <p>
      You understand that by clicking on the AGREE button immediately following this notice you are providing 'written instructions' to {{consumerInfo.companyName}}
      under the Fair Credit Reporting Act authorizing  {{consumerInfo.companyName}}  to obtain your consumer report [and score] from Equifax. This is a soft inquiry and will not affect your credit score.
      You authorize {{consumerInfo.companyName}} to obtain such information solely to pre-qualify you for credit options.
    </p>
  </div>
  <div *ngIf="preview">
    <p><strong>Test Bureau</strong></p>
    <p>
      This is a test agreement message that is a sub for the actual agreements for each bureau.
    </p>
  </div>

  <div>
    <button mat-flat-button style="color: #fff;" (click)="goToTermsAndConditions()" [ngStyle]="{background:branding.ButtonBackgroundColor}">Terms and Conditions</button>
  </div>

</div>
