import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ClientColors } from 'src/app/components/classes/Client';
import { ConsmerLoanCategory, Consumer, ConsumerColors } from 'src/app/components/classes/Consumer';
import { DialogData } from 'src/app/components/dialogs/alerts/alerts.component';

@Component({
  selector: 'app-consumer-preview',
  templateUrl: './consumer-preview.component.html',
  styleUrls: ['./consumer-preview.component.scss']
})
export class ConsumerPreviewComponent implements OnInit {

  clientColors: ConsumerColors;
  @ViewChild('stepper') myStepper: MatStepper;
  consumerTitle: string = "Test Title";
  beginRequest: boolean;
  companyLogoUrl: string;
  viewOutput: string = "ViewOutput.aspx";
  categories: Array<ConsmerLoanCategory>;
  selectProduct: string;
  consumerInfo: Consumer;

  constructor( @Inject(MAT_DIALOG_DATA) public data: PreviewData | any) { }

  ngOnInit() {
    this.consumerInfo = new Consumer();
    this.clientColors = this.data.branding;
    this.setStepperStyles();
  }

  setStepperStyles(){
    document.documentElement.style.setProperty('--mat-stepper-header-selected-state-icon-background-color', this.clientColors.SelectedTabBackgroundColor);
    document.documentElement.style.setProperty('--mat-stepper-header-done-state-icon-background-color', this.clientColors.SelectedTabBackgroundColor);
    document.documentElement.style.setProperty('--mat-stepper-header-selected-state-label-text-color', this.clientColors.SelectedTabBackgroundColor);
    document.documentElement.style.setProperty('--mat-stepper-header-edit-state-icon-background-color', this.clientColors.UnselectedTabBackgroundColor);
    document.documentElement.style.setProperty('--mat-stepper-header-icon-background-color', this.clientColors.UnselectedTabBackgroundColor);
    document.documentElement.style.setProperty('--mat-stepper-header-optional-label-text-color', this.clientColors.UnselectedTabBackgroundColor);
    document.documentElement.style.setProperty('--mat-stepper-header-label-text-color', this.clientColors.UnselectedTabBackgroundColor);
  }

  begin(){
    this.beginRequest = true;
    this.consumerTitle = "Choose a Product"
  }

  goBack(){
    this.myStepper.previous();
  }

  goForward(){
    this.myStepper.next();
  }

  goToStepperIndex(index){
    this.myStepper.selectedIndex = index;
  }

  getDesctiptionIcon(item){
    if(item == 'Mortgage'){
      return "fa-solid fa-house";
    }
    else if(item == 'Auto'){
      return "fa-solid fa-car";
    }
    else if(item == 'Credit'){
      return "fa-solid fa-credit-card";
    }
    else if(item == 'Business Finance'){
      return "fa-solid fa-dollar-sign";
    }
    else{
      return "";
    }
  }

}

export interface PreviewData{
  branding: ConsumerColors;
}
