import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { lastValueFrom } from 'rxjs';
import { User } from '../components/classes/User';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private httpClient:HttpClient) { }

  async getProviderUsers(active: string){

    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json');

    if(active){
      const params = new HttpParams().append('status', active)
      return await lastValueFrom(this.httpClient.get('api/Provider/Subscriber/Users', {headers: headers, params: params}))
    }
    else{
      return await lastValueFrom(this.httpClient.get('api/Provider/Users', {headers: headers}))
    }
  }

  async getUsers(providerId: string){

    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json');

    return await lastValueFrom(this.httpClient.get('api/Subscriber/Users/' + providerId, {headers: headers}))
  }

  async addNewUser(newUser: User){
    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json');

    return await lastValueFrom(this.httpClient.post('api/Subscriber/User', newUser, {headers: headers}));
  }

  async addNewUserRoot(newUser: User){
    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json');

    return await lastValueFrom(this.httpClient.post('api/Root/User', newUser, {headers: headers}));
  }

  async updateUser(user: User){
    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json');

    return await lastValueFrom(this.httpClient.put('api/Subscriber/User', user, {headers: headers}));
  }

}
