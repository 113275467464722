import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-setup',
  templateUrl: './user-setup.component.html',
  styleUrls: ['./user-setup.component.scss']
})
export class UserSetupComponent implements OnInit {

  userKind = localStorage.getItem("userKind") || null;


  constructor() { }

  ngOnInit() {
  }

}
