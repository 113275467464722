<div class="contentContainer">
  <div class="row justify-content-end" >
    <div class="col-md-2">
      <input type="text" class="form-control pull-right w m-r-lg" placeholder="SEARCH" [(ngModel)]="searchText" (ngModelChange)="filterProduct()"/>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <h2 style="position: relative">
        Product Setup
        <span class="badge pl bg-primary alert_indicator"> {{products?.length}} </span>
      </h2>
    </div>
    <div class="col-md-4 offset-md-4">
      <span class="fa-stack fa-pull-right text-lg m-t-10 clickable" (click)="toggleNewProductVisibility()">
        <i class="fa fa-circle fa-stack-2x pl" [ngClass]="{'text-danger':showNewProduct, 'text-primary':!showNewProduct}"></i>
        <i class="fa fa-stack-1x fa-inverse" [ngClass]="{'fa-plus':!showNewProduct, 'fa-minus':showNewProduct}"></i>
      </span>
    </div>
  </div>
  <mat-divider class="m-t-10 m-b-10"></mat-divider>
  <div *ngIf="showNewProduct">
    <app-product-setup-new [addableUsers]="users" [loanCategory]="loanCategories" (addedNewProduct)="addNewProduct($event)" (cancelNewProduct)="toggleNewProductVisibility()"></app-product-setup-new>
  </div>
  <div *ngFor="let item of currentItemsToShow">
    <app-product-setup-item [product]="item" [users]="users" [loanCategory]="loanCategories"></app-product-setup-item>
  </div>

  <mat-paginator class="m-t-10" [length]="products?.length" (page)="onPageChange($event)" [pageSize]="10" [showFirstLastButtons]="true" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>
