import { Component, OnInit } from '@angular/core';
import { LoginService } from '../services/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { confirmPasswordValidator } from '../components/constants/confirm-password.validator';
import { ProgressAlertComponent } from '../components/dialogs/progress-alert/progress-alert.component';

@Component({
  selector: 'app-resetpw',
  templateUrl: './resetpw.component.html',
  styleUrls: ['./resetpw.component.scss']
})
export class ResetpwComponent implements OnInit {

  resetPwParams ={
    UserName : "",
    NewPassword : "",
    ConfirmPassword : "",
    ChangePasswordToken : ""
  }
  resetPasswordForm: FormGroup;
  passwordChanged: boolean;
  errorMessage:string;
  
  constructor(private activatedRoute: ActivatedRoute,private loginService:LoginService, private router:Router, private dialog:MatDialog, private matSnackBar: MatSnackBar) { }
  
  
  ngOnInit(): void {
    if(localStorage.getItem("token")){
      this.loginService.logoutUser(false);
    }
    this.getRouteParams()
    this.resetPasswordForm =  new FormGroup({
      newPassword: new FormControl('', [Validators.required, Validators.pattern("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&]).{8,50}")]),
      confirmPassword: new FormControl('', [Validators.required])
    },
    {validators: confirmPasswordValidator})
  }
  
  getRouteParams(){
    this.activatedRoute.queryParams.subscribe(params => {
      this.resetPwParams.UserName = params['username'];
      this.resetPwParams.ChangePasswordToken = params['code'];
    });
  }

  async forgotPassword(){
    const progressDialog = this.dialog.open(ProgressAlertComponent, {
      data: {
        title: 'Loading...'
      }
    });
    try{
      this.errorMessage = "";
      this.resetPwParams.NewPassword = this.resetPasswordForm.value.newPassword;
      this.resetPwParams.ConfirmPassword = this.resetPasswordForm.value.confirmPassword;
      await this.loginService.resetPassword(this.resetPwParams);
      progressDialog.close();
      this.passwordChanged = true;
    }
    catch(e){
      this.passwordChanged = false;
      this.errorMessage ="An error occurred. Please try again. If you continue to get this message, please try submitting a new password reset request on the login page.";
      progressDialog.close();

    }
  }

  goToLogin(){
    this.router.navigate(['app/access/signin']);
  }

  
}
