import { Logo } from "./Logo";
import { User } from "./User";

export class Client {
  Id: string;
  address: string;
  branding: string;
  // parsedBranding: ClientColors;
  cell: string;
  city: string;
  code: string;
  companyName: string;
  completionMessage: string;
  contact: string;
  dateCreated: string;
  dateLastLogon: string;
  dateModified: string;
  email: string;
  hierarchicalCompletionMessage: string;
  hierarchicalRejectMessage: string;
  hierarchicalWelcomeMessage: string;
  kind: string;
  logo: Logo;
  notificationEmail: string;
  parentId: string;
  password: string;
  phone: string;
  productSetup: ProductSetup;
  providerId: string;
  rejectMessage: string;
  securityCookie: string;
  state:string;
  status:number;
  url:string;
  users: Array<User>;
  userName: string;
  welcomeMessage: string;
  xolCredentials: XOLCredentials;
  zip: string;
}

export class XOLCredentials{
  providerId: string;
  companyId: string;
  userId: string;
  password: string;
}

export class ProductSetup{
  EFX?: ProductSetupEnabled | null;
  XPN?: ProductSetupEnabled | null;
  TU?: ProductSetupEnabled | null;
}

export class ProductSetupEnabled{
  enabled?: boolean | null;
}

export class ClientColors{
  HeaderBackgroundColor: string = "#000000";
  FooterBackgroundColor: string =  "";
  ButtonBackgroundColor: string = "";
  HeaderTextColor: string = "";
  SelectedTabBackgroundColor: string = "#777777";
  UnselectedTabBackgroundColor: string = "#CCCCCC";
  CategoryHeadingTextColor: string = "";
  FinishHeadingTextColor:string ="";
}
