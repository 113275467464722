<div class="col-md-12">
<mat-divider class="m-t-10 m-b-10"></mat-divider>
  <div class="row">
      <div class="col-md-12 m-b-10" *ngIf="request.score">
        <div style="font-size: 24px" >{{getPrequalifiedText(request)}} for {{request.loanTypeName}} based on Credit Report</div>
        </div>

      <div class="col-md-8">
          <div *ngIf="request.xolOutputURL"><button class="btn btn-primary pl" type="button" [disabled]="isProcessing || isGettingReport" (click)="viewReport($event, request)">View Report <mat-spinner *ngIf="isGettingReport"></mat-spinner></button></div>
      </div>
      <div class="col-md-4">
          <div class="h2" style="margin-bottom: 0;">SCORE: <span style="font-weight: normal" [ngClass]="{'text-success':request.prequalified, 'text-danger':!request.prequalified}">{{request.score || 'N/A'}}</span></div>
          <div class="m-t-sm font-bold text-danger" ng-if="userKind!=constants.USER_KINDS.ORIGINATOR.value" >{{request.contactAllowed?"":"DO NOT CONTACT"}}</div>
      </div>
  </div>
  <mat-divider class="m-t-10 m-b-10"></mat-divider>

  <div class="row m-b-10">
      <div class="col-md-3">
          Phone: {{request.phone}}
      </div>
      <div class="col-md-9">
          Email: {{request.email}}
      </div>
  </div>
  <div class="row">
      <div class="col-md-3">
          Address: {{request.address}}
      </div>
      <div class="col-md-3">
          City: {{request.city}}
      </div>
      <div class="col-md-3">
          State: {{request.state}}
      </div>
      <div class="col-md-3">
          Zip: {{request.zip}}
      </div>
  </div>

  <mat-divider class="m-t-10 m-b-10" style="padding-bottom: 10px"></mat-divider>

  <h3>Activity Log</h3>

  <div *ngFor="let activity of request.activities; let i= index" class="row activitiesItem " >
      <div class="col-md-3 p-a-5">
          {{activity.logDate | dateFormat:'l, h:mm a'}}
      </div>
      <div class="col-md-3 p-a-5">
          {{activity.kind}}
      </div>
      <div class="col-md-6 p-a-5">
          {{activity.comment}}
      </div>

  </div>

  <mat-divider class="m-t-10 m-b-10"></mat-divider>

  <div class="text-center"><button class="btn btn-danger" type="button" ng-disabled="isRemoving" (click)="removeFromInbox($event,request)" *ngIf="!request.archived">Remove From Inbox / Archive<mat-spinner *ngIf="isProcessing"></mat-spinner></button></div>
</div>
