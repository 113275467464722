<div class="sideBar" style="float: left">
  <div class="sideBarTitle clickable bg-neutral" (click)="goToHomeState()">
      <!-- <div *ngIf="!logoURL" class="navbar-brand">
        <img src="assets/readyqual.png" >
      </div> -->
      <div class="navbar-brand">
        <img [src]="logoURL">
      </div>
  </div>

  <div style="padding-left: 2px;" class="sideBarAction" *ngIf="UserType != 'RootUser'" (click)="routeToOrders()" >
    <span class="sideBarIcon">Inbox</span>
  </div>

  <div class="sideBarAction"  style="padding-left: 2px;" *ngIf="UserType == 'ProviderUser' || UserType == 'Administrator'" (click)="routeToClientPage()">
    <span>Client Setup</span>
  </div>

  <div class="sideBarAction" *ngIf="UserType == 'Administrator'" style="padding-left: 2px;" [routerLink]="'app/'+ UserType +'/products'">
    <span>Product Setup</span>
  </div>

  <div *ngIf="UserType == 'ProviderUser' || UserType == 'Administrator' " class="sideBarAction"  style="padding-left: 2px;" (click)="routeToUserPage()" >
    <span>User Setup</span>
  </div>

  <div *ngIf="UserType == 'ProviderUser' || UserType == 'RootUser'" class="sideBarAction"  style="padding-left: 2px;" (click)="routeToProvider()">
    <span>Provider Setup</span>
  </div>

  <div class="sideBarAction" *ngIf="UserType != 'RootUser'"  style="padding-left: 2px;" (click)="routeToReports()">
    <span>Reports</span>
  </div>


</div>
