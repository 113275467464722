import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoanType } from 'src/app/components/classes/LoanType';
import { User } from 'src/app/components/classes/User';
import { ProgressAlertComponent } from 'src/app/components/dialogs/progress-alert/progress-alert.component';
import { ToolsService } from 'src/app/services/tools-service';
import { UsersService } from 'src/app/services/users.service';
import { WebAddressService } from 'src/app/services/webaddress.service';

@Component({
  selector: 'app-user-setup-item',
  templateUrl: './user-setup-item.component.html',
  styleUrls: ['./user-setup-item.component.scss']
})
export class UserSetupItemComponent implements OnInit {

  @Input() user: User;
  originalUserData: User;
  @Input() loanType: Array<LoanType>;
  @Input() isProviderSetup: Boolean = false;
  editabledProdcuts: Array<LoanType> = [];
  editingUser: boolean;
  element: any | null;
  userKind: string = localStorage.getItem('userKind') || null;
  webBase: string;
  webAddres: string;
  @ViewChild("userForm") userForm: NgForm;

  constructor(private usersService:UsersService, private toolService: ToolsService ,private matSnackBar: MatSnackBar, private dialog:MatDialog, private webaddress:WebAddressService ) { }

  ngOnInit() {
    if(this.user){//Save a copy of the user
      this.originalUserData = this.toolService.deepClone(this.user);
    }
    if(!this.isProviderSetup && this.userKind != 'ProviderUser'){
      this.setEditableProducts();
    }
    this.setWebAddress();
  }

  setWebAddress(){
    this.webBase = this.webaddress.generateWebAddress();
    this.webAddres = this.webBase + this.user.code
  }

  async saveUser(){
    const progressDialog = this.dialog.open(ProgressAlertComponent, {
      data: {
        title: 'Updating User...'
      }
    });
    try{
      this.setProducts();
      await this.usersService.updateUser(this.user);
      this.originalUserData = this.toolService.deepClone(this.user);
      this.editingUser = false;
      progressDialog.close();
    }
    catch (e){
      progressDialog.close();
      if(e.error.includes("already exists")){
        this.matSnackBar.open( e.error + '. Please choose a different username.');
      }
      else{
        this.matSnackBar.open('Something went wrong.');
      }    
    }
  }

  setEditableProducts(){
    this.user?.products.forEach(element => {
      this.loanType.forEach( loan => {
        if(loan.Id == element.Id){
          this.editabledProdcuts.push(loan);
        }
      })
    });
  }

  setProducts(){
    this.user.products = [];
    this.editabledProdcuts.forEach(x =>{
      this.user.products.push({Id:x.Id , categoryId:  x.categoryId})
    });
  }

  getDescription(id){
    let description = "";

    this.loanType.forEach(element => {
      if(element.Id == id){
        description = element.description
      }
    });
    return description;
  }

  getCategoryName(id){
    let categoryDescription = "";

    this.loanType.forEach(element => {
      if(element.categoryId == id){
        categoryDescription = element.categoryName
      }
    });
    return categoryDescription;
  }

  cancelEdit(){
    this.editingUser = false;
    this.user = this.originalUserData;
  }

}
