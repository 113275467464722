import { ToolsService } from 'src/app/services/tools-service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Client, ClientColors, ProductSetup, ProductSetupEnabled, XOLCredentials } from 'src/app/components/classes/Client';
import { User } from 'src/app/components/classes/User';
import { ProgressAlertComponent } from 'src/app/components/dialogs/progress-alert/progress-alert.component';
import { ConsumerPreviewComponent } from 'src/app/consumer/consumer-preview/consumer-preview.component';
import { ClientService } from 'src/app/services/client.service';
import { environment } from 'src/environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Logo } from 'src/app/components/classes/Logo';
import { FileUploader } from 'ng2-file-upload';
import { ZipLookUpInfo } from 'src/app/components/classes/Consumer';
import { matchPasswordValidator } from 'src/app/components/constants/confirm-password.validator';
import { States } from 'src/app/components/constants/UsStates';

@Component({
  selector: 'app-client-setup-new',
  templateUrl: './client-setup-new.component.html',
  styleUrls: ['./client-setup-new.component.scss']
})
export class ClientSetupNewComponent implements OnInit {

  client: Client = new Client();
  clientForm: FormGroup
  adminUser: User;
  confirmPassword: string;
  @Output() cancelNewClient = new EventEmitter<boolean>();
  @Output() addedNewClient = new EventEmitter<boolean>();
  logoURL: string;
  viewOutput: string = "ViewOutput.aspx";
  showLogoMessage = "If checked, the logo will appear on both the administrative site and consumer site. If unchecked, the logo will only appear on the administrative site.";
  attachmentHandler: string = "AttachmentHandler.aspx"
  uploader:FileUploader;
  zipData: ZipLookUpInfo;
  allowedFileTypes: string = '|jpg|png|jpeg|bmp|gif|';
  parsedBranding: ClientColors;
  statesList = States;

  constructor(private clientService:ClientService, private matSnackBar: MatSnackBar, private dialog:MatDialog, private toolService: ToolsService) { }

  ngOnInit() {
    this.client = new Client();
    this.adminUser = new User();
    this.client.logo = new Logo();
    this.parsedBranding = new ClientColors();
    this.client.xolCredentials = new XOLCredentials();
    this.setProfileSetup();
    this.setForm();
    this.uploader = new FileUploader({
      url: environment.apiUrl + "/" + this.attachmentHandler,
      method: 'post',
    })
    this.uploader.onSuccessItem = (fileItem , response) => this.saveFileResponse(response) ;
  }

  setForm(){
    this.clientForm = new FormGroup({
      email: new FormControl(this.client.email, {nonNullable: true, validators: [Validators.required, Validators.email]}),
      phone: new FormControl(this.client.phone, {nonNullable: true, validators: [Validators.required, Validators.pattern("^[0-9]*$")]}),
      companyName: new FormControl(this.client.companyName, {nonNullable: true, validators: [Validators.required]}),
      zip: new FormControl(this.client.zip, {nonNullable: true, validators: [Validators.required]},),
      state: new FormControl(this.client.state, {nonNullable: true, validators: [Validators.required]},),
      city: new FormControl(this.client.city, {nonNullable: true, validators: [Validators.required]},),
      address: new FormControl(this.client.address, {nonNullable: true, validators: [Validators.required]},),
      headerBackgroundColor: new FormControl(this.parsedBranding.HeaderBackgroundColor, {nonNullable: true, validators: [Validators.required]},),
      selectedTabBackgroundColor: new FormControl(this.parsedBranding.SelectedTabBackgroundColor, {nonNullable: true, validators: [Validators.required]},),
      unselectedTabBackgroundColor: new FormControl(this.parsedBranding.UnselectedTabBackgroundColor, {nonNullable: true, validators: [Validators.required]},),
      providerId: new FormControl(this.client.xolCredentials.providerId, {nonNullable: true, validators: [Validators.required]},),
      companyId: new FormControl(this.client.xolCredentials.companyId, {nonNullable: true, validators: [Validators.required]},),
      userId: new FormControl(this.client.xolCredentials.userId, {nonNullable: true, validators: [Validators.required]},),
      xolpassword:  new FormControl(this.client.xolCredentials.password, {nonNullable: true, validators: [Validators.required]},),
      tuEnabled: new FormControl( this.client.productSetup.TU.enabled, {nonNullable: true, validators: [Validators.required]},),
      xpnEnabled: new FormControl(this.client.productSetup.XPN.enabled, {nonNullable: true, validators: [Validators.required]},),
      efxEnabled: new FormControl(this.client.productSetup.EFX.enabled, {nonNullable: true, validators: [Validators.required]},),
      userContact: new FormControl(this.adminUser.contact, {nonNullable: true, validators: [Validators.required]},),
      userEmail: new FormControl(this.adminUser.email, {nonNullable: true, validators: [Validators.required]},),
      userPhone: new FormControl(this.adminUser.phone, {nonNullable: true, validators: [Validators.required]},),
      userCell: new FormControl(this.adminUser.cell, {nonNullable: true, validators: [Validators.pattern("^[0-9]*$")]},),
      userName: new FormControl(this.adminUser.userName, {nonNullable: true, validators: [Validators.required]},),
      password: new FormControl(this.adminUser.password, {nonNullable: true, validators: [Validators.required, Validators.pattern("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&]).{8,50}")]},),
      matchPassword: new FormControl(this.adminUser.password, {nonNullable: true, validators: [Validators.required]}),
    },
    {validators: matchPasswordValidator});
  }

  setProfileSetup(){
    this.client.productSetup = new ProductSetup()
    this.client.productSetup.EFX = new ProductSetupEnabled();
    this.client.productSetup.EFX.enabled = false;
    this.client.productSetup.TU = new ProductSetupEnabled();
    this.client.productSetup.TU.enabled = false;
    this.client.productSetup.XPN = new ProductSetupEnabled();
    this.client.productSetup.XPN.enabled = false;
  }

  setNewClientData(){

    //assign branding data
    this.parsedBranding.HeaderBackgroundColor = this.clientForm.value.headerBackgroundColor
    this.parsedBranding.FooterBackgroundColor = this.clientForm.value.headerBackgroundColor;
    this.parsedBranding.ButtonBackgroundColor = this.clientForm.value.selectedTabBackgroundColor
    this.parsedBranding.HeaderTextColor = this.clientForm.value.selectedTabBackgroundColor
    this.parsedBranding.FinishHeadingTextColor = this.clientForm.value.selectedTabBackgroundColor
    this.parsedBranding.SelectedTabBackgroundColor = this.clientForm.value.selectedTabBackgroundColor
    this.parsedBranding.UnselectedTabBackgroundColor = this.clientForm.value.unselectedTabBackgroundColor
    this.parsedBranding.CategoryHeadingTextColor = this.clientForm.value.unselectedTabBackgroundColor
    this.client.branding = JSON.stringify(this.parsedBranding);
    //assign productSetUp
    this.client.productSetup.TU.enabled = this.clientForm.value.tuEnabled
    this.client.productSetup.XPN.enabled = this.clientForm.value.xpnEnabled
    this.client.productSetup.EFX.enabled = this.clientForm.value.efxEnabled
    //xol creds
    this.client.xolCredentials.providerId = this.clientForm.value.providerId;
    this.client.xolCredentials.companyId = this.clientForm.value.companyId;
    this.client.xolCredentials.userId = this.clientForm.value.userId;
    this.client.xolCredentials.password = this.clientForm.value.xolpassword;

    this.client.address = this.clientForm.value.address;
    this.client.email = this.clientForm.value.email;
    this.client.phone = this.clientForm.value.phone;
    this.client.companyName = this.clientForm.value.companyName;
    this.client.zip = this.clientForm.value.zip;
    this.client.state = this.clientForm.value.state;
    this.client.city = this.clientForm.value.city;
    this.client.welcomeMessage = 'What are you looking for today?';
    this.client.rejectMessage = '[[LOAN_OFFICER_NAME]] will be in contact with you shortly to discuss your options. If you have any questions between now and then, [[LOAN_OFFICER_NAME]] can be reached by phone at [[LOAN_OFFICER_PHONE]] or by email at [[LOAN_OFFICER_EMAIL]].';
    this.client.completionMessage = 'Based on the data returned, you have been prequalified for a/n [[PRODUCT_NAME]]. The maximum pre-qualification loan amount will be determined by factors including, but not limited to, your credit at the time of application, your verifiable income, the total amount of your current obligations, and your verifiable assets. If you have any questions, [[LOAN_OFFICER_NAME]] can be reached by phone at [[COMPANY_PHONE]] or by email at [[COMPANY_EMAIL]].';
    this.client.parentId = localStorage.getItem("parentId") || null;

  }

  setNewUserData(){
    this.adminUser.contact = this.clientForm.value.userContact;
    this.adminUser.email = this.clientForm.value.userEmail;
    this.adminUser.phone = this.clientForm.value.userPhone;
    this.adminUser.cell = this.clientForm.value.userCell;
    this.adminUser.userName = this.clientForm.value.userName;
    this.adminUser.password = this.clientForm.value.password;
    this.adminUser.matchPassword = this.clientForm.value.matchPassword;
    this.adminUser.kind = "Administrator";
  }

  async addNewClient(){
    const progressDialog = this.dialog.open(ProgressAlertComponent, {
      data: {
        title: 'Adding New Client...'
      }
    });
    try{
      //Need to assign the form values back to the object before we send to server
      await this.setNewClientData();
      await this.setNewUserData();
      this.client.users = [];
      this.client.users.push(this.adminUser);
      await this.clientService.addClient(this.client);
      this.addedNewClient.emit(false)
      progressDialog.close();
    }
    catch(e){
      progressDialog.close();
      if(e.error.includes("already exists")){
         this.matSnackBar.open( e.error + '. Please choose a different username.');
      }
      else{
        this.matSnackBar.open('Something went wrong.');
      }
     
    }
  }

  handleFiles(fileItem) {
    //check file type first before upload
    var type = '|' + fileItem[0].type?.slice(fileItem[0].type.lastIndexOf('/') + 1) + '|';
    const validType = this.allowedFileTypes.indexOf(type) !== -1
    if(validType){
      this.uploader.queue[0].upload();
    }
    else{
      //this.uploader.cancelAll(); //remove file from the uploader queue
      this.uploader.clearQueue();
      console.log(this.uploader.queue)
      this.matSnackBar.open('Error file is an invalid type.', "OK");
    }
  }

  saveFileResponse(response){
    const newLogo = JSON.parse(response);
    this.client.logo = newLogo[0];
    this.client.logo.showOnConsumer = false;
    this.setLogo();
  }

  async zipcodeLookUp(zip: string){
    if(zip?.length >= 5){
      this.zipData =  await this.toolService.zipLookUp(zip);
      this.clientForm.patchValue({
        state: this.zipData.state,
        city: this.zipData.city
      })
    }
  }

  setLogo(){
    if(this.client.logo){
      this.logoURL = environment.apiUrl + "/"+ this.viewOutput + "?filename="+ this.toolService.getFilenameFromURL(this.client.logo.url);
    }
    else{
      this.logoURL = null
    }
  }



  cancelAddingClient(){
    this.cancelNewClient.emit(false);
  }

  previewBranding(){
    //Assign data back to object to load in preview component
    this.parsedBranding.HeaderBackgroundColor = this.clientForm.value.headerBackgroundColor
    this.parsedBranding.FooterBackgroundColor = this.clientForm.value.headerBackgroundColor;
    this.parsedBranding.ButtonBackgroundColor = this.clientForm.value.selectedTabBackgroundColor
    this.parsedBranding.HeaderTextColor = this.clientForm.value.selectedTabBackgroundColor
    this.parsedBranding.FinishHeadingTextColor = this.clientForm.value.selectedTabBackgroundColor
    this.parsedBranding.SelectedTabBackgroundColor = this.clientForm.value.selectedTabBackgroundColor
    this.parsedBranding.UnselectedTabBackgroundColor = this.clientForm.value.unselectedTabBackgroundColor
    this.parsedBranding.CategoryHeadingTextColor = this.clientForm.value.unselectedTabBackgroundColor
    //open preview component 
    this.dialog.open(ConsumerPreviewComponent, {
      data:{
        branding: this.parsedBranding
      },
      width: '1500px'
    })
  }


}
